import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  HostListener,
  ElementRef,
  OnDestroy,
  AfterViewChecked,
  Inject,
  Injector
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ScoringlightModel } from '../models/scoringlight.model';
import { SituationfamilialeEnum } from '../enums/situationfamiliale.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { CapaciteEmpruntModaleComponent } from '../../shared/modal/capacite.emprunt.modale/capacite.emprunt.modale.component';
import { ScoringService } from '../services/scoring.service';
import { AccountComponent } from '../account/account.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { AccountCoemprunteurComponent } from '../account/account-coemprunteur/account-coemprunteur.component';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { ClientModel } from 'src/app/tealium/client.model';
import { ClicModel } from 'src/app/tealium/clic.model';
import { CustomModel } from 'src/app/tealium/custom';
import { ProspectService } from '../services/prospect.service';
import { ProspectModel } from 'src/app/features/models/prospect.model';
import { ErreurModalComponent } from 'src/app/shared/modal/erreur.modal/erreur.modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', './home.scoringlight.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  constructor(
    @Inject(Injector) private injector: Injector,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private scoringLightService: ScoringService,
    private _elementRef: ElementRef,
    private routeNavigation: Router,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService,
    private prospectService: ProspectService,
    private http: HttpClient
  ) {
    this.subscription = this.scoringLightService.getAnchor().subscribe(data => {
      const section = _elementRef.nativeElement.querySelector('.' + data.anchor);
      section.scrollIntoView({ behavior: 'smooth', block: 'start' }, true);
    });

    // Get params from url
    this.route.queryParams.subscribe(params => ((this.signkey = params['signkey']), (this.activated = params['activated'])));
  }
  public isCollapsed = false;
  prospect;
  lines: string[];
  // link tabset in DOM
  @ViewChild('icvSimpleTabs', { static: true })
  public icvSimpleTabs: NgbTabset;

  subscription: Subscription;
  situationFamilialeEnum = SituationfamilialeEnum;
  situationFamilialeForm: FormGroup;
  enfantAchargeForm: FormGroup;
  codePostalForm: FormGroup;
  revenuSeulForm: FormGroup;
  revenusCoupleForm: FormGroup;
  chargeForm: FormGroup;
  onglet1Inactif: boolean;
  inputScoringLight = new ScoringlightModel();
  outputScoringLight = new ScoringlightModel();
  revenuSeul: boolean;
  ongletEnfantInactif: boolean;
  calculDisable: boolean;
  showRestimation: boolean;
  infoCapacite: boolean;
  cpInvalid: boolean;
  cpDomTom: boolean;
  ongletCodePostaltInactif: boolean;
  ongletRevenuInactif: boolean;
  ongletChargesInactif: boolean;
  chargeRevenuInvalid: boolean;
  revenusFoyerNull: boolean;
  cpNull: boolean;
  chargesNull: boolean;
  chargesNegatives: boolean;
  enfantNull: boolean;
  revenusNull: boolean;
  revenusNegatives: boolean;
  revenusConjointNegatives: boolean;
  revenuFoyer;
  showContentTab: boolean;
  hideTabList: boolean;
  afficherInfoCapaciteCalculee: boolean;
  icvList = [];
  signkey = '';
  activated = '';
  postal_code_error_list = '';
  revenus_error_list = '';
  revenusCouple_error_list = '';
  charges_error_list = '';
  listTabsIds: string[];
  listTabsVisibility: boolean[];

  // to keep the window size update on resize
  public innerWidth: any = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.innerWidth < 600 && window.innerWidth >= 600) {

      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = false;
      this._elementRef.nativeElement.querySelector('ul').hidden = false;
    } else if (this.innerWidth >= 600 && window.innerWidth < 600) {
      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = true;
      this._elementRef.nativeElement.querySelector('ul').hidden = false;

    }
    this.innerWidth = window.innerWidth;

  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.prospect = new ProspectModel();
    if (location.href.includes('source=')) {
      const index = location.href.indexOf('source') + 7;
      const fin = location.href.indexOf('&');
      if (fin != -1) {
        const codeApp = location.href.substring(index, fin);
        this.registerNieProspect(codeApp);
      }
    }

    window.scroll(0, 0);
    if (!localStorage.getItem('accesKey'))
      localStorage.setItem('accesKey', this.route.snapshot.queryParamMap.get('accesKey'));
    localStorage.setItem('icvLightList', null);
    this.situationFamilialeForm = this.formBuilder.group({
      situationFamiliale: ['', [Validators.required]]
    });
    this.enfantAchargeForm = this.formBuilder.group({
      enfant: ['', [Validators.min(0)]]
    });
    this.codePostalForm = this.formBuilder.group({
      codePostal: ['', [Validators.minLength(5), Validators.maxLength(5)]]
    });
    this.revenuSeulForm = this.formBuilder.group({
      revenusSeul: [null, []]
    });
    this.revenusCoupleForm = this.formBuilder.group({
      monRevenu: [null, []],
      revenuConjoint: [null, []]
    });
    this.chargeForm = this.formBuilder.group({
      charges: [null, []]
    });
    // Initialisation ICV LIGHT
    this.initIcvLight();
    // gestion de la responsivité d'icv simple
    if (this.innerWidth <= 414) {
      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = true;
    } else {
      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = false;
      this._elementRef.nativeElement.querySelector('ul').hidden = false;
    }

    /***************************************************************************************************************
     *  Gestion Signature coemprunteur : if sign key existe (le coemprunteur arrive depuis le lien reçu dans son mail)
     ***************************************************************************************************************/
    if (this.signkey !== undefined && this.signkey !== '') {
      this.modalService.open(AccountCoemprunteurComponent, { backdrop: 'static' });
    }

    /******************************************************
     * TAGGAGE Tealium
     *****************************************************/
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Accueil', 'home'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Home', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  /***************************************************************************
   *  Initialisation ICV LIGHT
   ****************************************************************************/
  initIcvLight() {
    // Init scoringLight attributes
    this.inputScoringLight = new ScoringlightModel();
    this.inputScoringLight.nombreEnfants = 0;
    this.enfantAchargeForm.setValue({ enfant: 0 });

    this.situationFamilialeForm.value.situationFamiliale = null;
    this.outputScoringLight = new ScoringlightModel();
    // init boolean
    this.revenuSeul = true;
    this.calculDisable = true;
    this.showRestimation = false;
    this.infoCapacite = false;
    this.cpInvalid = false;
    this.cpDomTom = false;
    this.ongletEnfantInactif = true;
    this.ongletCodePostaltInactif = true;
    this.ongletRevenuInactif = true;
    this.ongletChargesInactif = true;
    this.onglet1Inactif = false;
    this.chargeRevenuInvalid = false;
    this.revenusFoyerNull = false;
    this.revenuFoyer = null;
    this.afficherInfoCapaciteCalculee = false;
  }

  /***********************************************
   * INIT tabs
   **********************************************/
  ngAfterViewInit() {
    this.innerWidth = window.innerWidth;
    if (this.icvSimpleTabs) {
      this.icvSimpleTabs.select('family');
    }

    setTimeout(() => {
      const param = this.route.snapshot.fragment;
      if (param != null) {
        const section = this._elementRef.nativeElement.querySelector('.' + param);
        section.scrollIntoView({ behavior: 'smooth', block: 'start' }, true);
      }
    }, 1000);
    this.ariaAdjustments();
  }

  ngAfterViewChecked() { }

  /***********************************************************
   * Show modale ma capacité d'emprunt
   ***********************************************************/
  openModaleCapaciteEmprunt() {
    // Taggage Tealium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Home', 'home'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Home', 'Header', ''),
        new ClicModel('La capacité d emprunt, qu est ce que c est', 'navigation', 'Home', 'Header', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.modalService.open(CapaciteEmpruntModaleComponent);
  }

  /************************************************************
   * Passer onglet suivant
   * @param id du tab suivant
   ***********************************************************/
  activerSuivant(id) {
    if (this.innerWidth > 414 || id === 'totalCelibataire' || id === 'totalCouple') {
      setTimeout(() => {
        this.icvSimpleTabs.select(id);
        this.ariaAdjustments();
      }, 0);
    } else {
      this.showTabList();
    }
  }

  /***************************************************************
   * Valider situation familiale
   **************************************************************/
  validerSituationFamiliale() {
    // Taggage Tealium pageview
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Situation Familiale - Valider', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Home', 'Situation familiale', this.situationFamilialeForm.value.situationFamiliale),
        new ClientModel(),
        new CustomModel(this.situationFamilialeForm.value.situationFamiliale, '', '', '', '', '', '', '', '')
      )
    );

    this.inputScoringLight.situationFamiliale = this.situationFamilialeForm.value.situationFamiliale;

    if (this.inputScoringLight.situationFamiliale !== '') {
      switch (this.inputScoringLight.situationFamiliale) {
        case 'CELIBATAIRE': {
          this.revenuSeul = true;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.CELIBATAIRE;
          break;
        }
        case 'MARIE': {
          this.revenuSeul = false;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.MARIE;
          break;
        }
        case 'PACSE': {
          this.revenuSeul = false;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.PACSE;
          break;
        }
        case 'CONCUBINAGE': {
          this.revenuSeul = false;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.CONCUBINAGE;
          break;
        }
        case 'VEUF': {
          this.revenuSeul = true;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.VEUF;
          break;
        }
        case 'SEPARE': {
          this.revenuSeul = true;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.SEPARE;
          break;
        }
        case 'DIVORCE': {
          this.revenuSeul = true;
          this.outputScoringLight.situationFamiliale = SituationfamilialeEnum.DIVORCE;
          break;
        }
      }
      // Si passage de célibataire à en couple ou de en couple à célibataire on initialise les revenus
      this.revenuFoyer = this.inputScoringLight.montantRevenus;
      this.outputScoringLight.montantRevenusConjoint = null;
      this.outputScoringLight.montantRevenus = this.revenuFoyer;
      // Vérifier champs valides non null pour le calcul ma capacité d'emprunt
      this.okCalcul();
      // Appel fonction Calcul icv
      if (!this.calculDisable) {
        this.calculerIcvLight();
      } else {
        // Passer à l'onglet suivant
        this.ongletEnfantInactif = false;
        this.activerSuivant('children');
      }
    }
  }

  /*****************************************************************
   * Valider enfants à charge
   *****************************************************************/
  validerEnfants() {
    // Taggage Tealium pageview
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Nombre d enfants a charge - Valider', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Icv Light', 'Nombre d enfants a charge', this.enfantAchargeForm.value.enfant),
        new ClientModel(),
        new CustomModel('', this.enfantAchargeForm.value.enfant, '', '', '', '', '', '', '')
      )
    );

    this.inputScoringLight.nombreEnfants = this.enfantAchargeForm.value.enfant;
    if (this.inputScoringLight.nombreEnfants === null) {
      this.enfantNull = true;
    } else {
      this.enfantNull = false;
      this.outputScoringLight.nombreEnfants = this.inputScoringLight.nombreEnfants;
      // Vérifier champs valides pour le calcul ma capacité d'emprunt
      this.okCalcul();
      // Appel fonction Calcul icv
      if (!this.calculDisable) {
        this.calculerIcvLight();
      } else {
        // Passer à l'onglet suivant
        this.ongletCodePostaltInactif = false;
        this.activerSuivant('postCode');
      }
    }
  }

  /************************************************************************
   * Valider code postal
   ************************************************************************/
  validerCP() {
    // Taggage Tealium pageview
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Code Postal d habitation - Valider', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Icv Light', 'Code Postal d habitation', this.codePostalForm.value.codePostal),
        new ClientModel(),
        new CustomModel('', '', this.codePostalForm.value.codePostal, '', '', '', '', '', '')
      )
    );

    let codesPostaux = null;
    this.cpNull = false;
    this.inputScoringLight.codePostal = this.codePostalForm.value.codePostal;
    if (this.inputScoringLight.codePostal === '') {
      this.cpNull = true;
      this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
    } else if (this.strStartsWith(this.inputScoringLight.codePostal, '97') || this.strStartsWith(this.inputScoringLight.codePostal, '98')) {
      // Vérifier si DOM-TOM
      this.cpDomTom = true;
      this.cpInvalid = false;
      this.outputScoringLight.codePostal = this.inputScoringLight.codePostal;
      this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
    } else {
      this.cpDomTom = false;
      // Vérifier si code postal est dans la liste du fichier json
      this.scoringLightService.getListValidCPLight().subscribe(
        success => {
          codesPostaux = success;
          for (let i = 0; i < codesPostaux.length; i++) {
            if (this.inputScoringLight.codePostal === codesPostaux[i].cp) {
              this.cpInvalid = false;
              this.outputScoringLight.codePostal = this.inputScoringLight.codePostal;
              // Vérifier si champs valides pour le calcul ma capacité d'emprunt sinon redirection vers l'onglet suivant
              this.okCalcul();
              // Appel fonction Calcul icv
              if (!this.calculDisable) {
                this.calculerIcvLight();
              } else {
                // Passer à l'onglet suivant
                this.activerSuivant('salary');
                this.ongletRevenuInactif = false;
              }
              this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
              break;
            } else {
              this.cpInvalid = true;
              this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
            }
          }
        },
        error => {
          console.log(error);
        }
      );
      this.cpInvalid = false;
      this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
    }

    this.getPostalCodeErrorList(this.cpInvalid, this.cpDomTom, this.cpNull, this.codePostalForm)
  }
  strStartsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
  }

  /***************************************************************
   * Valider revenus
   ***************************************************************/
  validerRevenus() {
    // Taggage Tealium pageview
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Revenus net par mois - Valider', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Icv Light', 'Vos revenus', ''),
        new ClientModel(),
        new CustomModel('', '', '',
          this.revenuSeul ? this.revenuSeulForm.value.revenusSeul : this.revenusCoupleForm.value.monRevenu,
          this.revenusCoupleForm.value.revenuConjoint,
          '', '', '', '')
      )
    );

    this.revenusNull = false;
    this.revenusFoyerNull = false;
    this.revenusNegatives = false;
    this.revenusConjointNegatives = false;

    // Contrôle champs obligatoires revenus seul
    if (this.revenuSeul) {
      this.inputScoringLight.montantRevenus = this.revenuSeulForm.value.revenusSeul;
      this.revenusCoupleForm.setValue({ monRevenu: this.inputScoringLight.montantRevenus, revenuConjoint: null });
      if (this.inputScoringLight.montantRevenus === null) {
        this.revenusNull = true;
        this.outputScoringLight.montantRevenus = null;
      } else if (this.inputScoringLight.montantRevenus < 0) {
        // Contrôle Revenus négatifs
        this.revenusNegatives = true;
        this.outputScoringLight.montantRevenus = null;
      } else {
        this.revenuFoyer = this.inputScoringLight.montantRevenus;
        this.outputScoringLight.montantRevenus = this.revenuFoyer;
        // Vérifier champs valides pour le calcul ma capacité d'emprunt
        this.okCalcul();
        // Appel fonction Calcul icv
        if (!this.calculDisable) {
          this.calculerIcvLight();
        } else {
          // Passer à l'onglet suivant
          this.activerSuivant('wages');
          this.ongletChargesInactif = false;
        }
      }
      this.revenus_error_list = `${this.revenusNull ? 'revenus_error_1' : ''} ${this.revenusNegatives ? 'revenus_error_2' : ''}`
    } else {
      this.inputScoringLight.montantRevenus = this.revenusCoupleForm.value.monRevenu;
      this.inputScoringLight.montantRevenusConjoint = this.revenusCoupleForm.value.revenuConjoint;
      this.revenuSeulForm.setValue({ revenusSeul: this.inputScoringLight.montantRevenus });
      // Contrôle champs obligatoires revenus foyer
      if (this.inputScoringLight.montantRevenus === null && this.inputScoringLight.montantRevenusConjoint === null) {
        this.revenusFoyerNull = true;
        this.outputScoringLight.montantRevenus = null;
      } else if (this.inputScoringLight.montantRevenus < 0 && this.inputScoringLight.montantRevenusConjoint < 0) {
        // Contrôle Revenus négatifs
        this.revenusNegatives = true;
        this.revenusConjointNegatives = true;
        this.outputScoringLight.montantRevenus = null;
      } else if (this.inputScoringLight.montantRevenus < 0 && this.inputScoringLight.montantRevenusConjoint >= 0) {
        this.revenusNegatives = true;
        this.revenusConjointNegatives = false;
      } else if (this.inputScoringLight.montantRevenusConjoint < 0 && this.inputScoringLight.montantRevenus >= 0) {
        this.revenusConjointNegatives = true;
        this.revenusNegatives = false;
      } else {
        this.revenuFoyer = this.inputScoringLight.montantRevenus + this.inputScoringLight.montantRevenusConjoint;
        this.outputScoringLight.montantRevenus = this.revenuFoyer;
        // Vérifier champs valides pour le calcul ma capacité d'emprunt
        this.okCalcul();
        // Appel fonction Calcul icv
        if (!this.calculDisable) {
          this.calculerIcvLight();
        } else {
          // Passer à l'onglet suivant
          this.activerSuivant('wages');
          this.ongletChargesInactif = false;
        }
      }
      this.revenusCouple_error_list = `${this.revenusFoyerNull ? 'revenusCouple_error_1' : ''} ${this.revenusNegatives ? 'revenusCouple_error_2' : ''} ${this.revenusConjointNegatives ? 'revenusCouple_error_3' : ''}`
    }
  }

  /**************************************************************
   * Valider charges et passer dernier onglet contrôle sur les champs non null
   ***************************************************************/
  validerCharges() {
    // Taggage Tealium pageview
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Charges par mois - Valider', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Icv Light', 'Charges par mois', this.chargeForm.value.charges),
        new ClientModel(),
        new CustomModel('', '', '', '', '', this.chargeForm.value.charges, '', '', '')
      )
    );

    this.chargesNull = false;
    this.chargesNegatives = false;
    this.inputScoringLight.montantCharges = this.chargeForm.value.charges;
    if (this.inputScoringLight.montantCharges === null) {
      this.chargesNull = true;
    } else if (this.inputScoringLight.montantCharges < 0) {
      this.chargesNegatives = true;
    } else {
      this.outputScoringLight.montantCharges = this.inputScoringLight.montantCharges;
    }
    this.charges_error_list = `${this.chargesNull ? 'charges_error_1' : ''} ${this.chargesNegatives ? 'charges_error_2' : ''}`
    // Vérifier champs valides pour le calcul ma capacité d'emprunt
    this.okCalcul();
    // Appel fonction Calcul icv
    if (!this.calculDisable) {
      this.calculerIcvLight();
    }
  }

  /******************************************************************************
   * Calcul du Scoring light
   *******************************************************************************/
  calculerIcvLight() {
    if (this.inputScoringLight.montantRevenus + this.inputScoringLight.montantRevenusConjoint < this.inputScoringLight.montantCharges) {
      this.chargeRevenuInvalid = true;
    } else {
      this.chargeRevenuInvalid = false;
      // Récupérer données prospect
      if (localStorage.getItem('accesKey')) {
        this.inputScoringLight.prospectAccesKey = localStorage.getItem('accesKey');
      }

      // Appel service de Calcul Scoring Light
      this.prospectService.getProspect(localStorage.getItem('accesKey')).subscribe(result => {
        localStorage.setItem('media', result['media']);
      });

      this.inputScoringLight.media = localStorage.getItem('media');
      this.scoringLightService.calculerIcvLignt(this.inputScoringLight).subscribe(
        success => {
          this.inputScoringLight = success as ScoringlightModel;
          // add id to icv light list, per session
          this.icvList.push(this.inputScoringLight.id);
          localStorage.setItem('icvLightList', JSON.stringify(this.icvList));
          // Résultat à afficher (Résultat foyer ou indiv)
          let idTAb = 'totalCelibataire';
          if (!this.revenuSeul) {
            idTAb = 'totalCouple';
          }
          this.activerSuivant(idTAb);
          this.showRestimation = true;
          this.afficherInfoCapaciteCalculee = true;

          // Taggage Tealium
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Icv Light Estimée', 'formulaire'),
              new ProduitModel(),
              new FormModel('ICV Light', ''),
              new NtxModel(this.routeNavigation, 'ICV Light', '', ''),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '')
            )
          );
        },
        error => {
          console.log(error);
          // code for error callback
          this.toastr.error('Service indisponible', '', {
            disableTimeOut: true,
            positionClass: 'toast-top-full-width',
            closeButton: true
          });
        }
      );
    }
  }

  /**************************************************************
   * Reset Icv Light
   ***************************************************************/
  resetIcvLight() {
    // Taggage Tealium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Icv Light Estimée', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, '', '', ''),
        new ClicModel('Recommencer l estimation', 'action', 'Icv Light', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.initIcvLight();
    this.activerSuivant('family');
    // reset formulaires
    this.codePostalForm.setValue({ codePostal: '' });
    this.revenuSeulForm.patchValue({ revenusSeul: null });
    this.revenusCoupleForm.patchValue({ monRevenu: null, revenuConjoint: null });
    this.chargeForm.patchValue({ charges: null });
    this.situationFamilialeForm.patchValue({ situationFamiliale: '' });
    setTimeout(() => {
      //set focus on family tab
      document.getElementById("selectbox1")?.focus();
    });
  }

  /***************************************************************
   * Test validation de tous les champs nécessaires pour calcul ICV
   ***************************************************************/
  okCalcul() {
    if (
      this.revenuSeul &&
      this.inputScoringLight.montantCharges !== null &&
      !this.chargesNegatives &&
      this.inputScoringLight.montantRevenus !== null &&
      !this.revenusNegatives &&
      this.inputScoringLight.situationFamiliale !== null &&
      this.inputScoringLight.nombreEnfants !== null &&
      this.inputScoringLight.codePostal !== ''
    ) {
      this.calculDisable = false;
    } else if (
      !this.revenuSeul &&
      this.inputScoringLight.montantCharges !== null &&
      !this.chargesNegatives &&
      ((this.inputScoringLight.montantRevenus !== null && !this.revenusNegatives) ||
        (this.inputScoringLight.montantRevenusConjoint !== null && !this.revenusConjointNegatives)) &&
      this.inputScoringLight.situationFamiliale !== null &&
      this.inputScoringLight.nombreEnfants !== null &&
      this.inputScoringLight.codePostal !== ''
    ) {
      this.calculDisable = false;
    }
    if (this.cpDomTom || this.cpInvalid) {
      this.calculDisable = true;
    }
  }

  /*****************************************
   * gestion des spin Nombre d'enfants
   ****************************************/
  upSpin() {
    if (this.enfantAchargeForm.value.enfant < 10) {
      this.enfantAchargeForm.setValue({ enfant: this.enfantAchargeForm.value.enfant + 1 });
    }
  }
  dowSpin() {
    if (this.enfantAchargeForm.value.enfant > 0) {
      this.enfantAchargeForm.setValue({ enfant: this.enfantAchargeForm.value.enfant - 1 });
    }
  }

  /******************************************
   * Gestion responsive icv light
   ******************************************/
  showTabList() {
    if (this.innerWidth <= 414) {
      this._elementRef.nativeElement.querySelector('ul').hidden = false;
      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = true;
    }
  }

  showTabContent(ongletName: string, etape: string) {
    if (this.innerWidth <= 414) {
      this._elementRef.nativeElement.querySelector('ul').hidden = true;
      this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0].hidden = false;
    }
    // Taggage Tealium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel(ongletName, 'formulaire'),
        new ProduitModel(),
        new FormModel('ICV Light', etape),
        new NtxModel(this.routeNavigation, 'ICV Light', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  /**************************************************************
   * Redirection vers account / icvFinal / dashboard
   **************************************************************/
  account() {
    // Taggage Tealium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Icv Light Estimée', 'formulaire'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, '', '', ''),
        new ClicModel('Affiner mon estimation', 'action', 'Icv Light', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    // Utilisateur non connecté
    if (localStorage.getItem('isLoggedIn') === 'false' || localStorage.getItem('isLoggedIn') === null) {
      this.modalService.open(AccountComponent, { backdrop: 'static' }).componentInstance.name = 'signin';
    } else if (localStorage.getItem('AccountInfos') !== null) {
      // Utilisateur connecté
      const infoUser = JSON.parse(localStorage.getItem('AccountInfos'));
      if (infoUser.firstIcvFinal) {
        // si c'est son premier icv complet
        this.routeNavigation.navigate(['features/icv-complet']); // redirect to icv complet parcours
      } else {
        // else redirect to dashboard
        this.routeNavigation.navigate(['features/dashboard']);
      }
    }
  }

  // ajouter un nouveau apporteur
  registerNieProspect(codeApporteur) {
    this.prospect.codeApporteurAffaire = codeApporteur.toUpperCase();
    this.prospectService.registerNieProspect(this.prospect).subscribe(
      response => {
        this.prospect = response as ProspectModel;
        //  Open new tab with accesKey param
        localStorage.setItem('accesKey', this.prospect.accesKey)
      },
      error => {
        this.modalService.open(ErreurModalComponent);
      }
    );
  }

  getTextFile(event) {
    if (typeof this.lines == "undefined") {
      this.http.get('assets/transcription.txt', { responseType: 'text' })
        .subscribe(data => {
          this.lines = data.split(/[\r\n]+/);
        });
    }
  }

  getPostalCodeErrorList(cpInvalid, cpDomTom, cpNull, codePostalForm) {
    let error_list = '';
    error_list += cpInvalid ? ' postal_code_error_1 ' : '';
    error_list += cpDomTom ? ' postal_code_error_2 ' : '';
    error_list += cpNull ? ' postal_code_error_3 ' : '';
    error_list += codePostalForm.dirty && codePostalForm.invalid ? ' postal_code_error_4 ' : '';
    this.postal_code_error_list = error_list.replace('  ', ' ');
  }


  /** ARIA SECTION START  **/
  ariaAdjustments() {
    setTimeout(() => {
      let list = this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('nav-item');
      let listUl = this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('nav-pills')[0];
      let panel = this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('tab-content')[0];
      listUl.setAttribute("aria-orientation","vertical");
      listUl.addEventListener("keydown", function (e) {
        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
          e.preventDefault();
        }
      }, false);
      panel.setAttribute('tabindex',"-1");
      this.listTabsIds = [];
      this.listTabsVisibility = [];
      if (list && list.length) {
        for (let index = 0; index < list.length; index++) {
          list[index].setAttribute('role',"presentation");
          let child = list[index].children[0];
          if (child) {
            if (child?.id != 'totalCouple' && child?.id != 'totalCelibataire') {
              console.log('child', child)
              this.listTabsVisibility = [...this.listTabsVisibility, !child?.innerHTML.includes('disabled')]
              this.listTabsIds = [...this.listTabsIds, child?.id]
            }
            if (child?.innerHTML.includes('disabled')) {
              child.setAttribute('disabled', "");
            } else {
              child.removeAttribute('disabled');
            }
            child.setAttribute('tabindex', "-1");
            if (child.getAttribute('aria-selected') == "true") {
              child.setAttribute('tabindex', "0");
            }
          }
          if (index > 4) {
            let elm = list[index];
            elm.style.display = 'none';
            //exception for lastElement handling focus
            if (child.getAttribute('aria-selected') == "true") {
              list[4].children[0].setAttribute('tabindex', "0");
            }
          }
        }
      }
    }, 0);
  }

  transfertAttrsFromchildtoParent(child, parent, attrs: string[]) {
    attrs.forEach(attr => {
      if (child.getAttribute(attr)) {
        parent.setAttribute(attr, child.getAttribute(attr));
        child.removeAttribute(attr);
      }
    });
  }

  navigationFleche(event, isForward) {
    let direction = isForward ? 'forward' : 'back';
    let currentIndex = this.listTabsIds.includes(document?.activeElement?.id) ? this.listTabsIds.indexOf(document.activeElement.id) : this.listTabsIds.indexOf(this.icvSimpleTabs.activeId);
    let nextIndex = this.getNextElement(direction, currentIndex, this.listTabsIds.length);
    if (this.listTabsVisibility[nextIndex]) {
      document.getElementById(this.listTabsIds[nextIndex])?.focus();
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  getNextElement(direction, currentIndex, length) {
    let nextIndex = currentIndex;
    if (direction == "back") {
      if (currentIndex - 1 < 0) {
        nextIndex = length - 1;
      } else {
        nextIndex--
      }
    }
    if (direction == "forward") {
      if (currentIndex + 1 >= length) {
        nextIndex = 0;
      } else {
        nextIndex++
      }
    }
    return nextIndex;
  }

  selectTab() {
    if (this.listTabsIds.includes(document?.activeElement?.id)) {
      this.icvSimpleTabs.select(document?.activeElement?.id);
    }
  }

  tabChangeEvent() {
    this.ariaAdjustments();
  }

  
  /** ARIA SECTION END  **/
}