import { Component, OnInit, OnDestroy } from '@angular/core';
import { TealiumUtagService } from './tealium/utag.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { HealthService } from './features/services/health.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isAppVisible: boolean = true;

  constructor(
    private tealium: TealiumUtagService,
    private route: Router,
    private conf: HealthService,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {
    /**** Config Tealium *****/
    if (location.href.includes('.dev2.') || location.href.includes('localhost')) {
      this.tealium.setConfig({ account: 'natixis', profile: 'ntx-nfi-w-samycredit', environment: 'dev' });
    } else if (location.href.includes('.rec3.')) {
      this.tealium.setConfig({ account: 'natixis', profile: 'ntx-nfi-w-samycredit', environment: 'dev' });
    } else if (location.href.includes('.qua.')) {
      this.tealium.setConfig({ account: 'natixis', profile: 'ntx-nfi-w-samycredit', environment: 'qa' });
    } else {
      this.tealium.setConfig({ account: 'natixis', profile: 'ntx-nfi-w-samycredit', environment: 'prod' });
    }
  }

  ngOnInit() {
    // Charger la config de l'appli
    if (location.href.includes('.qua.')) {
      this.isAppVisible = false;
    }

    this.insertKameleoonScript();

    this.conf.getConfig().subscribe(
      success => {

      },
      error => {}
    );

    this.setPageTitle();

  }

  ngOnDestroy() {

  }

  insertKameleoonScript() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;

    if (location.href.includes('localhost') || location.href.includes('.dev2.')
        || location.href.includes('.rec3.') || location.href.includes('.qua.')) {
      script.src = '//gstfzn3ksd.kameleoon.eu/kameleoon.js';
    } else {
      script.src = '//8jzonvobp8.kameleoon.eu/kameleoon.js';
    }

    document.getElementsByTagName('head')[0].appendChild(script);
  }
  private setPageTitle(): void {
    const defaultPageTitle = 'BPCE Financement';
    this.route.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;

          if (!child) {
            return this.activatedRoute.snapshot.data.title || defaultPageTitle;
          }

          while (child.firstChild) {
            child = child.firstChild;
          }

          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          } else {
            return defaultPageTitle;
          }
        })
      ).subscribe((title: string) => this.title.setTitle(title));

  }
}
