import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddHeaderInterceptor } from './addHeaderInterceptor';
import { AdminGuard } from './features/services/route-guards/admin.guard';
import { UserGuard } from './features/services/route-guards/user.guard';
import { AuthenticatedGuard } from './features/services/route-guards/authenticated.guard';
import { CollaborateurGuard } from './features/services/route-guards/collaborateur.guard';
import { UserCollaborateurGuard } from './features/services/route-guards/user-collaborateur.guard';
import { registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { ToastrModule, ToastNoAnimationModule } from 'ngx-toastr';
import { TealiumUtagService } from './tealium/utag.service';
import { AccountModule } from './features/account/account.module';
import { HomeModule } from './features/home/home.module';
import { AccountComponent } from './features/account/account.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { AccountCoemprunteurComponent } from './features/account/account-coemprunteur/account-coemprunteur.component';
import { AccountCoemprunteurModule } from './features/account/account-coemprunteur/account-coemprunteur.module';




registerLocaleData(localeFr, 'fr');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    MaterialModule,
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: true, relativeLinkResolution: 'legacy' }),
    NgbModule,
    ToastrModule.forRoot(),
    ToastNoAnimationModule.forRoot(),
    AccountModule,
    HomeModule,
    AccountCoemprunteurModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    AdminGuard,
    UserGuard,
    AuthenticatedGuard,
    CollaborateurGuard,
    UserCollaborateurGuard,
    TealiumUtagService
  ],
  entryComponents: [AccountComponent, AccountCoemprunteurComponent],
  bootstrap: [AppComponent]
})
export class AppModule {}
