export class ClientModel {
  idEnttGrpe = '14768';
  id = '';
  statut = 'prospect';
  marche = 'Retail';

  constructor() {
    if (localStorage.getItem('isLoggedIn')) {
      if (localStorage.getItem('AccountInfos') !== null && localStorage.getItem('AccountInfos') !== '') {
        this.id = JSON.parse(localStorage.getItem('AccountInfos')).referenceUtilisateur; // TODO Recuperer Id utilisateur
      }
      this.statut = 'authentifie';
    }
  }
}
