import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-erreur.modal',
  templateUrl: './erreur.modal.component.html',
  styleUrls: ['./erreur.modal.component.css']
})
export class ErreurModalComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService
  ) {}

  ngOnInit() {
    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Page erreur', 'erreur'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'Erreurs', '', ''),
        new ClientModel(),  new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
}
