import { Router } from '@angular/router';

export class NtxModel {
  site_id: string;
  environnement: string;
  metier: string;
  langue: string;
  canal: string;
  site_section: string;
  categorie: string;
  sous_categorie: string;
  produits_services: string;
  reseau: string;
  segmentation_ntx: string;
  id_client_ntx: string;
  user_logged_status: string;
  page_url_query_string_parameter: string;
  user_profil_visiteur: string;

  constructor(private route: Router, section, categorie, sous_categorie) {
    this.environnement = 'dev';
    const host = window.location.hostname;
    if (host.includes('.rec3.')) {
      this.environnement = 'rec';
    } else if (host.includes('.qua.')) {
      this.environnement = 'qa';
    } else if (host.includes('pret-personnel.bpce.fr')) {
      this.environnement = 'prod';
    }
    this.site_id = 'NTX-' + this.environnement + '-W-NFI-OFFRE-PRET-PERSO';

    this.metier = 'NFI';
    this.langue = 'fr';
    this.canal = 'siteweb';
    this.site_section = section;
    this.categorie = categorie;
    this.sous_categorie = sous_categorie;
    this.produits_services = 'pret personnel';
    this.reseau = 'BPCE Financement';
    this.segmentation_ntx = 'abcd';
    this.id_client_ntx = '';

    this.user_profil_visiteur = 'prospect';
    this.user_logged_status = 'non connecte';
    if (localStorage.getItem('isLoggedIn')) {
      if (localStorage.getItem('AccountInfos') !== null && localStorage.getItem('AccountInfos') !== '') {
        this.id_client_ntx = JSON.parse(localStorage.getItem('AccountInfos')).login; // TODO Recuperer Id utilisateur
      }
      this.user_logged_status = 'connecte';
      this.user_profil_visiteur = 'client';
      if (JSON.parse(localStorage.getItem('AccountInfos')).authorities.indexOf('ROLE_COLLABORATEUR') !== -1) {
        this.user_profil_visiteur = 'collaborateur';
      }
    }

    this.page_url_query_string_parameter = '';
    const url = this.route.url;
    if (url.includes('?')) {
      this.page_url_query_string_parameter = url.substring(url.indexOf('?'), url.length);
    }
  }
}
