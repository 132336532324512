<div class="modal-header">
  <p>EN SAVOIR + SUR BRIDGE</p>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="activeModal.dismiss('Cross click')">&times;</button>

</div>

<div class="modal-body whoarewe clearfix">
  <h5>Comment ça marche ?</h5>
    <ol>
      <li>Vous allez quitter l'environnement BPCE financement pour vous rendre  sur celui de notre partenaire Bridge.</li>
      <li>Sélectionnez votre banque principale sur laquelle sont domiciliés vos revenus et charges.</li>
      <li>Renseignez votre identifiant et mot de passe de banque en ligne.</li>
      <li>Suivez les indications données par votre banque afin de vous authentifier.</li>
      <li>Découvrez votre capacité d'emprunt définitive!</li>
    </ol>

</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary center-block" data-dismiss="modal" (click)="activeModal.dismiss('')">Fermer
  </button>
</div>
