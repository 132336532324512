import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountCoemprunteurComponent } from './account-coemprunteur.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AccountCoemprunteurComponent],
  imports: [
    CommonModule, ReactiveFormsModule, NgbModule, RouterModule
  ],
  exports : [AccountCoemprunteurComponent],
  providers: [NgbActiveModal]
})
export class AccountCoemprunteurModule { }
