export class ClicModel {
  libelle: string;
  type: string;
  event_site_section: string;
  event_categorie: string;
  event_sous_categorie: string;
  event_name: string;

  constructor(
    libelle: string,
    type: string,
    event_site_section: string,
    event_categorie: string,
    event_sous_categorie: string,
  ) {
    this.libelle = libelle;
    this.type = type;
    this.event_site_section = event_site_section;
    this.event_categorie = event_categorie;
    this.event_sous_categorie = event_sous_categorie;
    this.libelle == 'sortie-formulaire-creation-compte'? this.event_name = 'event11':this.event_name ='event10';
  }
}
