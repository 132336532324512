<div>
  <div *ngIf="!isError" class="modal-body authIdent confInscr">
      <h5 style="text-align: center;">Félicitations, la création de votre compte est validée.</h5>
      <div class="resultaticvfinal">
          <div class=" info">
              <h5>Vous pouvez calculer et / ou consulter votre Capacité d'emprunt.</h5>
          </div>
          <div class="inscriptionValidation" *ngIf="!isLoggedIn">
              <button type="submit" class="fullSize w300" (click)="openModal()">Se connecter</button>
          </div>
      </div>
  </div>
  <div *ngIf="isError" class="modal-body authIdent errorActivation">
    <h5 style="text-align: center;">Oups ! l'activation de votre compte a échoué</h5>
    <div class=" info">
      <h5>Veuillez consulter le support.</h5>
  </div>
</div>
</div>

