import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AccountActivatedComponent } from './account-activated/account-activated.component';
import { MaterialModule } from '../../material.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModule, RouterModule, MaterialModule],
  declarations: [AccountComponent, ResetPasswordComponent, AccountActivatedComponent],
  exports: [AccountComponent],
  providers: [NgbActiveModal]
})
export class AccountModule {}
