export class IcvFinalModel {
  icvFinal0DTO: {};
  icvFinalDTO: {};
  icvSimpleDTO: {};
  userDTO: {};
  iban = []; '';
  listIban = [];
  libelleCompteBridge: '';

  constructor() {}
}
