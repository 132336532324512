import { Component, OnInit, ElementRef } from '@angular/core';
import { ConstantsModel } from '../models/constants.model';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.css']
})
export class MentionsLegalesComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _elementRef: ElementRef,
    private router: Router,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService
  ) {}

  constantsModel: ConstantsModel = new ConstantsModel();

  ngOnInit() {
    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Mentions legales', 'edito'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'Transverse', '', ''),
        new ClientModel(),  new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

}
