<!-- MODAL ABANDONNER SIMU -->
<div class="modal-header">
  <p>Abandonner</p>
  <button type="button" class="close" (click)="activeModal.dismiss('close click')">&times;</button>
</div>
<div class="modal-body mdpOubli authIdent clearfix">
  <h5>Attention, en réalisant cette action, vous renoncez à cette offre de crédit.</h5>
  <p>Etes-vous sûr de vouloir renoncer à cette offre de crédit ?</p>
  <button type="submit" class="w300 mAuto" (click)="abandonnerSimulation();tagNavigation('Confirmer_Abandonner')">Confirmer</button><br />
  <button type="submit" class="w300 mAuto whiteBtn" (click)="activeModal.dismiss('cancel click');tagNavigation('Annuler_Abandonner')">Annuler</button>
</div>
