import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DashboardService } from 'src/app/features/services/dashboard.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';
import { ClicModel } from 'src/app/tealium/clic.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-abandon-simulation',
  templateUrl: './confirm-abandon-simulation.component.html',
  styleUrls: ['./confirm-abandon-simulation.component.css']
})
export class ConfirmAbandonSimulationComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private dashboardService: DashboardService,
    private tealium: TealiumUtagService,
    private route: Router,
   private utagDataService: UtagDataService) {}
  @Input() public idSimulation;
  @Output() public passEntry: EventEmitter<any> = new EventEmitter();

  ngOnInit() {}

  abandonnerSimulation() {
    this.dashboardService.abandonnerSimulation(this.idSimulation).subscribe(
      (response: any) => {
        this.passEntry.emit('abandoned');
        this.dashboardService.sendAbandonedEvent(true);  
        this.activeModal.close();
      },
      error => {}
    );
  }
  tagNavigation(clickLibelle){
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('dashboard', 'Mon suivi'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Espace Authentifié', 'Mon suivi', ''),
        new ClicModel(clickLibelle, 'navigation', 'Espace Authentifié', 'Mon suivi', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      ));
  }
}
