<div class="modal-header">
  <p *ngIf="!accesCollaborateur">Votre résultat</p>
  <p *ngIf="accesCollaborateur">Résultat</p>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-hidden="true"
    *ngIf="!accesCollaborateur"
    (click)="continuerVersSimulateur()"
  >
    &times;
  </button>
  <button type="button" class="close" (click)="fermerModale()" *ngIf="accesCollaborateur">&times;</button>
</div>
<!--ICV Hausse-->
<div class="modal-body confInscr authIdent capaCalculee clearfix" *ngIf="icvHausse">
  <h1 class="modal-title-1" *ngIf="!accesCollaborateur">Voici votre capacité d'emprunt affinée grâce aux dernières informations communiquées :</h1>
  <h1 class="modal-title-1" *ngIf="accesCollaborateur">Voici la capacité d'emprunt du client :</h1>
  <ul class="clearfix">
    <!-- INFO DEV>>>> SI L'INTERNAUTE EST SEUL (PAS DE CONJOINT), LE <li> PREND LA CLASS="solo"-->
    <li [ngClass]="{ solo: (enCouple && isFromBridge) || (!enCouple)}">
      <h2 class="modal-title-2">Seul</h2>
      <div class="informations">
        <span *ngIf="!accesCollaborateur">Montant maximum que vous pouvez emprunter*</span>
        <span *ngIf="accesCollaborateur">Montant maximum empruntable</span>
        <strong> {{ montantMax | number: '1.0-0' }} €</strong>
        <span *ngIf="!accesCollaborateur">Mensualité maximum que vous pouvez supporter*</span>
        <span *ngIf="accesCollaborateur">Mensualité maximum supportable</span>
        <strong> {{ mensualiteMax | number: '1.0-0' }} €/mois</strong>
      </div>
    </li>
    <li *ngIf="enCouple && !isFromBridge">
      <h2 class="modal-title-2">Avec votre conjoint</h2>
      <div class="informations">
        <span *ngIf="!accesCollaborateur">Montant maximum que vous pouvez emprunter*</span>
        <span *ngIf="accesCollaborateur">Montant maximum empruntable</span>
        <strong> {{ montantFoyerMax | number: '1.0-0' }} €</strong>
        <span *ngIf="!accesCollaborateur">Mensualité maximum que vous pouvez supporter*</span>
        <span *ngIf="accesCollaborateur">Mensualité maximum supportable</span>
        <strong> {{ mensualiteFoyerMax | number: '1.0-0' }} €/mois</strong>
      </div>
    </li>
  </ul>

  <p *ngIf="!accesCollaborateur">Vous allez pouvoir définir votre projet et découvrir nos offres.</p>
  <button type="submit" class="w300 mAuto" (click)="continuerVersSimulateur()" *ngIf="!accesCollaborateur">Continuer</button>
  <button type="submit" class="w300 mAuto" *ngIf="accesCollaborateur" (click)="fermerModale()">Fermer</button>
  <i *ngIf="!accesCollaborateur"
    >* Informations non contractuelles communiquées sous réserve de l'étude et de l'acceptation de votre demande de crédit par BPCE
    Financement</i
  >
</div>

<!--ICV Baisse-->
<div class="modal-body confInscr authIdent capaCalculee capaModif clearfix" *ngIf="icvBaisse">
  <h1 class="modal-title-1" *ngIf="!accesCollaborateur && !isFromBridge">Compte tenu des éléments renseignés, votre capacité d'emprunt a été affinée :</h1>
  <h1 class="modal-title-1" *ngIf="accesCollaborateur && !isFromBridge">Voici la capacité d'emprunt du client :</h1>
  <h1 class="modal-title-1" *ngIf="!accesCollaborateur && isFromBridge">Selon les informations fournies, découvrez votre capacité d’emprunt définitive :</h1>
  <h1 class="modal-title-1" *ngIf="accesCollaborateur && isFromBridge">Selon les informations fournies, découvrez votre capacité d’emprunt définitive :</h1>

  <ul class="clearfix">
    <!-- INFO DEV >>>> SI L'INTERNAUTE EST SEUL (PAS DE CONJOINT), LE <li> PREND LA CLASS="solo"-->
    <li [ngClass]="{ solo: (enCouple && isFromBridge) || (!enCouple) }">
      <h2 class="modal-title-2">Seul</h2>
      <div class="informations">
        <span *ngIf="!accesCollaborateur">Montant maximum que vous pouvez emprunter*</span>
        <span *ngIf="accesCollaborateur">Montant maximum empruntable</span>
        <strong> {{ montantMax | number: '1.0-0' }} €</strong>
        <span *ngIf="!accesCollaborateur">Mensualité maximum que vous pouvez supporter*</span>
        <span *ngIf="accesCollaborateur">Mensualité maximum supportable</span>
        <strong>{{ mensualiteMax | number: '1.0-0' }} €/mois</strong>
      </div>
    </li>
    <li *ngIf="enCouple  && !isFromBridge">
      <h2 class="modal-title-2">Avec votre conjoint</h2>
      <div class="informations">
        <span *ngIf="!accesCollaborateur">Montant maximum que vous pouvez emprunter*</span>
        <span *ngIf="accesCollaborateur">Montant maximum empruntable</span>
        <strong>{{ montantFoyerMax | number: '1.0-0' }} €</strong>
        <span *ngIf="!accesCollaborateur">Mensualité maximum que vous pouvez supporter*</span>
        <span *ngIf="accesCollaborateur">Mensualité maximum supportable</span>
        <strong> {{ mensualiteFoyerMax | number: '1.0-0' }} €/mois</strong>
      </div>
    </li>
  </ul>

  <p *ngIf="!accesCollaborateur">Vous allez pouvoir définir votre projet et découvrir nos offres.</p>
  <button type="submit" class="w300 mAuto" (click)="continuerVersSimulateur()" *ngIf="!accesCollaborateur">Continuer</button>
  <button type="submit" class="w300 mAuto" *ngIf="accesCollaborateur" (click)="fermerModale()">Fermer</button>
  <i *ngIf="!accesCollaborateur"
    >* Informations non contractuelles communiquées sous réserve de l'étude et de l'acceptation de votre demande de crédit par BPCE
    Financement</i
  >
</div>
<!--ICV ZERO-->
<div class="modal-body mdpOubli authIdent clearfix" *ngIf="icvZero">
  <p *ngIf="!accesCollaborateur && !isRegenairCall">Compte tenu des éléments renseignés, votre capacité d'emprunt ne peut pas être calculée.</p>
  <p *ngIf="!accesCollaborateur && isRegenairCall">Vous êtes déjà client BPCE Financement et nous ne pouvons pas finaliser votre demande. Pour de plus amples informations contactez votre conseiller crédit par tchat (ou prise de rendez-vous) </p>
  <p *ngIf="accesCollaborateur">Compte tenu des éléments renseignés, la capacité d'emprunt ne peut pas être calculée.</p>
  <button type="submit" class="w300 mAuto" *ngIf="!accesCollaborateur && !isRegenairCall" (click)="continuerVersSimulateur()">Fermer</button>
  <button type="submit" class="w300 mAuto" *ngIf="accesCollaborateur || isRegenairCall" (click)="fermerModale()">Fermer</button>
</div>
