import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ScoringService } from 'src/app/features/services/scoring.service';
import { AccountModel } from 'src/app/features/models/account.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-resultat-icv-complet',
  templateUrl: './resultat-icv-complet.component.html',
  styleUrls: ['./resultat-icv-complet.component.css']
})
export class ResultatIcvCompletComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  enCouple = false;
  montantMax = null;
  montantFoyerMax = null;
  mensualiteMax = null;
  mensualiteFoyerMax = null;
  icvHausse = false;
  icvZero = false;
  icvBaisse = false;
  resultaticv = null;
  autorities = [];
  role = '';
  accesCollaborateur = false;
  isRegenairCall = false;
  isFromBridge = false;

  constructor(public activeModal: NgbActiveModal, private route: Router, private scoringService: ScoringService,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService,
    private routeNavigation: Router) {
    this.subscription = this.scoringService.getMessage().subscribe(data => {
      if (data.resultatCalculIcv !== null) {
        this.resultaticv = data.resultatCalculIcv;
      }
    });
  }

  // Detruit l'écoute suite à la destruction du component
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    // Get user connecte
    const accountInfos: AccountModel = JSON.parse(localStorage.getItem('AccountInfos'));
    this.autorities = accountInfos.authorities;
    this.autorities.forEach(element => {
      if (element.includes('COLLABORATEUR')) {
        this.role = 'COLLABORATEUR';
      }
    });

    if (this.role !== '' && this.role === 'COLLABORATEUR' && this.route.url.includes('/collaborateur')) {
      // Collaborateur
      this.accesCollaborateur = true;
    } else {
      // User
      this.accesCollaborateur = false;
    }
    this.montantMax = this.resultaticv.icvFinalDTO.montantMax;
    this.montantFoyerMax = this.resultaticv.icvFinalDTO.montantFoyerMax;
    this.mensualiteMax = this.resultaticv.icvFinalDTO.mensualiteMax;
    this.mensualiteFoyerMax = this.resultaticv.icvFinalDTO.mensualiteFoyerMax;


    this.enCouple =
      this.resultaticv.icvFinalDTO.situationFamiliale === 'MARIE' ||
      this.resultaticv.icvFinalDTO.situationFamiliale === 'PACSE' ||
      this.resultaticv.icvFinalDTO.situationFamiliale === 'CONCUBINAGE';

    if(this.resultaticv.version === '2'){
      this.isRegenairCall = true;
    }

    // icv à zero //
    if ((!this.enCouple && this.montantMax === 0) || (this.enCouple && this.montantMax === 0 && this.montantFoyerMax === 0)) {
      this.icvZero = true;
    } else if (
      // icv à la baisse //
      this.resultaticv.icvSimpleDTO !== null &&
      ((!this.enCouple && this.montantMax < this.resultaticv.icvSimpleDTO.montantMax) ||
        (this.enCouple && this.montantFoyerMax < this.resultaticv.icvSimpleDTO.montantFoyerMax))
    ) {
      this.icvBaisse = true;
    } else {
      // icv à la hausse //
      this.icvHausse = true;
    }
    this.isFromBridge = localStorage.getItem('source') === 'bridge';


  }

  /**********************************************
   * Save icv final and go to dashboard
   **********************************************/
  continuerVersSimulateur() {
    this.scoringService.saveCalculScoringFinal().subscribe(
      response => {
        localStorage.setItem('apporteur',response['icvFinalDTO']['codeApporteurAffaire']);
        this.activeModal.dismiss('Cross click');
        this.route.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        };
        this.route.navigate(['features/dashboard']);
        // tag tealium
        this.tealium.view(
          this.utagDataService.constructDataPage(
            new PageModel('Mon projet', 'produit'),
            new ProduitModel(),
            new FormModel('', ''),
            new NtxModel(this.routeNavigation, 'Espace Authentifié', 'Mon Espace Client', 'Mon projet'),
            new ClientModel(),
            new CustomModel('', '', '', '', '', '', '', '', '')
          )
        );
      },
      error => {
        console.log(error);
      }
    );
  }

  fermerModale() {
    this.activeModal.dismiss('Cross click');
  }
}
