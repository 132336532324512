<div class="modal-header">
  <p>Qui sommes nous ?</p>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="activeModal.dismiss('Cross click')">&times;</button>

</div>

<div class="modal-body whoarewe clearfix">
  <h5>A propos du site web :</h5>
  <p>Voici une application innovante, simple rapide et transparente afin de vous aider à imaginer et financer
    vos projets depuis votre ordinateur, votre tablette ou votre smartphone.</p>
  Vous pouvez :
    <ul>
      <li> - Vous faire une première idée de votre capacité totale d'emprunt - montant maximum que vous pourriez
        emprunter et mensualité maximale que vous pourriez supporter - en quelques instants,</li>
      <li> - Fiabiliser et prendre connaissance de votre capacité totale d'emprunt très rapidement,</li>
      <li> - Faire une simulation en fonction du montant de votre projet,</li>
      <li> - Souscrire un crédit à la consommation dans la limite de votre capacité totale d'emprunt.</li>
      Le service est  mis à disposition pour tous clients via les enseignes partenaires de BPCE Financement
    </ul>
  <h5>A propos de BPCE Financement :</h5>
  BPCE Financement est une société de financement du groupe BPCE spécialiste du crédit à la consommation au
  service de ses partenaires.
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-secondary center-block" data-dismiss="modal" (click)="activeModal.dismiss('')">Fermer
  </button>
</div>
