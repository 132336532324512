import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { AccountModule } from '../account/account.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AccountCoemprunteurModule } from '../account/account-coemprunteur/account-coemprunteur.module';
import { MaterialModule } from '../../material.module';


@NgModule({
  imports: [
    CommonModule,
    AccountModule,
    AccountCoemprunteurModule,
    ReactiveFormsModule,
    SharedModule,
    NgbModule,
    RouterModule,
    MaterialModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
