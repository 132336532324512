<div class="modal-header">
  <p>Déconnexion</p>
  <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">&times;</button>
</div>
<div class="modal-body mdpOubli authIdent clearfix">
  <fieldset>
  <legend style="text-align: center;">
    Souhaitez-vous mettre fin à votre session ?<br />
  </legend>
  <button type="submit" class="w300 mAuto logout-margin" (click)="logout()">Oui</button>
  <button type="submit" class="w300 mAuto" (click)="activeModal.dismiss('cancel click');tagDeconnexion('Non','navigation');">Non</button>
  </fieldset>
</div>
