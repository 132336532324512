import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NieSimulateurGuard implements CanActivate  {
  constructor(private route: Router) {}
  canActivate() {
    if (location.href.includes('.dev2.') || location.href.includes('.rec3.') || location.href.includes('.uat.') || location.href.includes('.qua.') || location.href.includes('localhost'))
       return true
    else
    {
      this.route.navigate(['features/access-denied'], { skipLocationChange: true });
      return false;
    }
  }
}
