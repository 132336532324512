export class RegisterAccountRequestModel {
  login: string;
  sexe: string;
  dateNaissance: Date;
  firstName: string;
  lastName: string;
  telMobile: string;
  email: string;
  password: string;
  oldpassword: string;
  confirmPassword: string;
  acceptCgu: boolean;
  optIn: boolean;
  listIcvLight: number[];
  langKey: string;
  canal: string;
  accesKey: string;

  constructor() {
    this.login = null;
    this.sexe = '';
    this.firstName = '';
    this.lastName = '';
    this.telMobile = '';
    this.email = '';
    this.password = '';
    this.oldpassword = '';
    this.confirmPassword = '';
    this.acceptCgu = false;
    this.optIn = false;
    this.listIcvLight = [];
    this.langKey = 'fr';
    this.canal = 'front-v2';
    this.accesKey = null;
  }

  constructModelRegister(signIn: any) {
    const registerAccountRequestModel = new RegisterAccountRequestModel();
    registerAccountRequestModel.email = signIn.email;
    registerAccountRequestModel.firstName = signIn.firstName.trim();
    registerAccountRequestModel.lastName = signIn.lastName.trim();
    registerAccountRequestModel.sexe = signIn.civilite;
    registerAccountRequestModel.password = signIn.password;
    registerAccountRequestModel.telMobile = signIn.telephoneMobile;
    registerAccountRequestModel.optIn = signIn.optin;
    registerAccountRequestModel.acceptCgu = signIn.cgu;
    registerAccountRequestModel.accesKey = localStorage.getItem('accesKey') !== 'null' ? localStorage.getItem('accesKey') : null;
    registerAccountRequestModel.listIcvLight = JSON.parse(localStorage.getItem('icvLightList'));
    return registerAccountRequestModel;
  }
}
