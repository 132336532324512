import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  abandonedSubject = new Subject<any>();
  initSimulator(simulationDTO) {
    return this.http.post('/ccoweb/api/initialiser-simulateur', simulationDTO, {});
  }

  simulate(simulationDTO) {
    return this.http.post('/ccoweb/api/simuler', simulationDTO, {});
  }

  createSimulation(simulationDTO) {
    return this.http.post('/ccoweb/api/creer-simulation', simulationDTO, {});
  }
  /*************************************************************
   * get statistique des dossiers de l'utilisateur connecté
   * @param email
   *************************************************************/
  getStatSimulations(email) {
    return this.http.get('/ccoweb/api/' + email + '/statistiques', {});
  }
  /*************************************************************
   * get liste des dossiers de l'utilisateur connecté
   *************************************************************/
  getListSimulations() {
    return this.http.get('/ccoweb/api/list-simulation', {});
  }

  /*************************************************************
   * get details d'une simulation
   *************************************************************/
  getDetailSimulation(idSimulation) {
    return this.http.get('/ccoweb/api/' + idSimulation, {});
  }

  /*************************************************************
   * abandonner une simulation
   *************************************************************/
  abandonnerSimulation(idSimulation) {
    return this.http.put('/ccoweb/api/' + idSimulation + '/cancel', {});
  }

  /*************************************************************
   * résilier une assurance
   *************************************************************/
  resilierAssurance(idSimulation, role) {
    return this.http.put('/ccoweb/api/' + idSimulation + '/resilier-assurance/' + role, {});
  }

  /*************************************************************
   * éligibilité de la résiliation d'une assurance
   *************************************************************/
   eligibiliteResiliationAssurance(idSimulation, role) {
    return this.http.get('/ccoweb/api/' + idSimulation + '/eligibilite-resiliation-assurance/' + role, {});
  }

  sendAbandonedEvent(isAbondaned: any) {
    this.abandonedSubject.next({ message: isAbondaned });
  }

  getAbandonedEvent(): Observable<any> {
    return this.abandonedSubject.asObservable();
  }

  /************************************************
   * receptionnerSignature
   * @param contratDTO
   ************************************************/
  receptionnerSignature(contratDTO: any) {
    return this.http.post('/ccoweb/api/receptionner-signature', contratDTO, {});
  }

  /*****************************************
   * receptionnerRefusSignature
   * @param contratDTO
   *****************************************/
  receptionnerRefusSignature(contratDTO: any) {
    return this.http.post('/ccoweb/api/receptionner-refus-signature', contratDTO, {});
  }

  /************************************************
   * Lister les courriers de gestion de
   * l'utilisateur connecté
   ***********************************************/
  getListCourrier() {
    return this.http.post('/ccoweb/api/list-courriers', {});
  }

  /************************************************
   * récupérer le contenu d'un courrier de gestion
   * @param id
   ***********************************************/
  getCourrierGestion(login, id) {
    return this.http.get('/ccoweb/api/courrier/' + login + '/' + id, { responseType: 'arraybuffer' });
  }

   /************************************************
   * Récupérer l'url de la PAD Oneytrust
   ***********************************************/
  getUrlPadOneytrust(simulationDTO: any) {
    return this.http.post('/ccoweb/api/url-oneytrust', simulationDTO, {});
  }

}
