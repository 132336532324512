<div class="modal-header">
  <p></p>
  <button type="button" class="close" (click)="activeModal.dismiss('Cross click')">&times;</button>
</div>
<div class="modal-body bridgeModal clearfix">
  <fieldset>
    <legend>
      Attention, si vous modifiez une donnée, vous serez redirigé vers le parcours classique et l’étude de votre dossier
      sera plus longue.
      <br/>
    </legend>
    <div class="row">
      <button type="submit" class="w300 mAuto col-5 bridgeModalButton"
              (click)="annuler();">Annuler
      </button>
      <button type="submit" class="w300 mAuto col-5 bridgeModalButton logout-margin" (click)="exitBridge()">Continuer
      </button>

    </div>
  </fieldset>
</div>
