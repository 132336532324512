
<div class="modal-header">
    <p>ERREUR</p>
    <button type="button" class="close"  (click)="activeModal.dismiss('Cross click')">&times;</button>
  </div>
  <div class="modal-body clearfix">
    <p>Service momentannément indisponible.</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn center-block"  (click)="activeModal.dismiss('Cross click')">Fermer
    </button>
  </div>

