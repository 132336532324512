export class ProspectModel {
  uidProspect;
  string;
  accesKey: string;
  prenom: string;
  nom: string;
  dateNaissance: Date;
  email: string;
  sexe: string;
  telPortable: string;
  telFix: string;
  montantEpargneSalarial: number;
  dateDisponibiliteEpargneSalarial: Date;
  montantPretImmo: number;

  constructor() {}
}
