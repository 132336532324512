<ng-container *ngIf="isAppVisible">
  <head>
    <meta name="robots" content="noindex, nofollow">
  </head>
</ng-container>
<app-header></app-header>
<main role="main" class="contener container" id="contenu" tabindex="-1">
<router-outlet></router-outlet>
</main>
<app-footer></app-footer>


