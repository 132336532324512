<div class="modal-header">
<!--<p id="modal-account">Mon compte</p>-->
  <h1 class="modal-header-title">Mon compte</h1>
  <button type="button" class="close" aria-label="close" (click)="activeModal.dismiss('Cross click');tagSortieFormulaire()">&times;</button>
</div>
<div *ngIf="!showConfirmation && !showForgotPassword" class="modal-body connexionCreation">
  <!--Alerte register-->
  <div class="alertMessage">
    <div class="alert alert-success" *ngIf="alert.success"><strong>Compte enregistre avec succes!</strong></div>
    <div class="alert alert-danger" *ngIf="alert.error"><strong>Échec de l'enregistrement!</strong></div>
    <div class="alert alert-danger" *ngIf="errorEmailExists"><strong>Adresse mail non valide </strong></div>
    <!--Alerte connexion-->
    <div class="alert alert-danger" *ngIf="authenticationError">Veuillez vérifier votre identifiant ou votre mot de passe.</div>
    <div class="alert alert-danger" *ngIf="userBlocked">
      Votre compte est bloqué suite à un trop grand nombre de tentatives,<br />
      Vous allez recevoir un e-mail pour reinitialiser votre mot de passe.
    </div>
    <div class="alert alert-danger" *ngIf="userNotActivated72HError">
      Vous n'avez pas activé votre compte dans les délais. Vous devez confirmer sa création en cliquant sur le lien d'activation contenu
      dans l'e-mail "Activation de votre compte" qui vous a été adressé. Vous pouvez demander l'envoi d'un nouveau mail d'activation en
      cliquant sur le lien ci-dessous. <a (click)="requestActivationLink()"><strong>Renvoyer le mail d'activation</strong></a>
    </div>
  </div>
  <!-- <div class="tab-content"> -->
  <ngb-tabset id="#ngb-tabset" type="pills" justify="center" class="tabs" #accountTabs
    (keyup.arrowleft)="navigationFleche($event, false)" (keyup.arrowright)="navigationFleche($event, true)"
    (keyup.Space)="selectTab()" (tabChange)="tabChangeEvent()">
    <!--LOGIN TAB-->
    <ngb-tab id="login" title="Connexion">
  <!--  <button type="button" id="login" title="Connexion"> -->
      <ng-template ngbTabContent>
        <div id="loginContent" class="tab-pane fade in">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmitF1()">
            <p class="mandatory">*Informations obligatoires</p>
            <div class="form-group">
              <label for="username">Adresse mail*</label>
              <input
                formControlName="username"
                type="email"
                class="form-control"
                id="username"
                name="username"
                autocomplete="email"
                placeholder="email@exemple.com"
              />
              <div *ngIf="f1.username.dirty && f1.username.errors">
                <p class="help-block" *ngIf="f1.username.errors.required">Adresse mail obligatoire.</p>
                <p class="help-block" *ngIf="f1.username.errors.email">Adresse mail invalide.</p>
              </div>
            </div>
            <div class="form-group">
              <label for="password"
                >Mot de passe* <i>(8 caractères minimum dont au moins une majuscule, une minuscule, un chiffre)</i>
              </label>
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                placeholder="mot de passe"
                autocomplete="current-password"
                formControlName="password"
              />
              <div *ngIf="f1.password.dirty && f1.password.errors">
                <p class="help-block" *ngIf="f1.password.errors.required">Mot de passe obligatoire.</p>
                <p class="help-block" *ngIf="f1.password.errors.minlength">Votre mot de passe doit comporter au moins 8 caractères</p>
                <p class="help-block" *ngIf="f1.password.errors.pattern">Votre mot de passe doit comporter au moins une majuscule, une minuscule, un chiffre</p>
              </div>
            </div>
            <a class="forgottenMdp" (click)="showForgetPassword()">Mot de passe oublié ?</a>

            <button type="submit" [disabled]="!loginForm.valid" class="fullSize">Se connecter</button>
          </form>
        </div>
      </ng-template>
    </ngb-tab>
 <!-- </button> -->



    <!--SIGNIN TAB-->
    <ngb-tab id="signin" title="Création">
      <ng-template ngbTabContent>
        <div id="signin" class="tab-pane fade in">
        <!--  <h5>Créer un compte vous permet de sécuriser les données nécessaires au calcul de votre capacité d'emprunt.</h5>-->
          <p class="signin_desc">Vos coordonnées ne seront pas utilisées à des fins commerciales sans votre accord.</p>
        <!--  <p class="accountSousTitle">Vos coordonnées ne seront pas utilisées à des fins commerciales sans votre accord.</p>-->
          <p class="accountSousTitle"></p>
          <form [formGroup]="signInForm" (ngSubmit)="onSubmitF2()">
            <p class="mandatory">*Informations obligatoires</p>
            <div class="completeChamp clearfix">
              <div class="partLeft">
                <div class="form-group">
                  <label class="control-label" for="civilite">Civilité*</label>
                  <select *ngIf='isReadOnly'  disabled ='true' class="form-control" formControlName="civilite" name="civilite" id="civilite" autocomplete="honorific-prefix">
                    <option value=""  selected hidden>Sélectionnez</option>
                    <option value="HOMME">Monsieur</option>
                    <option value="FEMME">Madame</option>
                  </select>
                   <select *ngIf='!isReadOnly'  class="form-control" formControlName="civilite" name="civilite" id="civilite" autocomplete="honorific-prefix">
                    <option value=""  selected hidden>Sélectionnez</option>
                    <option value="HOMME">Monsieur</option>
                    <option value="FEMME">Madame</option>
                  </select>
                </div>
                <div class="form-group">
                  <label class="control-label" for="firstName">Prénom*</label>
                  <input type="text" [readOnly]="isReadOnly" class="form-control" name="firstName" id="firstname" formControlName="firstName" placeholder="Votre prénom" autocomplete="given-name"/>
                  <div *ngIf="f2.firstName.dirty && f2.firstName.errors">
                    <p class="help-block" *ngIf="f2.firstName.errors.required">Votre prénom est obligatoire.</p>
                    <p class="help-block" *ngIf="f2.firstName.errors.minlength">Votre prénom doit comporter au moins 1 caractère.</p>
                    <p class="help-block" *ngIf="f2.firstName.errors.maxlength">Votre prénom ne peut pas dépasser 50 caractères.</p>
                    <p class="help-block" *ngIf="f2.firstName.errors.pattern">Saisie incorrecte.</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label" for="lastName">Nom*</label>
                  <input type="text" [readOnly]="isReadOnly" class="form-control" name="lastName" id="lastName" formControlName="lastName" placeholder="Votre nom" autocomplete="family-name"/>
                  <div *ngIf="f2.lastName.dirty && f2.lastName.errors">
                    <p class="help-block" *ngIf="f2.lastName.errors.required">Votre nom est obligatoire.</p>
                    <p class="help-block" *ngIf="f2.lastName.errors.minlength">Votre nom doit comporter au moins 1 caractère.</p>
                    <p class="help-block" *ngIf="f2.lastName.errors.maxlength">Votre nom ne peut pas dépasser 50 caractères.</p>
                    <p class="help-block" *ngIf="f2.lastName.errors.pattern">Saisie incorrecte.</p>
                  </div>
                </div>
                <div class="form-group">
                  <label class="control-label" for="telephoneMobile">Téléphone Mobile (facultatif) </label>
                  <button mat-icon-button aria-label="Information (i)" ngbTooltip="Information nécessaire en cas de souscription d'un prêt." placement="bottom" class="customTooltip custom-account-tooltip" tooltipClass="dashboard-tooltip">
                    <mat-icon class="tooltipStyle">info</mat-icon>
                  </button>
                  <input
                    type="text"
                    [readOnly]="isReadOnly"
                    class="form-control"
                    formControlName="telephoneMobile"
                    maxlength="10"
                    name="telephoneMobile"
                    id="telephoneMobile"
                    placeholder="Votre numéro de téléphone mobile"
                    (change)="validerTelPortable()"
                    autocomplete="tel-national"
                  />
                  <p class="help-block" *ngIf="f2.telephoneMobile && f2.telephoneMobile.dirty && !validerTelPortable()">
                    Format N° de téléphone incorrect
                  </p>
                </div>
                <br />
              </div>
              <div class="partRight">
                <div class="form-group">
                  <label class="control-label" for="email">Adresse mail*</label>
                  <input type="email" [readOnly]="isReadOnly" class="form-control" name="email" id="email" formControlName="email" placeholder="E-mail" autocomplete="email"/>
                  <div *ngIf="f2.email.dirty && f2.email.errors">
                    <p class="help-block" *ngIf="f2.email.errors.required">Adresse mail obligatoire.</p>
                    <p class="help-block" *ngIf="f2.email.errors.email">Adresse mail invalide (exemple : jean.dupond@mail.com).</p>
                    <p class="help-block" *ngIf="f2.email.errors.maxlength">L'adresse mail ne doit pas dépasser 100 caractères</p>
                  </div>
                </div>
                <div class="form-group iconPass">
                  <label class="control-label" for="passord"
                    >Mot de passe* <i>(8 caractères minimum dont au moins une majuscule, une minuscule, un chiffre)</i>
                  </label>
                  <input
                    type="password"
                    class="active form-control"
                    name="password"
                    id="passord"
                    formControlName="password"
                    placeholder="Mot de passe"
                    autocomplete="new-password"
                  />
                  <div *ngIf="f2.password.dirty && f2.password.errors">
                    <p class="help-block" *ngIf="f2.password.errors.required">Mot de passe obligatoire.</p>
                    <p class="help-block" *ngIf="f2.password.errors.minlength">Votre mot de passe doit comporter au moins 8 caractères</p>
                    <p class="help-block" *ngIf="f2.password.errors.maxlength">
                      Votre mot de passe ne doit pas comporter plus de 50 caractères
                    </p>
                    <p class="help-block" *ngIf="f2.password.errors.pattern">Votre mot de passe doit comporter au moins une majuscule, une minuscule, un chiffre</p>
                  </div>
                </div>
                <div class="form-group iconPass">
                  <label class="control-label" for="confirmPassword">Confirmation du mot de passe* <i>(8 caractères minimum dont au moins une majuscule, une minuscule, un chiffre)</i>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    name="confirmPassword"
                    id="confirmPassword"
                    formControlName="confirmPassword"
                    placeholder="Confirmation du mot de passe"
                    (change)="validerConfirmPassword()"
                    autocomplete="new-password"
                  />
                  <div *ngIf="f2.confirmPassword.dirty && f2.confirmPassword.errors">
                    <p class="help-block" *ngIf="f2.confirmPassword.errors.required">Confirmation du mot de passe est obligatoire.</p>
                    <p class="help-block" *ngIf="f2.confirmPassword.errors.minlength">
                      Votre confirmation du mot de passe doit comporter au moins 8 caractères
                    </p>
                    <p class="help-block" *ngIf="f2.confirmPassword.errors.maxlength">
                      Votre confirmation du mot de passe ne doit pas comporter plus de 50 caractères
                    </p>
                    <p class="help-block" *ngIf="f2.confirmPassword.errors.pattern">Confirmation du mot de passe doit comporter au moins une majuscule, une minuscule, un chiffre</p>
                  </div>
                  <p class="help-block" *ngIf="signInForm.value.confirmPassword && f2.confirmPassword.dirty && !validerConfirmPassword()">
                    Saisie différente.
                  </p>
                </div>
              </div>
            </div>

            <div class="inscriptionValidation">
              <div class="checkbox clearfix">
                <input type="checkbox" name="cgu" id="cgu" formControlName="cgu" /> <label for="cgu"></label>
                <span
                  >En cochant cette case, j'accepte les <a [routerLink]="['/features/cgu/']" target="_blank">CGU</a> et déclare avoir pris
                  connaissance de l'<a href="https://www.notice-financement.bpce.fr/Notice_information_donnees_personnelles_Financement.pdf" target="_blank"
                    >Information sur mes données personnelles</a
                  >
                </span>
              </div>
              <div class="checkbox clearfix">
                <input type="checkbox" name="optin" id="optin" formControlName="optin" /> <label for="optin"></label>
                <span
                  >En cochant cette case, j'accepte de recevoir des offres commerciales de BPCE Financement par courriers électroniques
                  concernant ses produits ou services et ceux de ses partenaires.</span
                >
              </div>
              <button
                type="submit"
                [disabled]="
                  !signInForm.valid ||
                  (signInForm.value.telephoneMobile !== null && !validerTelPortable()) ||
                  !signInForm.value.cgu ||
                  !validerConfirmPassword()
                "
                class="w300 mAuto"
              >
                Créer mon compte
              </button>
              <p>En cliquant sur valider, vous recevrez un e-mail vous invitant à confirmer votre compte.</p>
            </div>
          </form>
        </div>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
 </div>
<!--*********************************Confirmation Creation***************************************/-->
<div *ngIf="showConfirmation && !showForgotPassword" class="modal-body confInscr authIdent clearfix">
  <h5>Félicitations, vous êtes inscrit(e) !</h5>
  <br />
  <p class="confirmationTexte">
    Un mail d'activation vient de vous être envoyé à l'adresse ci-dessous : <br /><strong class="muli">{{
      registerAccountRequestModel.email
    }}</strong>
    <br />Vérifiez qu'il n'est pas classé dans vos spam.
  </p>
  <br />
  <p>Vous pouvez d'ores et déjà calculer votre capacité d'emprunt</p>
  <button type="submit" class="w300 mAuto" (click)="activeModal.dismiss('continue button');tagPopinFelicitation()">Continuer</button>
</div>
<!--*********************************RESET PASSWORD***************************************/-->
<div *ngIf="!showConfirmation && showForgotPassword" class="modal-body mdpOubli authIdent clearfix">
  <p *ngIf="!requestResetSuccess">
    Pour obtenir un nouveau mot de passe, veuillez renseigner l'adresse mail de votre compte et cliquer sur "Envoyer"</p>
    <p *ngIf="!requestResetSuccess">
    Un lien permettant de créer un nouveau mot de passe sera envoyé par email à l'adresse renseignée.
    Si vous ne recevez pas cet email, veuillez vérifier le dossier « courriers indésirables » de votre messagerie ou contacter l'un de nos conseillers pour de l'aide supplémentaire.
  </p>
  <div class="alert alert-success" *ngIf="requestResetSuccess">
    <p>Veuillez vérifier vos nouveaux emails et suivre les instructions pour réinitialiser votre mot de passe.</p>
  </div>
  <form *ngIf="!requestResetSuccess" [formGroup]="forgetPasswordFrom" (ngSubmit)="requestResetPassword()">
    <div class="form-group">
      <label class="control-label" for="email">Adresse mail</label>
      <input
        type="email"
        class="form-control"
        name="email"
        formControlName="email"
        placeholder="exemple@mail.com"
        [(ngModel)]="emailResetRequest"
      />
      <div *ngIf="f3.email.dirty && f3.email.errors">
        <p class="help-block" *ngIf="f3.email.errors.required">Adresse mail obligatoire.</p>
        <p class="help-block" *ngIf="f3.email.errors.email">Adresse mail invalide.</p>
        <p class="help-block" *ngIf="f3.email.errors.maxlength">L'adresse mail ne doit pas dépasser 100 caractères</p>
      </div>
    </div>
    <button type="submit" class="w300 mAuto" *ngIf="!requestResetSuccess" [disabled]="!forgetPasswordFrom.valid">Envoyer</button>
  </form>
  <button type="submit" class="w300 mAuto" *ngIf="requestResetSuccess" (click)="closeRequest()">Fermer</button>
</div>
