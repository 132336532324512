<!-- MODALE CAPACITE D'EMPRUNT -->
<div class="modal-header">
  <h1 id="capacite.emprunt.title" class="capacite-emprunt-title">La Capacité d'emprunt, qu'est-ce que c'est ?</h1>
  <button type="button" class="close"  (click)="activeModal.dismiss('Cross click')">&times;</button>
</div>
<div class="modal-body clearfix">
  <h1>C'est définir, combien vous pourriez emprunter pour mieux construire vos projets.</h1>
  <p>Il vous est proposé de calculer votre capacité d'emprunt pour un crédit à la consommation.</p>
  <p>La capacité d'emprunt calculée correspond au montant maximum que vous pourriez emprunter auprès de BPCE
    Financement et à la mensualité maximale que vous pourriez supporter.</p>
  <p>Connaître à l'avance votre capacité d'emprunt vous permet de construire vos projets de manière responsable en
    étant bien informé sur vos moyens.</p>
  <h2>Comment est calculée ma capacité d'emprunt ?</h2>
  <p>Votre capacité d'emprunt est calculée à partir des informations que vous nous communiquez, notamment
    vos revenus et vos charges récurrentes.</p>
  <h2>Imaginons Claire et Thomas :</h2>
  <div class="textImg clearfix">
    <p>Claire et Thomas sont mariés depuis 10 ans et ont deux enfants. Ils sont heureux propriétaires de leur maison
      achetée grâce à un prêt immobilier sur 20 ans. Ils envisagent d'acheter une voiture et se demandent s'ils peuvent
      encore emprunter.
    </p>
    <p>
      Grâce au calcul sur-mesure de leur capacité d'emprunt, ils réalisent qu'ils peuvent encore emprunter 15 000 €
      pour réaliser leur projet, et même consacrer un budget à la rénovation de leur cuisine.
    </p>
    <img src="./assets/images/modal/capemprunt.jpg" alt=""/>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="center-block"  (click)="activeModal.dismiss('Cross click')">Fermer
  </button>
</div>
<!-- END MODALE CAPACITE D'EMPRUNT -->
