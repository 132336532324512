
export class ScoringlightModel {
  id: number;
  // Variables nécessaires au calcul d'ICV
  prospectAccesKey: string;
  // Revenu Emprunteur
  montantRevenus: number;
  // Revenu Conjoint
  montantRevenusConjoint: number;
  // Charges
  montantCharges: number;
  // Nombre d'enfants à charge
  nombreEnfants: number;
  // Situation Familiale (Marié, Pacsé, autre vie de couple, Célibataire, Veuf...)
  situationFamiliale: string;
  // code postal d'habitation
  codePostal: string;

  // Variables calculées
  // Mensualité Maximum supportable, seul
  mensualiteMax: number;
  // Mensualité Minimum supportable, seul
  mensualiteMin: number;
  // Montant Maximum du prêt si j'emprunte seul
  montantMax: number;
  // Mensualité Maximum supportable, niveau foyer
  mensualiteFoyerMax: number;
  // Mensualité Minimum supportable, niveau foyer
  mensualiteFoyerMin: number;
  // Montant Maximum du prêt si j'emprunte avec mon conjoint
  montantFoyerMax: number;

  // Variables facultatives utilisées dans le calcul d'ICV
  // Apporteur d'affaire
  apporteur: string;
  // Montant du prêt immobilier
  montantPretImmo: number;
  // Montant d'épargne salariale
  montantEpargneSalariale: number;
  media? : string ;

  constructor() {
    this.id = null;
    this.prospectAccesKey = '';
    this.situationFamiliale = null;
    this.codePostal = '';
    this.mensualiteFoyerMax = 0;
    this.mensualiteFoyerMin = 0;
    this.mensualiteMax = 0;
    this.mensualiteMin = 0;
    this.montantCharges = null;
    this.montantMax = 0;
    this.montantRevenusConjoint = null;
    this.montantRevenus = null;
    this.nombreEnfants = null;
    this.apporteur = 'AUTRE';
    this.montantPretImmo = null;
    this.montantEpargneSalariale = null;
    this.media =''
    
  }
}
