import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionsLegalesComponent } from './mentions-legales.component';
import {Ng2PageScrollModule} from 'ng2-page-scroll';

@NgModule({
  imports: [
    CommonModule,
    Ng2PageScrollModule
  ],
  declarations: [MentionsLegalesComponent], exports: [MentionsLegalesComponent]
})
export class MentionsLegalesModule { }
