import { CanActivate, Router } from '@angular/router';
import { AccountModel } from '../../models/account.model';
import { Injectable } from '@angular/core';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(private route: Router) {}
  canActivate() {
    const account: AccountModel = JSON.parse(
      localStorage.getItem('AccountInfos')
    );
    if (account.authorities.indexOf('ROLE_USER') !== -1) {
      return true;
    } else {
      this.route.navigate(['features/access-denied'], { skipLocationChange: true });
      return false;
    }
  }
}
