import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from 'src/app/features/services/account.service';
import {PageModel} from 'src/app/tealium/page.model';
import {ProduitModel} from 'src/app/tealium/produit.model';
import {UtagDataService} from 'src/app/tealium/utag-data.service';
import {FormModel} from 'src/app/tealium/form.model';
import {NtxModel} from 'src/app/tealium/ntx';
import {TealiumUtagService} from 'src/app/tealium/utag.service';
import {ClientModel} from 'src/app/tealium/client.model';
import {CustomModel} from 'src/app/tealium/custom';
import {ClicModel} from 'src/app/tealium/clic.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-bridge-form',
  templateUrl: './bridge-form-data-ko.component.html',
  styleUrls: ['./bridge-form-data-ko.component.css']
})
export class BridgeFormDataKoComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private accountService: AccountService,
              private tealium: TealiumUtagService,
              private route: Router,
              private utagDataService: UtagDataService) {
  }

  ngOnInit() {
  }


  exitBridge() {
    localStorage.setItem('source', 'classique');
    localStorage.setItem('goToMesInfos', 'true');
    this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.route.navigate(['features/dashboard']);
    this.activeModal.dismiss('logout click');
  }


}
