export class SignInModel {
  firstName: string;
  lastName: string;
  civilite: string;
  telephoneMobile: string;
  email: string;
  password: string;
  confirmPassword: string;
  cgu: boolean;
  optin: boolean;

  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.civilite = '';
    this.telephoneMobile = '';
    this.email = '';
    this.password = '';
    this.confirmPassword = '';
    this.cgu = false;
    this.optin = false;
  }
}
