import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { TealiumUtagService } from 'src/app/tealium/utag.service';

@Component({
  selector: 'app-bridge-info',
  templateUrl: './bridge-info.html',
  styleUrls: ['./bridge-info.css']
})
export class BridgeInfoComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private route: Router,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService
  ) {}

  ngOnInit() {}
}
