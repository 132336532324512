<section class="mentions maxWidth clearfix">
  <h1 class="page_title_1">Mentions légales</h1>

  <div class="content">
    <nav role="navigation" aria-label="Accéder à">
      <div class="menu center-block">
        <h2 class="menu-title">Accéder à :</h2>
        <ol>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#societe">I. Société éditrice du présent
              site </a></li>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#protection">II. Protection des données
              personnelles</a></li>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#heb">III. Hébergeur</a></li>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#intellectuelle">IV. Propriété
              intellectuelle - Copyright</a></li>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#hypertextes">V. Liens hypertextes et
              virus</a></li>
          <li><a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#reclamation">VI. Réclamation et
              médiation</a></li>
        </ol>
      </div>
    </nav>

    <div class="text-content col-md-12">
      <h3 class="section-content-title" id="societe">I. Société éditrice du présent site</h3>
      <p>
        BPCE Financement - S.A. au capital de {{ constantsModel.shareCapital }}, immatriculée au RCS de Paris sous le numéro
        439 869 587. Siège social : 7 promenade Germaine Sablon 75013 Paris. Société de financement agréée par l'Autorité de
        Contrôle Prudentiel et de Résolution (ACPR, 4 Place de Budapest, CS 92459, 75436 Paris Cedex 09). Adhérent à l'Association
        des Sociétés Financières (ASF), 24 avenue de la Grande Armée, 75854 Paris cedex 17. Intermédiaire d'assurance immatriculé
        à l'ORIAS sous le numéro 07022393. Numéro individuel d'identification d'assujetti à la TVA FR15439869587. Code APE :
        6498Z<br />
        Identifiant unique REP Emballages Ménagers et Papiers n° FR232581_01QHNQ (BPCE – SIRET 493 455 042)<br />
        BPCE Financement est détenue par BPCE à hauteur de 100%.<br />
        Téléphone : {{ constantsModel.companyPhoneNumber }}<br />
        Courrier électronique :
        <a class="a-inline" href="{{constantsModel.companyEmail}}"> {{ constantsModel.companyEmail }} </a> <br />
        Directeur de la publication : Didier Miquel<br />
        Réservation du nom de domaine :
        <a class="a-inline" href="{{constantsModel.siteUrl}}"> {{ constantsModel.nomDomaine1 }} </a> et
        <a class="a-inline" href="{{constantsModel.siteUrl}}"> {{ constantsModel.nomDomaine2 }} </a> sont des domaines
        déposés par BPCE Financement auprès de {{ constantsModel.registrationAuthority }}.<br />
        Crédit photo : Site <a class="a-inline" href="http://pexels.com/">Pexels.com</a>
      </p>

      <h3 class="section-content-title" id="protection">II. Protection des données personnelles</h3>
      <p>
        BPCE FINANCEMENT recueille des données à caractère personnel vous concernant et met en œuvre des mesures techniques et
        organisationnelles appropriées pour s'assurer que les traitements de données à caractère personnel sont effectués
        conformément à la législation applicable.
      </p>
      <p>
        En remplissant les formulaires de ce parcours en ligne, vous garantissez que les informations communiquées sont exactes
        et que vous êtes autorisé à en disposer. Si vous saisissez des données relatives à une autre personne, tel qu'un
        coemprunteur ou un cotitulaire, vous vous engagez à l'informer de la communication de ses données, des finalités
        poursuivies et de ses droits.
      </p>
      <p>
        Les données recueillies dans le cadre de ce parcours sont destinées à BPCE FINANCEMENT, responsable de traitement. Selon
        la nature du produit, du service ou du crédit sollicité, elles pourront être transmises à nos partenaires impliqués dans
        sa fourniture.
      </p>
      <p>
        Vos données sont traitées en vue de répondre à votre demande et vous fournir le produit, le service, ou le crédit que
        vous souscrivez ou souhaitez souscrire. Ces traitements sont fondés sur l'exécution de mesures précontractuelles ou
        contractuelles prises à votre profit, telles que la délivrance d'un conseil, d'une proposition commerciale, d'une
        simulation ou une souscription.
      </p>
      <p>
        En complétant les formulaires de ce parcours, vous consentez à être recontacté(e) par BPCE FINANCEMENT, notamment par
        téléphone ou par courrier électronique, afin d'accompagner et le cas échéant finaliser votre demande.
      </p>
      <p>
        Les données ainsi recueillies sont obligatoires. A défaut, votre demande ne pourrait être instruite ou son traitement
        s'en trouverait retardé.
      </p>
      <p>
        Si vous ne souscrivez pas le produit, le service ou le crédit envisagé, vos données seront conservées pour une durée de
        <strong>6 mois</strong> courant à compter de la saisie en ligne de votre demande, ou du dernier contact entrant de votre
        part.
      </p>
      <p>
        Pour exercer vos droits Informatiques et Libertés, notamment vos droits d'accès, de rectification ou d'effacement de vos
        données, vous pouvez contacter BPCE FINANCEMENT - Service Consommateurs TSA 68004 LILLE CEDEX 9 ou à l'adresse mail
        <a class="a-inline" href="mailto:financement-serviceconsommateurs@bpce.fr" rel="nofollow">
          financement-serviceconsommateurs@bpce.fr</a>.
      </p>
      <p>
        Pour en savoir plus sur la gestion de vos données personnelles, et pour savoir où et comment exercer vos droits,
        consultez notre notice d'information sur la protection des données personnelles disponible :
        <a class="a-inline" href="https://www.notice-financement.bpce.fr/Notice_information_donnees_personnelles_Financement.pdf" target="_blank">
          Notice d'information sur la protection des données personnelles</a>.
      </p>

      <h3 class="section-content-title" id="heb">III. Hébergeur</h3>
      <p>Ce site est hébergé chez ALBIANT-IT - 110 avenue de France - 75013 Paris - Tél +33 (0)9 72 72 01 73.</p>

      <h3 class="section-content-title" id="intellectuelle">IV. Propriété intellectuelle - Copyright</h3>
      <div>
        <p>
          Ce site Internet constitue une œuvre protégée en France par le code de la propriété intellectuelle et, à l'étranger,
          par
          les conventions internationales en vigueur sur le droit d'auteur. La reproduction de tout ou partie de ce site ou de
          son
          contenu, par quelque procédé que ce soit, est formellement interdite, sauf autorisation expresse du directeur de la
          publication. La violation de l'un des droits d'auteur de l'œuvre est un délit de contrefaçon passible, aux termes de
          l'article L. 335-2 du code de la propriété intellectuelle, de trois ans d'emprisonnement et de 300 000 euros
          d'amende.</p>
        <p>
          Les marques citées sur ce site sont déposées par les entités du groupe BPCE qui en sont
          propriétaires ou détiennent tout droit à cet effet de leurs titulaires. Toute reproduction ou imitation totale ou
          partielle des marques et/ou logos est interdite sans l'accord préalable de leurs propriétaires ou de leurs
          titulaires,
          conformément aux articles L. 713-2 et L. 713-3 du code de la propriété intellectuelle. Il en est de même des bases
          de
          données figurant au sein du site dont le contenu est protégé, notamment par les dispositions de la loi du 1er
          juillet 1998
          relative à la protection juridique des bases de données. La violation de ce droit est constitutive de contrefaçon et
          passible à ce titre de poursuites judiciaires, tant au plan civil que pénal.
        </p>
      </div>

      <h3 class="section-content-title" id="hypertextes">V. Liens hypertextes et virus</h3>
      <p>
        Les liens hypertextes, éventuellement mis en place dans le cadre du site en direction d'autres sites présents sur le
        réseau Internet, ne sauraient engager la responsabilité de BPCE Financement. Le fait de fournir des liens hypertextes
        ne constitue, en aucune manière, un conseil ou un avis concernant le contenu des sites auxquels ils renvoient.<br />
        En outre, les utilisateurs et visiteurs du site ne peuvent mettre en place un hyperlien en
        direction du présent site sans l'autorisation expresse et préalable de BPCE Financement.<br />
        De façon générale, il appartient à l'utilisateur de prendre les précautions nécessaires pour s'assurer que le site
        sélectionné n'est pas infesté de virus ou de tout autre parasite de nature destructive. En aucun cas, BPCE Financement
        ne pourra être tenu responsable des dommages directs ou indirects résultant de l'usage de son site web ou d'autres sites
        qui lui sont liés.<br />
        L'utilisateur reconnaît avoir connaissance des contraintes et limites du réseau internet (Internet et Internet mobile).<br />
        Il incombe à l'utilisateur de protéger ses équipements techniques notamment contre toute forme de contamination par des
        virus et/ou de tentative d'intrusion, BPCE Financement ne pouvant en aucun cas en être tenu pour responsable.<br />
        L'utilisateur est seul responsable de l'installation, de l'exploitation et de la maintenance de ses équipements techniques
        nécessaires pour consulter le site internet. En aucun cas BPCE Financement ne saurait être tenu responsable si le site
        internet s'avère incompatible ou présente des dysfonctionnements avec certains équipements de
        l'utilisateur. En outre, BPCE Financement n'assume aucune responsabilité pour les dommages qui pourraient être causés
        aux équipements des utilisateurs.
      </p>

      <h3 class="section-content-title" id="reclamation">VI. Réclamation et médiation</h3>

      <p><strong>Si vous êtes client de BPCE Financement, vous avez souscrit un crédit,</strong></p>
      <ul>
        <li>Rapprochez-vous, tout d'abord, de votre Centre de Relation Clientèle.</li>
        <li>
          Si vous considérez que la réponse que vous avez reçue n'est pas satisfaisante, vous pouvez adresser votre demande, par
          voie postale ou par voie électronique à l'adresse suivante :
        </li>
        <br />
        <dl>
          <dd>BPCE Financement</dd>
          <dd>Service Consommateurs</dd>
          <dd>TSA 68004</dd>
          <dd>59781 LILLE CEDEX 9</dd>
          <dd>
            <a class="external-link" href="mailto:financement-serviceconsommateurs@bpce.fr" rel="nofollow">
              financement-serviceconsommateurs@bpce.fr
            </a>
          </dd>
        </dl>
        <p>BPCE Financement s'engage à vous apporter une réponse sous dix (10) jours.</p>
        <ul>
          <li>
            En dernier recours et si aucun accord n'a pu être trouvé, vous pouvez saisir gratuitement, le médiateur de
            l'Association Française des Sociétés Financières :
          </li>
          <ul>
            <li>par voie postale (courrier simple) à M. le médiateur de l'ASF - 75854 Paris cedex 17</li>
            <li>
              par la saisie en ligne d'un formulaire sur le site du médiateur :
              <a href="http://lemediateur.asf-france.com/">http://lemediateur.asf-france.com/</a>
            </li>
          </ul>
        </ul>
        <br />
        Le médiateur est compétent pour traiter les litiges des clients « particuliers » relatifs aux services fournis et à
        l'exécution des contrats conclus en matière d'opérations de banque, de services de paiement, de services d'investissement,
        d'instruments financiers et de produits d'épargne, dans le délai d'un an à compter de la réclamation faite auprès de
        BPCE Financement.
        <br />La Charte de la médiation est disponible sur le site internet du médiateur ASF :
        <a href="http://lemediateur.asf-france.com/">http://lemediateur.asf-france.com/</a>
        <br /><p>La saisine du médiateur ne peut intervenir qu'après épuisement des voies de recours internes précédemment indiquées.
        Il vous répondra directement dans un délai maximum de quatre-vingt-dix (90) jours. La procédure de médiation est gratuite,
        elle suspend les délais de prescription (article 2238 du code civil).</p> <p>Vous pouvez également déposer votre
        réclamation sur la plateforme européenne de règlement par voie extrajudiciaire des litiges en ligne qui orientera votre
        demande :
        <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a></p>
      </ul>
    </div>
  </div>
</section>
