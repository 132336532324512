import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountComponent } from '../account.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';


@Component({
  selector: 'app-account-activated',
  templateUrl: './account-activated.component.html',
  styleUrls: ['./account-activated.component.css']
})
export class AccountActivatedComponent implements OnInit {
  constructor(private modalService: NgbModal, private route: ActivatedRoute, private accountService: AccountService,
    private tealium: TealiumUtagService,
    private router: Router,
    private utagDataService: UtagDataService) {}
  isLoggedIn: any;
  key: any;
  isError: any = true;
  ngOnInit() {

    this.key = this.route.snapshot.queryParamMap.get('key');
    if (this.key !== undefined && this.key !== null) {
      this.accountService.activate(this.key).subscribe(
        value => {
          this.isError = false;
        },
        error => {
          console.log('account activation error ==>' + error);
        }
      );
    }
    if (localStorage.getItem('isLoggedIn') === 'false' || localStorage.getItem('isLoggedIn') === null) {
      this.isLoggedIn = false;
    } else {
      this.isLoggedIn = true;
    }
        // Taggage Telium
        this.tealium.view(
          this.utagDataService.constructDataPage(
            new PageModel('Activation compte', 'home'),
            new ProduitModel(),
            new FormModel('', ''),
            new NtxModel(this.router, 'Activation mon compte', '', ''),
            new ClientModel(),
            new CustomModel('', '', '', '', '', '', '', '', '')
          )
        );
  }

  openModal() {
    this.modalService.open(AccountComponent);
  }
}
