import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProspectService {
  constructor(private http: HttpClient) {}

  /*********************************
   * Save prospect NIE
   ********************************/
  public registerNieProspect(data) {
    return this.http.post('/ccoweb/api/register-nie-prospect', data, {});
  }

  /*********************************
   * Save prospect CFF
   ********************************/
  public registerCffProspect(data) {
    return this.http.post('/ccoweb/api/register-cff-prospect', data, {});
  }

  /*********************************
   * Get prospect by acceskey
   ********************************/
  public getProspect(accesKey) {
    const prospectData = {
      accesKey: '' + accesKey
    };
    return this.http.post('/ccoweb/api/charger-prospect', prospectData, {});
  }
}
