import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAriaClick]'
})
export class AriaClickDirective {
    constructor(private el: ElementRef) {}
    @HostListener('keyup', ['$event']) public onKeyDown(
        input: KeyboardEvent
      ): boolean {
        if (input.code === 'Space' || input.code === 'Enter') {
            input.preventDefault()
          return this.el.nativeElement.click();
     
        }
      }
}