import { Component, OnInit } from '@angular/core';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';
import { ClicModel } from 'src/app/tealium/clic.model';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  constructor(private tealium: TealiumUtagService, private utagDataService: UtagDataService, private router: Router) {}
  isActive1: boolean;
  isActive2: boolean;
  isActive3: boolean;
  isActive4: boolean;

  ngOnInit() {
    this.isActive1 = true;
    this.isActive2 = false;
    this.isActive3 = false;
    this.isActive4 = false;
    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('FAQ General', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  activer1() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'FAQ', ''),
        new ClicModel('General', 'navigation', 'FAQ', 'FAQ', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.isActive1 = true;
    this.isActive2 = false;
    this.isActive3 = false;
    this.isActive4 = false;
  }
  activer2() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'FAQ', ''),
        new ClicModel('Espace Client', 'navigation', 'FAQ', 'FAQ', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.isActive1 = false;
    this.isActive2 = true;
    this.isActive3 = false;
    this.isActive4 = false;
  }
  activer3() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'FAQ', ''),
        new ClicModel('Souscription', 'navigation', 'FAQ', 'FAQ', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.isActive1 = false;
    this.isActive2 = false;
    this.isActive3 = true;
    this.isActive4 = false;
  }
  activer4() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'FAQ', ''),
        new ClicModel('Suivi de mon prêt', 'navigation', 'FAQ', 'FAQ', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.isActive1 = false;
    this.isActive2 = false;
    this.isActive3 = false;
    this.isActive4 = true;
  }
  close() {
    window.close();
  }

  tagGeneralSections(sectionName) {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ General', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'General', ''),
        new ClicModel(sectionName, 'navigation', 'FAQ', 'General', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
  tagEspaceSections(sectionName) {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ Espace Client', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'Espace Client', ''),
        new ClicModel(sectionName, 'navigation', 'FAQ', 'Espace Client', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagSouscriptionSections(sectionName) {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ Souscription', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'Souscription', ''),
        new ClicModel(sectionName, 'navigation', 'FAQ', 'Souscription', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagSuiviSections(sectionName) {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('FAQ Suivi de mon prêt', 'faq'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'FAQ', 'Suivi de mon prêt', ''),
        new ClicModel(sectionName, 'navigation', 'FAQ', 'Suivi de mon prêt', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
}
