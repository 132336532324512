import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-capacite.emprunt.modale',
  templateUrl: './capacite.emprunt.modale.component.html',
  styleUrls: ['./capacite.emprunt.modale.component.css']
})
export class CapaciteEmpruntModaleComponent implements OnInit, AfterViewInit {

  constructor(public activeModal: NgbActiveModal,
    private _elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let elm:  Element = document.getElementsByClassName('modal')[0];
       elm.setAttribute('aria-labelledby','capacite.emprunt.title');
    }, 0);

  }

  ngOnInit() {
  }
}
