export class CustomModel {
  situation_familiale: string;
  nombre_enfants_charge: string;
  code_postal_habitation: string;
  revenus_demandeur: string;
  revenu_conjoint: string;
  charges_mensuelles: string;
  choix_pret: string;
  emprunter: string;
  pourcentage_completude: string;
  site_affluent: string;
  device: string;
  navigateur: string;
  navigateur_version: string;
  source_trafic: string;
  systeme_exploitation: string;

  montantSouhaite?: string;
  mensualiteSouhaite?: string;
  dureeSouhaitee?: string;
  type_pret?: string;
  optionAssurance?: string;
  datePrelevement?: string;
  versement_8_jours?: string;

  //DSP2
  co_emprunteur?: string;
  dsp2?: string;
  impot_source?: string;


  constructor(
    situation_familiale: string,
    nombre_enfants_charge: string,
    code_postal_habitation: string,
    revenus_demandeur: string,
    revenu_conjoint: string,
    charges_mensuelles: string,
    choix_pret: string,
    emprunter: string,

    pourcentage_completude: string,
    montantSouhaite?: string,
    mensualiteSouhaite ?: string ,
    dureeSouhaitee ?: string ,
    type_pret ?: string ,
    optionAssurance ?: string ,
    datePrelevement ?: string ,
    versement_8_jours ?: string ,
    co_emprunteur?: string,
    dsp2?: string,
    impot_source?: string,

  ) {
    this.situation_familiale = situation_familiale;
    this.nombre_enfants_charge = nombre_enfants_charge;
    this.code_postal_habitation = code_postal_habitation;
    this.revenus_demandeur = revenus_demandeur;
    this.revenu_conjoint = revenu_conjoint;
    this.charges_mensuelles = charges_mensuelles;
    this.choix_pret = choix_pret;
    this.emprunter = emprunter;
    this.pourcentage_completude = pourcentage_completude;
    this.montantSouhaite = montantSouhaite;
    this.mensualiteSouhaite = mensualiteSouhaite;
    this.dureeSouhaitee = dureeSouhaitee;
    this.type_pret = type_pret;
    this.optionAssurance = optionAssurance;
    this.datePrelevement = datePrelevement;
    this.versement_8_jours = versement_8_jours;
    this.source_trafic = 'xxxx';
    this.site_affluent = 'xxxx';
    this.co_emprunteur = co_emprunteur;
    this.dsp2 = dsp2;
    this.impot_source = impot_source;
  }
}
