import { Component, OnInit, AfterViewInit, ViewChild, Input, SimpleChanges, OnChanges, Directive, ElementRef } from '@angular/core';
import { AccountService } from '../services/account.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginModel } from '../models/login.model';
import { SignInModel } from '../models/signin.model';
import { AlertsModel } from '../../shared/models/alerts.model';
import { RegisterAccountRequestModel } from '../models/register.account.request.model';
import { AccountModel } from '../models/account.model';
import { NgbActiveModal, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProspectService } from '../services/prospect.service';
import { ProspectModel } from '../models/prospect.model';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';
import { ClicModel } from 'src/app/tealium/clic.model';

@Directive()
export class NgbdModalContent {
  @Input()
  name;
  constructor(public activeModal: NgbActiveModal) {
    this.name = name;
  }
}
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, AfterViewInit {
  login: any;
  name: any;
  isReadOnly: boolean ;
  readOnlyTel: boolean  = true;
  blanc: '';

  readOnlyNaissance: boolean = true;

  readOnlyDate: boolean = true;
  constructor(
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private route: Router,
    private prospectService: ProspectService,
    private tealium: TealiumUtagService,
    private _elementRef: ElementRef,
    private utagDataService: UtagDataService
  ) {
    this.login = this.accountService;
    this.name = name;
  }
  // link tabset in DOM
  @ViewChild('accountTabs')
  public accountTabs: NgbTabset;

  // Variables init
  account: LoginModel;
  UserInfos: AccountModel;
  loginForm: FormGroup;
  signInForm: FormGroup;
  forgetPasswordFrom: FormGroup;
  submitted = false;
  authenticationError = false;
  userBlocked = false;
  userNotActivated72HError = false;
  errorEmailExists = false;
  toto = false;
  alert: AlertsModel;
  singIn: SignInModel;
  registerAccountRequestModel: RegisterAccountRequestModel = new RegisterAccountRequestModel();
  showConfirmation = false;
  showForgotPassword = false;
  emailResetRequest: string;
  errorEmailNotExists = false;
  requestResetSuccess = false;
  listTabsIds: string[];
  listTabsVisibility: boolean[];
  ngOnInit() {
    this.isReadOnly= true ;
    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Mon Compte', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Mon Compte - Création', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.account = new LoginModel();
    this.alert = new AlertsModel();
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.minLength(8), Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)]
      ]
    });
    this.singIn = new SignInModel();
    this.signInForm = this.formBuilder.group({
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      civilite: ['', [Validators.required]],
      telephoneMobile: ['', [Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ],
      cgu: ['', []],
      optin: ['', []]
    });
    this.forgetPasswordFrom = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(100)]]
    });

    // Récupérer données prospect
    this.getDonneesProspect();
  }


  /***********************************************
   * INIT tabs
   **********************************************/
  ngAfterViewInit() {
    if (this.accountTabs) {
      if (this.name != null && this.name === 'signin') {
        setTimeout(() => {
          this.accountTabs.select('signin');
        }, 0);

        // Taggage Telium
        this.tealium.view(
          this.utagDataService.constructDataPage(
            new PageModel('Mon Compte - Création', 'profil'),
            new ProduitModel(),
            new FormModel('', ''),
            new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
            new ClientModel(),
            new CustomModel('', '', '', '', '', '', '', '', '')
          )
        );
      }
      this.ariaAdjustments();
    }
  }
  // Control form Tab 1
  get f1() {
    return this.loginForm.controls;
  }
  // Control form Tab 2
  get f2() {
    return this.signInForm.controls;
  }
  // control form request Reset Robert
  get f3() {
    return this.forgetPasswordFrom.controls;
  }

  /************************************
   * login
   ***********************************/
  onSubmitF1() {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Mon compte', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Se connecter', 'action', 'Mon compte', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    let connexionOK = false;
    this.submitted = true;
    this.account = this.loginForm.value;
    this.accountService.login(this.account).subscribe(
      response => {
        this.authenticationError = false;
        this.errorEmailExists = false;
        this.userBlocked = false;
        localStorage.setItem('isLoggedIn', 'true');
        const expires_at = new Date();
        expires_at.setSeconds(expires_at.getSeconds() + response.expires_in);
        response.expires_at = expires_at.getTime();
        localStorage.setItem('tokenAuthentication', JSON.stringify(response));
        this.getAccountInfos();
        connexionOK = true;

        this.userNotActivated72HError = false;
        this.activeModal.close('Connexion Ok');
      },
      error => {
        connexionOK = false;
        this.handleError(error);
      }
    );
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
  }
  /*****************************************
   * handleError
   ****************************************/
  private handleError(error: any) {
    if (error) {
      if (error.error != null && error.error.error_description != null) {
        if (error.error.error_description.indexOf('429 - user was blocked') !== -1) {
          this.authenticationError = false;
          this.errorEmailExists = false;
          this.userNotActivated72HError = false;
          this.userBlocked = true;
        } else if (error.error.error_description.indexOf('User was not activated + 72H !') !== -1) {
          this.authenticationError = false;
          this.errorEmailExists = false;
          this.userNotActivated72HError = true;
          this.userBlocked = false;
        } else {
          this.authenticationError = true;
        }
      } else {
        this.authenticationError = true;
      }
    }
  }
  // get token infos service call
  private getAccountInfos() {
    this.accountService.getAccount().subscribe(
      success => {
        this.UserInfos = success;
        localStorage.setItem('AccountInfos', JSON.stringify(this.UserInfos));
        this.accountService.sendMessage('login');

        if (localStorage.getItem('AccountInfos') !== null) {
          const infoUser = JSON.parse(localStorage.getItem('AccountInfos'));

          if (infoUser.authorities.indexOf('ROLE_COLLABORATEUR') !== -1) {
            this.route.navigate(['features/collaborateur']);

          } else {
            if (location.href.includes('account-settings')) {
              this.route.navigate(['features/account-settings']);

            } else if (infoUser.firstIcvFinal) {
              // si c'est son premier icv complet
              this.route.navigate(['features/icv-complet']);
              this.showConfirmation = true;

            } else {
              // else redirect to dashboard
              this.route.navigate(['features/dashboard']);

            }
          }

        }

        // Taggage Telium
        this.tealium.view(
          this.utagDataService.constructDataPage(
            new PageModel('Mon Compte - Connexion Validée', 'profil'),
            new ProduitModel(),
            new FormModel('', ''),
            new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
            new ClientModel(),
            new CustomModel('', '', '', '', '', '', '', '', '')
          )
        );
      },
      error => {
        console.log(error);
        // code for error callback
      }
    );
  }
  /***************************************
   * signin
   **************************************/
  onSubmitF2() {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Mon compte', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Valider (Creation)', 'action', 'Mon compte', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    // stop here if form is invalid
    if (this.signInForm.invalid) {
      return;
    } else {
      this.singIn = this.signInForm.value;
      const credentials: LoginModel = new LoginModel();
      this.registerAccountRequestModel = this.registerAccountRequestModel.constructModelRegister(this.singIn);

      this.accountService.registerAccount(this.registerAccountRequestModel).subscribe(
        success => {
          this.errorEmailExists = false;
          credentials.username = this.singIn.email;
          credentials.password = this.singIn.password;

          // Taggage Telium
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Mon Compte - Confirmation - Création Validée', 'profil'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '')
            )
          );
          // lOGIN AFTER REGISTER
          this.loginAfterRegister(credentials);
        },
        error => {
          // code for error callback
          if (error.status === 400 && error != null && error.error != null && error.error.indexOf('invalid e-mail') !== -1) {
            this.errorEmailExists = true;
          } else {
            this.errorEmailExists = false;
          }

          // Taggage Telium
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Mon Compte - Création non terminée', 'profil'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Mon Compte', 'Mon Compte', ''),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '')
            )
          );
        }
      );
    }
  }
  /*****************************************************
   * login After Register
   ****************************************************/
  private loginAfterRegister(credentials: LoginModel) {
    this.accountService.login(credentials).subscribe(response => {
      localStorage.setItem('isLoggedIn', 'true');
      localStorage.setItem('tokenAuthentication', JSON.stringify(response));
      const expires_at = new Date();
      expires_at.setSeconds(expires_at.getSeconds() + response.expires_in);
      response.expires_at = expires_at.getTime();
      localStorage.setItem('tokenAuthentication', JSON.stringify(response));
      this.getAccountInfos();
    });
  }
  /*****************************************************
   *  Request link Activation
   * @param email
   ****************************************************/
  requestActivationLink() {
    this.accountService.requestActivationLink(this.loginForm.value.username);
  }
  /*****************************************************
   *  valider Tel Portable
   * @param telMobile
   ****************************************************/
  validerTelPortable() {
    const telMobile = this.signInForm.value.telephoneMobile;
    // Num tel doit commencer par 06 ou 07
    if (telMobile && telMobile.length === 10) {
      const reg = new RegExp('^[0-9]*$');
      /*regexp qui contrôle s'il y a des chiffes*/
      const test = reg.test(telMobile);
      if (test) {
        const TelephoneBeginWith = telMobile.slice(0, 2);
        if (TelephoneBeginWith === '07' || TelephoneBeginWith === '06') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (telMobile === '') {
      return true;
    }
  }
  /**********************************************************
   *  Function to validate if the confirm Robert = Robert
   * @param password, confirmRobert
   **********************************************************/
  // Function to validate if the confirm Robert = Robert
  validerConfirmPassword() {
    const password = this.signInForm.value.password;
    const confirmPassword = this.signInForm.value.confirmPassword;
    if (confirmPassword && password && confirmPassword === password) {
      return true;
    } else {
      return false;
    }
  }
  /**********************************RESET Robert***************************************/
  showForgetPassword() {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Mon compte', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Mot de passe oublie', 'navigation', 'Mon compte', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.showForgotPassword = true;

    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Mot de passe oublie', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon Compte', 'Mon compte - Connexion', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
  requestResetPassword() {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Mot de passe oublie', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Envoyer', 'action', 'Mon compte', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.accountService.resetPassword(this.emailResetRequest).subscribe(
      response => {
        this.errorEmailNotExists = false;


        // Taggage Telium
        this.tealium.view(
          this.utagDataService.constructDataPage(
            new PageModel('Mot de passe oublie - Envoyer', 'profil'),
            new ProduitModel(),
            new FormModel('', ''),
            new NtxModel(this.route, 'Mon Compte', 'Mon compte - Connexion', ''),
            new ClientModel(),
            new CustomModel('', '', '', '', '', '', '', '', '')
          )
        );
      },
      error => {
        if (error.error === 'e-mail address not registered' && error.status === 400) {
          this.errorEmailNotExists = true;
        }
      }
    );
    this.requestResetSuccess = true;
  }
  closeRequest() {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Mot de passe oublie', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Fermer', 'navigation', 'Mon compte', '', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );

    this.showForgotPassword = false;
  }

  /**********************************************
   *  Charger les données du prospect
   *********************************************/
  getDonneesProspect() {
    if (localStorage.getItem('accesKey') !== null) {
      this.prospectService.getProspect(localStorage.getItem('accesKey')).subscribe(
        success => {
          this.isReadOnly = false;
          const prospect = success as ProspectModel;
          this.signInForm.setValue({
            firstName: prospect.prenom,
            lastName: prospect.nom,
            email: prospect.email,
            civilite: prospect.sexe,
            telephoneMobile: prospect.telPortable,
            password: this.blanc,
            confirmPassword: this.blanc,
            optin: false,
            cgu: false
          });

        if(prospect.telPortable)
        this.readOnlyTel = true ;
        else
        this.readOnlyTel = false ;


        },
        error => {
          console.log(error);
          // code for error callback
        }
      );
    }
  }

  tagSortieFormulaire() {
    let Civilite = 'Civilite=['; this.signInForm.value.civilite ? (Civilite = Civilite + '1]') : (Civilite = Civilite + '0]') ;
    let Prenom = 'Prenom=['; this.signInForm.value.firstName ? Prenom = Prenom + '1]' : Prenom = Prenom +'0]';
    let Nom = 'Nom=['; this.signInForm.value.lastName ? Nom = Nom + '1]' : Nom = Nom +'0]';
    let Telephone = 'Telephone=[';this.signInForm.value.telephoneMobile ? Telephone = Telephone+ '1]' : Telephone = Telephone +'0]';
    let AdressEmail = 'AdressEmail=[';this.signInForm.value.email ? AdressEmail = AdressEmail + '1]' : AdressEmail = AdressEmail + '0]' ;
    let BoxCGU = 'BoxCGU=[';this.signInForm.value.cgu ? BoxCGU = BoxCGU + '1]': BoxCGU = BoxCGU + '0]';
    let BoxInfo = 'BoxInfo=['; this.signInForm.value.optin ? BoxInfo = BoxInfo + '1]' : BoxInfo = BoxInfo + '0]';

    let event_categorie = Civilite + Prenom +Nom+Telephone+AdressEmail+BoxCGU+BoxInfo;


                  this.tealium.link(
                    this.utagDataService.constructDataLink(
                      new PageModel('Mon compte', 'profil'),
                      new ProduitModel(),
                      new FormModel('', ''),
                      new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
                      new ClicModel('sortie-formulaire-creation-compte', 'navigation', 'Mon Formulaire - Mon Compte - Création non terminée', event_categorie, ''),
                      new ClientModel(),
                      new CustomModel('', '', '', '', '', '', '', '', '')
                    )
                  );
  }
  tagPopinFelicitation(){
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Popin-Felicitations', 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Mon compte', 'Mon compte', ''),
        new ClicModel('Continuer', 'navigation', 'Espace Authentifié', 'Mon espace client', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  /** ARIA SECTION START  **/
  ariaAdjustments() {
    setTimeout(() => {
      let tabSetElement = this._elementRef.nativeElement.querySelector('ngb-tabset');
      if (tabSetElement) {
        let listUl = tabSetElement.getElementsByClassName('nav-pills')[0];
        listUl.addEventListener("keydown", function (e) {
          if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
        }, false);
        let list = tabSetElement.getElementsByClassName('nav-item');
        let panel = tabSetElement.getElementsByClassName('tab-content')[0];
        panel.setAttribute('tabindex', "-1");
        this.listTabsIds = [];
        this.listTabsVisibility = [];
        if (list && list.length) {
          for (let index = 0; index < list.length; index++) {
            list[index].setAttribute('role', "presentation");
            let child = list[index].children[0];
            if (child) {
              this.listTabsVisibility = [...this.listTabsVisibility, !child?.innerHTML.includes('disabled')]
              this.listTabsIds = [...this.listTabsIds, child?.id]
              if (child?.innerHTML.includes('disabled')) {
                child.setAttribute('disabled', "");
              }
              child.setAttribute('tabindex', "-1");
              if (child.getAttribute('aria-selected') == "true") {
                child.setAttribute('tabindex', "0");
              }
            }
          }
        }
      }
    }, 0);
  }

  navigationFleche(event, isForward) {
    let direction = isForward ? 'forward' : 'back';
    let currentIndex = this.listTabsIds.includes(document?.activeElement?.id) ? this.listTabsIds.indexOf(document.activeElement.id) : this.listTabsIds.indexOf(this.accountTabs.activeId);
    let nextIndex = this.getNextElement(direction, currentIndex, this.listTabsIds.length);
    if (this.listTabsVisibility[nextIndex]) {
      document.getElementById(this.listTabsIds[nextIndex])?.focus();
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  getNextElement(direction, currentIndex, length) {
    let nextIndex = currentIndex;
    if (direction == "back") {
      if (currentIndex - 1 < 0) {
        nextIndex = length - 1;
      } else {
        nextIndex--
      }
    }
    if (direction == "forward") {
      if (currentIndex + 1 >= length) {
        nextIndex = 0;
      } else {
        nextIndex++
      }
    }
    return nextIndex;
  }

  selectTab() {
    if (this.listTabsIds.includes(document?.activeElement?.id)) {
      this.accountTabs.select(document?.activeElement?.id);
    }
  }

  tabChangeEvent() {
    this.ariaAdjustments();
  }


  /** ARIA SECTION END  **/
}
