<div class="modal-header">
  <p>Votre résultat de synchronisation de compte bancaire
  </p>
  <button type="button" class="close" (click)="exitBridge()">&times;</button>
</div>
<div class="modal-body bridgeModal clearfix">
  <fieldset>
    <legend>
      <p>
      Compte-tenu des éléments synchronisés, vous n’êtes pas éligible au parcours avec notre partenaire Bridge.</p>
      <br/>
     <p> Cliquez sur «Continuer» pour poursuivre votre demande de prêt.</p>
    </legend>
    <div class="row">

      <button type="submit" class="w300 mAuto col-5 bridgeModalButton logout-margin" (click)="exitBridge()">Continuer
      </button>

    </div>
  </fieldset>
</div>
