import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CguComponent } from './cgu.component';
import {Ng2PageScrollModule} from 'ng2-page-scroll';

@NgModule({
  imports: [
    CommonModule,
    Ng2PageScrollModule
  ],
  declarations: [CguComponent],
  exports : [CguComponent]
})
export class CguModule { }
