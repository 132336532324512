import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  constructor(private http: HttpClient) {}

  loadAllApplicationServices() {
    return this.http.get('/ccoweb/api/health', {});
  }

  getConfig() {
    return this.http.get('./conf.txt', {});
  }
}
