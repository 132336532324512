import { Component, OnInit, ViewChild, ElementRef, LOCALE_ID, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { CapaciteEmpruntModaleComponent } from 'src/app/shared/modal/capacite.emprunt.modale/capacite.emprunt.modale.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ScoringService } from '../../features/services/scoring.service';
import { formatDate, DatePipe } from '@angular/common';
import { IcvFinalModel } from '../../features/models/icvFinal.model';
import { ResultatIcvCompletComponent } from 'src/app/shared/modal/resultat-icv-complet/resultat-icv-complet.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountModel } from 'src/app/features/models/account.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { ClientModel } from 'src/app/tealium/client.model';
import { ClicModel } from 'src/app/tealium/clic.model';
import { CustomModel } from 'src/app/tealium/custom';
import { event } from 'jquery';
import { BridgeInfoComponent } from '../modal/bridge-info/bridge-info.component';
import { BridgeService } from '../../features/services/bridge.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BridgeFormComponent } from '../modal/bridge-form/bridge-form.component';
import { BridgeFormDataKoComponent } from '../modal/bridge-form-data-ko/bridge-form-data-ko.component';


@Component({
  selector: 'app-informations-client-icv',
  templateUrl: './informations-client-icv.component.html',
  styleUrls: ['./informations-client-icv.component.css']
})
export class InformationsClientIcvComponent implements OnInit, AfterViewInit {
  readOnly = true;
  readOnlysexe: boolean;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private scoringService: ScoringService,
    private bridgeService: BridgeService,
    private _elementRef: ElementRef,
    private route: Router,
    private routewithParam: ActivatedRoute,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService,
    private routeNavigation: Router,
    public activeModal: NgbActiveModal
  ) { }
  // link tabset in DOM
  @ViewChild('situationPro')
  public situationPro: NgbTabset;
  @Input() public displayTag;
  @Input() public tag;
  managedIcvFinal: IcvFinalModel = new IcvFinalModel();
  icvFinalDTO: any = {};
  userDTO: any = {};
  icvSimpleDTO: any = {};
  // Forms declaration
  identiteForm: FormGroup;
  habitationForm: FormGroup;
  situationProForm: FormGroup;
  situationProConjointForm: FormGroup;
  revenusForm: FormGroup;
  chargesForm: FormGroup;
  // booleans to show different forms sections
  showList = true;
  showIdentite = false;
  showHabitation = false;
  showSituationProfessionnelle = false;
  showRevenus = false;
  showCharges = false;
  // variables to set when a form is complete
  dateNaissanceInvalide = false;
  identiteValid = false;
  habitationValid = false;
  situationProValid = false;
  revenusValid = false;
  chargesValid = false;
  // other variables used in the forms
  mineur = false;
  cpDomTom = false;
  cpInvalid = false;
  anneeEntreeHabitationValid = true;
  anneeDebutProValid = true;
  anneeDebutProConjointValid = true;
  enCouple = false;
  coupleToSeul = false;
  progressValue = 0;
  listeCspDetail: any = [];
  listeNafA: any = [];
  icvZero = false;
  icvHausse = false;
  icvBaisse = false;
  isDashboard = false;
  buttonWording = 'Calculer';
  isModifiedIcv = false;
  yearBirth = '';
  pipe = new DatePipe('fr-FR');
  autorities = [];
  role = '';
  referenceDossierNFI = '';
  idIcv = '';
  accesCollaborateur = false;
  listTabsIds: string[];
  listTabsVisibility: boolean[];
  bridgeOrClassicVal = '';
  emprunt1ou2Val = 0;
  emprunt1ou2Libelle = '';
  bridgeURL = '';
  source = '';
  isFromBridge = false;
  libelleCompteBridge = '';
  prelevementSourceOui;
  prelevementSourceNon;
  prelevementSourceSelected: boolean = null;
  emprunt1ou2Form: FormGroup;
  bridgeOrClassicForm: FormGroup;
  bridgeChargeRevenuForm: FormGroup;
  showEmprunt1ou2 = false;
  showBridgeOrClassic = false;
  emprunt1ou2Valid = false;
  ngOnInit() {
    // Get params from url
    this.routewithParam.queryParams.subscribe(params => (this.idIcv = params['idIcv']));
    // Get user connecte
    const accountInfos: AccountModel = JSON.parse(localStorage.getItem('AccountInfos'));
    this.autorities = accountInfos.authorities;
    this.autorities.forEach(element => {
      if (element.includes('COLLABORATEUR')) {
        this.role = 'COLLABORATEUR';
      }
    });

    if (this.role !== '' && this.role === 'COLLABORATEUR' && this.route.url.includes('/collaborateur')) {
      // Collaborateur
      this.accesCollaborateur = true;
      // Load icv by id
      this.loadIcvFinalById(this.idIcv);

    } else {
      // User : load icv connected user
      this.accesCollaborateur = false;
      this.routewithParam.queryParams.subscribe(
        params => ((this.source = params['provenance']))
      );



      if (this.source === 'bridge') {
        this.isFromBridge = true;
        this.tagViewDSP2('charges et revenus DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        this.loadIcvCompleteManagedWithBridgeData();
      } else {
        this.isFromBridge = false;
        this.loadIcvCompleteManaged();
      }


    }
  }

  ngAfterViewInit(): void {
    this.ariaAdjustments();
  }

  /****************************************
   * Load ICV By ID
   ****************************************/
  private loadIcvFinalById(id) {
    this.scoringService.getIcvCompleteManagedById(id).subscribe(
      response => {
        this.managedIcvFinal = response;
        this.icvFinalDTO = response.icvFinalDTO;
        this.userDTO = response.userDTO;
        this.icvSimpleDTO = response.icvSimpleDTO;
        this.initFormsAndControls();
        this.progressValue = 100;
        if (this.tag) {
          this.tealium.link(
            this.utagDataService.constructDataLink(
              new PageModel('dashboard', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace % de complétude ', 'Mes informations', '' + this.progressValue),
              new ClicModel('taux de complétude du dossier', 'navigation', 'Espace % de complétude', 'Mes informations', '' + this.progressValue),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '')
            ));
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Mes informations-scoring', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '' + this.progressValue)
            )
          );
        }


      },
      error => {
        console.log(error);
      }
    );
  }


  getBridgeConnectUrl() {
    this.bridgeService.getBridgeConnectUrl().subscribe(
      response => {
        if (response.toString() === '409') {
          this.route.navigate(['/features/dashboard'], { queryParams: { provenance: 'bridge' }}) .then(() => {
            window.location.reload();
          });
        } else {
        this.bridgeURL = response.toString();
        window.location.href = this.bridgeURL;
        return false;
      }
      }, error => {
        console.log(error);
      }
    );

  }


  /****************************************
   * Load ICV for connected user
   ***************************************/
  loadIcvCompleteManaged() {
    this.scoringService.getIcvCompleteManaged().subscribe(
      loadIcvFinalResponse => {
        this.managedIcvFinal = loadIcvFinalResponse;
        this.icvFinalDTO = loadIcvFinalResponse.icvFinalDTO;
        this.userDTO = loadIcvFinalResponse.userDTO;
        this.icvSimpleDTO = loadIcvFinalResponse.icvSimpleDTO;
        this.initFormsAndControls();
        if (this.tag) {
          this.tealium.link(
            this.utagDataService.constructDataLink(
              new PageModel('Espace Authentifié', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace Authentifié', 'Mon espace client', ''),
              new ClicModel('Mes informations', 'navigation', 'Espace Authentifié', 'Mes Mon espace client', ''),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '' + this.progressValue)
            ));
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Mes informations-scoring', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '' + this.progressValue)
            )
          );
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  /****************************************
   * Load ICV for connected user
   ***************************************/
  loadIcvCompleteManagedWithBridgeData() {
    this.scoringService.getIcvCompleteManagedWithBridgeData().subscribe(
      loadIcvFinalResponse => {
        if (loadIcvFinalResponse === null) {
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
          };
          this.modalService.open(BridgeFormDataKoComponent, ngbModalOptions);
        } else {
          this.managedIcvFinal = loadIcvFinalResponse;
          this.icvFinalDTO = loadIcvFinalResponse.icvFinalDTO;
          this.userDTO = loadIcvFinalResponse.userDTO;
          this.icvSimpleDTO = loadIcvFinalResponse.icvSimpleDTO;
          this.libelleCompteBridge = loadIcvFinalResponse.libelleCompteBridge;
          localStorage.setItem('source', 'bridge');
          localStorage.setItem('iban', JSON.stringify(loadIcvFinalResponse.iban));
          localStorage.setItem('libelleCompteBridge', loadIcvFinalResponse.libelleCompteBridge);
        }



        this.initFormsAndControls();
        if (this.tag) {
          this.tealium.link(
            this.utagDataService.constructDataLink(
              new PageModel('Espace Authentifié', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace Authentifié', 'Mes Mon espace client', ''),
              new ClicModel('Mes informations', 'navigation', 'Espace Authentifié', 'Mes Mon espace client', ''),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '' + this.progressValue)
            ));
          this.tealium.view(
            this.utagDataService.constructDataPage(
              new PageModel('Mes informations-scoring', 'information'),
              new ProduitModel(),
              new FormModel('', ''),
              new NtxModel(this.route, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
              new ClientModel(),
              new CustomModel('', '', '', '', '', '', '', '', '' + this.progressValue)
            )
          );
        }
      },
      error => {
        console.log(error);
      }
    );
  }


  /*****************************************
   * Init forms and controls
   ****************************************/
  private initFormsAndControls() {
    // initializing all forms
    this.initIdentiteForm();
    this.initHabitationForm();
    this.initSituationProForm();
    this.initSituationProConjointForm();
    this.initRevenusForm();
    this.initBridgeOrClassicForm();
    this.initChargesForm();
    this.getListCsps();
    this.getListNafA();
    this.onIdentiteChanges();
    this.onHabitationChanges();
    this.onSituationProChange();
    this.onSituationProConjointChange();
    this.incrementerProgressBar();
    this.initEmprunt1ou2Form();
    this.initBridgeChargeRevenuForm();
    // determiner la localisation dashborad ou icv
    if (this.route.url.includes('/features/dashboard')) {
      this.isDashboard = true;
      this.buttonWording = 'Mettre à jour ma capacité d\'emprunt';
    } else {
      this.isDashboard = false;
      this.buttonWording = 'Calculer ma capacité d\'emprunt';
    }
    if (this.accesCollaborateur) {
      this.buttonWording = 'Calculer la capacité d\'emprunt';
    }
    // init year of birth
    this.yearBirth = this.userDTO.dateNaissance === null ? '' : this.getAnnee(this.userDTO.dateNaissance);
  }

  getListCsps() {
    this.scoringService.getListeCsp().subscribe(
      listecsp => {
        this.listeCspDetail = listecsp;
      },
      error => {
        console.log(error);
        return [];
      }
    );
  }

  getListNafA() {
    this.scoringService.getListeNafA().subscribe(
      listenaf => {
        this.listeNafA = listenaf;
      },
      error => {
        console.log(error);
        return [];
      }
    );
  }
  initIdentiteForm() {
    this.identiteForm = this.formBuilder.group({
      sexe: [''.trim(), [Validators.required]],
      firstName: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      lastName: [
        ''.trim(),
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(50),
          Validators.pattern(/^([a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖòóôõöÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ' '-])*$/)
        ]
      ],
      situationFamiliale: ['', [Validators.required]],
      dateNaissance: [
        '',
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(10),
          Validators.pattern(/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}$/)
        ]
      ],
      ageConjoint: ['', []],
      nombreEnfants: ['', [Validators.required]]
    });

    this.identiteForm.setValue({
      sexe: this.userDTO.sexe === null ? JSON.parse(localStorage.getItem('AccountInfos'))['sexe'] : this.userDTO.sexe,
      firstName: this.userDTO.firstName === null ? JSON.parse(localStorage.getItem('AccountInfos'))['firstName'] : this.userDTO.firstName.trim(),
      lastName: this.userDTO.lastName === null ? JSON.parse(localStorage.getItem('AccountInfos'))['lastName'] : this.userDTO.lastName,
      situationFamiliale: this.icvFinalDTO.situationFamiliale === null ? '' : this.icvFinalDTO.situationFamiliale,
      dateNaissance: this.userDTO.dateNaissance === null ? '' : this.formatDate(this.userDTO.dateNaissance),
      ageConjoint: this.icvFinalDTO.ageConjoint === null ? '' : this.icvFinalDTO.ageConjoint,
      nombreEnfants: this.icvFinalDTO.nombreEnfants === null ? '' : this.icvFinalDTO.nombreEnfants
    });

    if (JSON.parse(localStorage.getItem('AccountInfos'))['codeApporteur'])
      this.readOnly = false;


    if (
      this.icvFinalDTO.situationFamiliale !== null &&
      (this.icvFinalDTO.situationFamiliale === 'MARIE' ||
        this.icvFinalDTO.situationFamiliale === 'CONCUBINAGE' ||
        this.icvFinalDTO.situationFamiliale === 'PACSE')
    ) {
      this.enCouple = true;
    }
    if (this.identiteForm.valid && (!this.enCouple || (this.enCouple && this.identiteForm.value.ageConjoint !== ''))) {
      this.identiteValid = true;
    }
  }
  private formatDate(date: string) {
    return this.pipe.transform(date, 'dd/MM/yyyy');
  }

  // Get Annee from date
  private getAnnee(date: string) {
    return this.pipe.transform(date, 'yyyy');
  }

  initHabitationForm() {
    this.habitationForm = this.formBuilder.group({
      typeHabitation: ['', [Validators.required]],
      codePostal: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5)]],
      anneeEntreeHabitation: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)]]
    });
    this.habitationForm.setValue({
      typeHabitation: this.icvFinalDTO.typeHabitation !== null ? this.icvFinalDTO.typeHabitation : '',
      codePostal: this.icvFinalDTO.codePostal === null ? '' : this.icvFinalDTO.codePostal,
      anneeEntreeHabitation: this.icvFinalDTO.anneeEntreeHabitation === null ? '' : this.icvFinalDTO.anneeEntreeHabitation
    });
    if (this.habitationForm.valid) {
      this.habitationValid = true;
    }
  }

  initSituationProForm() {
    this.situationProForm = this.formBuilder.group({
      csp: ['', [Validators.required]],
      secteurActivite: ['', [Validators.required]],
      anneeDebutPro: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)]],
      typeContrat: ['', [Validators.required]]
    });
    this.situationProForm.setValue({
      csp: this.icvFinalDTO.csp !== null ? this.icvFinalDTO.csp.code : '',
      secteurActivite: this.icvFinalDTO.nafAgrege !== null ? this.icvFinalDTO.nafAgrege : '',
      anneeDebutPro: this.icvFinalDTO.anneeDebutPro !== null ? this.icvFinalDTO.anneeDebutPro : '',
      typeContrat: this.icvFinalDTO.typeContrat !== null ? this.icvFinalDTO.typeContrat : ''
    });
  }

  initSituationProConjointForm() {
    this.situationProConjointForm = this.formBuilder.group({
      cspConjoint: ['', [Validators.required]],
      secteurActiviteConjoint: ['', [Validators.required]],
      anneeDebutProConjoint: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/^[0-9]*$/)]],
      typeContratConjoint: ['', [Validators.required]]
    });
    this.situationProConjointForm.setValue({
      cspConjoint: this.icvFinalDTO.cspConjoint !== null ? this.icvFinalDTO.cspConjoint.code : '',
      secteurActiviteConjoint: this.icvFinalDTO.nafAgregeConjoint !== null ? this.icvFinalDTO.nafAgregeConjoint : '',
      anneeDebutProConjoint: this.icvFinalDTO.anneeDebutProConjoint !== null ? this.icvFinalDTO.anneeDebutProConjoint : '',
      typeContratConjoint: this.icvFinalDTO.typeContratConjoint !== null ? this.icvFinalDTO.typeContratConjoint : ''
    });
    if (this.situationProForm.valid && !this.enCouple) {
      this.situationProValid = true;
    } else if (this.situationProForm.valid && this.situationProConjointForm.valid && this.enCouple) {
      this.situationProValid = true;
    }
  }

  initClassiqueParcoursSuiteABridgeSimulation() {
    this.emprunt1ou2Form = this.formBuilder.group({
      emprunt1ou2Val: ['1', [Validators.required]]
    });
    this.emprunt1ou2Libelle = 'J’emprunte seul(e)';
    this.bridgeOrClassicForm = this.formBuilder.group({
      bridgeOrClassicVal: ['classic', [Validators.required]]
    });
    this.revenusForm.reset();
    this.chargesForm.reset();


  }

  initEmprunt1ou2Form() {

    if (localStorage.getItem('source') === 'classique') {
      this.initClassiqueParcoursSuiteABridgeSimulation();
    } else {
      this.emprunt1ou2Form = this.formBuilder.group({
        emprunt1ou2Val: ['', [Validators.required]]
      });
    }

    if (this.emprunt1ou2Form.valid) {
      this.emprunt1ou2Valid = true;
    }
    //localStorage.setItem('source',' ');
  }

  initBridgeOrClassicForm() {

    this.bridgeOrClassicForm = this.formBuilder.group({
      bridgeOrClassicVal: ['', [Validators.required]]
    });
  }


  initRevenusForm() {
    this.revenusForm = this.formBuilder.group({
      montantRevenus: ['', [Validators.required, Validators.min(0)]],
      montantRevenusConjoint: ['', [Validators.min(0)]],
      autresRevenus: ['', [Validators.required, Validators.min(0)]]
    });
    this.revenusForm.setValue({
      montantRevenus: this.icvFinalDTO.montantRevenus === null ? '' : this.icvFinalDTO.montantRevenus,
      montantRevenusConjoint: this.icvFinalDTO.montantRevenusConjoint === null ? '' : this.icvFinalDTO.montantRevenusConjoint,
      autresRevenus: this.icvFinalDTO.autresRevenus === null ? '' : this.icvFinalDTO.autresRevenus
    });
    if (this.revenusForm.valid) {
      this.revenusValid = true;
    }
  }

  initChargesForm() {
    this.chargesForm = this.formBuilder.group({
      montantChargeResidencePrin: ['', [Validators.required, Validators.min(0)]],
      montantChargeResidenceSecon: ['', [Validators.required, Validators.min(0)]],
      montantCreditsRenouvlable: ['', [Validators.required, Validators.min(0)]],
      montantAutresCharges: ['', [Validators.required, Validators.min(0)]],
      montantAutresCredits: ['', [Validators.required, Validators.min(0)]]
    });
    this.chargesForm.setValue({
      montantChargeResidencePrin: this.icvFinalDTO.montantChargeResidencePrin === null ? '' : this.icvFinalDTO.montantChargeResidencePrin,
      montantChargeResidenceSecon:
        this.icvFinalDTO.montantChargeResidenceSecon === null ? '' : this.icvFinalDTO.montantChargeResidenceSecon,
      montantCreditsRenouvlable: this.icvFinalDTO.montantCreditsRenouvlable === null ? '' : this.icvFinalDTO.montantCreditsRenouvlable,
      montantAutresCharges: this.icvFinalDTO.montantAutresCharges === null ? '' : this.icvFinalDTO.montantAutresCharges,
      montantAutresCredits: this.icvFinalDTO.montantAutresCredits === null ? '' : this.icvFinalDTO.montantAutresCredits
    });
    if (this.chargesForm.valid) {
      this.chargesValid = true;
    }
  }

  initBridgeChargeRevenuForm() {



    this.bridgeChargeRevenuForm = this.formBuilder.group({
      montantChargeResidencePrin: ['', [Validators.required, Validators.min(0)]],
      montantAutresCharges: ['', [Validators.required, Validators.min(0)]],
      montantAutresCredits: ['', [Validators.required, Validators.min(0)]],
      montantRevenus: ['', [Validators.required, Validators.min(0)]],
      autresRevenus: ['', [Validators.required, Validators.min(0)]]

    });
    this.bridgeChargeRevenuForm.setValue({
      montantChargeResidencePrin: this.icvFinalDTO.montantChargeResidencePrin === null ? '' : this.icvFinalDTO.montantChargeResidencePrin,
      montantAutresCharges: this.icvFinalDTO.montantAutresCharges === null ? '' : this.icvFinalDTO.montantAutresCharges,
      montantAutresCredits: this.icvFinalDTO.montantAutresCredits === null ? '' : this.icvFinalDTO.montantAutresCredits,
      montantRevenus: this.icvFinalDTO.montantRevenus === null ? '' : this.icvFinalDTO.montantRevenus,
      autresRevenus: this.icvFinalDTO.autresRevenus === null ? '' : this.icvFinalDTO.autresRevenus

    });

    this.bridgeChargeRevenuForm.controls['montantChargeResidencePrin'].valueChanges.subscribe(newValue => {
      this.bridgeChargeRevenuForm.controls['montantChargeResidencePrin'].setValue(this.icvFinalDTO.montantChargeResidencePrin, { emitEvent: false });
      const modalRef = this.modalService.open(BridgeFormComponent);
      modalRef.result.then(
        (data: any) => {
        },
        (reason: any) => {
          if (reason == "exitBridge") {
            this.tagNavigationDSP2("Valider - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
          if (reason == "cancelExitBridge") {
            this.tagNavigationDSP2("Annuler - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
        });
      this.tagViewModal();
    }
    );
    this.bridgeChargeRevenuForm.controls['montantRevenus'].valueChanges.subscribe(newValue => {
      const modalRef = this.modalService.open(BridgeFormComponent);
      modalRef.result.then(
        (data: any) => {
        },
        (reason: any) => {
          if (reason == "exitBridge") {
            this.tagNavigationDSP2("Valider - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
          if (reason == "cancelExitBridge") {
            this.tagNavigationDSP2("Annuler - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
        });
      this.tagViewModal()
      this.bridgeChargeRevenuForm.controls['montantRevenus'].setValue(this.icvFinalDTO.montantRevenus, { emitEvent: false });
    }
    );
    this.bridgeChargeRevenuForm.controls['montantAutresCharges'].valueChanges.subscribe(newValue => {
      const modalRef = this.modalService.open(BridgeFormComponent);
      modalRef.result.then(
        (data: any) => {
        },
        (reason: any) => {
          if (reason == "exitBridge") {
            this.tagNavigationDSP2("Valider - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
          if (reason == "cancelExitBridge") {
            this.tagNavigationDSP2("Annuler - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
        });
      this.tagViewModal()
      this.bridgeChargeRevenuForm.controls['montantAutresCharges'].setValue(this.icvFinalDTO.montantAutresCharges, { emitEvent: false });
    }
    );
    this.bridgeChargeRevenuForm.controls['montantAutresCredits'].valueChanges.subscribe(newValue => {
      const modalRef = this.modalService.open(BridgeFormComponent);
      modalRef.result.then(
        (data: any) => {
        },
        (reason: any) => {
          if (reason == "exitBridge") {
            this.tagNavigationDSP2("Valider - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
          if (reason == "cancelExitBridge") {
            this.tagNavigationDSP2("Annuler - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
        });
      this.tagViewModal()
      this.bridgeChargeRevenuForm.controls['montantAutresCredits'].setValue(this.icvFinalDTO.montantAutresCredits, { emitEvent: false });
    }
    );
    this.bridgeChargeRevenuForm.controls['autresRevenus'].valueChanges.subscribe(newValue => {
      const modalRef = this.modalService.open(BridgeFormComponent);
      modalRef.result.then(
        (data: any) => {
        },
        (reason: any) => {
          if (reason == "exitBridge") {
            this.tagNavigationDSP2("Valider - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
          if (reason == "cancelExitBridge") {
            this.tagNavigationDSP2("Annuler - popin modif donnee DSP2", 'popin modif donnee DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
          }
        });
      this.tagViewModal()
      this.bridgeChargeRevenuForm.controls['autresRevenus'].setValue(this.icvFinalDTO.autresRevenus, { emitEvent: false });
    }
    );
  }

  tagViewModal() {
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel("popin modif donnee DSP2", 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource())
      )
    );
  }
  get identiteFormControls() {
    return this.identiteForm.controls;
  }

  get emprunt1ou2FormControls() {
    return this.emprunt1ou2Form.controls;
  }

  get bridgeOrClassicFormControls() {
    return this.bridgeOrClassicForm.controls;
  }

  get habitationFormControls() {
    return this.habitationForm.controls;
  }
  get situationProFormControls() {
    return this.situationProForm.controls;
  }
  get situationProConjointFormControls() {
    return this.situationProConjointForm.controls;
  }
  get revenusFormControls() {
    return this.revenusForm.controls;
  }

  get bridgeChargeRevenuFormControls() {
    return this.bridgeChargeRevenuForm.controls;
  }

  get chargesFormControls() {
    return this.chargesForm.controls;
  }
  /**
   * methode to listen to any changes done of the form
   * Since valueChanges returns an observable
   * in our code we can listen on a specific field/controlName as we can listen on the whole form fields/controls
   */
  onIdentiteChanges(): void {
    this.identiteForm.get('situationFamiliale').valueChanges.subscribe(value => {
      if (value === 'MARIE' || value === 'PACSE' || value === 'CONCUBINAGE') {
        if (
          this.identiteForm.value.situationFamiliale === 'MARIE' ||
          this.identiteForm.value.situationFamiliale === 'PACSE' ||
          this.identiteForm.value.situationFamiliale === 'CONCUBINAGE'
        ) {
          this.situationProValid = true;
        } else {
          this.situationProValid = false;
        }
        this.enCouple = true;
        if (!this.situationProConjointForm.valid && this.situationProValid) {
          this.situationProValid = false;
          this.progressValue -= 20;
        }

        if (
          (this.revenusForm.value.montantRevenusConjoint === '' || this.revenusForm.value.montantRevenusConjoint === null) &&
          this.revenusValid
        ) {
          this.revenusValid = false;
          this.progressValue -= 20;
        }
      } else {
        if (
          this.identiteForm.value.situationFamiliale === 'MARIE' ||
          this.identiteForm.value.situationFamiliale === 'PACSE' ||
          this.identiteForm.value.situationFamiliale === 'CONCUBINAGE'
        ) {
          this.coupleToSeul = true;
          this.clearConjointInfos();
        }
        this.enCouple = false;
        this.situationProValid = this.situationProForm.valid;
        this.revenusValid = this.revenusForm.valid;
      }
    });

    this.identiteForm.get('dateNaissance').valueChanges.subscribe(value => {
      this.checkAge(value);
    });
  }

  /**
   * methode to listen to any changes done of the form
   * Since valueChanges returns an observable
   * in our code we can listen on a specific field/controlName as we can listen on the whole form fields/controls
   */
  onHabitationChanges(): void {
    this.habitationForm.get('codePostal').valueChanges.subscribe(value => {
      this.validerCP();
    });
    this.habitationForm.get('anneeEntreeHabitation').valueChanges.subscribe(value => {
      const currentYear = new Date();
      if (value.length === 4 && (value > currentYear.getFullYear() || value < currentYear.getFullYear() - 60)) {
        this.anneeEntreeHabitationValid = false;
      } else {
        this.anneeEntreeHabitationValid = true;
      }
    });
  }
  /**
   * methode to listen to any changes done of the form
   * Since valueChanges returns an observable
   * in our code we can listen on a specific field/controlName as we can listen on the whole form fields/controls
   */
  onSituationProChange(): void {
    this.situationProForm.get('anneeDebutPro').valueChanges.subscribe(value => {
      const currentYear = new Date();
      if (value.length === 4 && (value > currentYear.getFullYear() || value < currentYear.getFullYear() - 60)) {
        this.anneeDebutProValid = false;
      } else if (value.length === 4 && value < this.yearBirth) {
        this.anneeDebutProValid = false;
      } else {
        this.anneeDebutProValid = true;
      }
    });
  }

  /**
   * methode to listen to any changes done of the form
   * Since valueChanges returns an observable
   * in our code we can listen on a specific field/controlName as we can listen on the whole form fields/controls
   */
  onSituationProConjointChange(): void {
    this.situationProConjointForm.get('anneeDebutProConjoint').valueChanges.subscribe(value => {
      const currentYear = new Date();
      if (value.length === 4 && (value > currentYear.getFullYear() || value < currentYear.getFullYear() - 60)) {
        this.anneeDebutProConjointValid = false;
      } else {
        this.anneeDebutProConjointValid = true;
      }
    });
  }

  openModaleCapaciteEmprunt() {
    this.modalService.open(CapaciteEmpruntModaleComponent, { backdrop: 'static' });
  }

  openModaleResultatIcvComplet() {
    this.modalService.open(ResultatIcvCompletComponent, { backdrop: 'static' });
    if (this.isModifiedIcv && this.isDashboard && this.isAllFormValid() && this.showList)
      this.tagNavigation('Mettre à jour ma capacité d emprunt  (rouge)', 'Mes informations');
    else
      this.tagNavigation('Mettre à jour ma capacité d emprunt ', 'Mes informations');
    this.tagView('Votre_resultat');

  }

  /**
   * methode to validate the identite form through all the validations required
   */
  validerIdentite() {
    this.userDTO.sexe = this.identiteForm.value.sexe;
    this.userDTO.firstName = this.identiteForm.value.firstName.trim();
    this.userDTO.lastName = this.identiteForm.value.lastName.trim();
    this.icvFinalDTO.situationFamiliale = this.identiteForm.value.situationFamiliale;
    this.icvFinalDTO.nombreEnfants = this.identiteForm.value.nombreEnfants;
    this.icvFinalDTO.dateNaissance = this.identiteForm.value.dateNaissance;
    this.userDTO.dateNaissance = this.identiteForm.value.dateNaissance;
    this.icvFinalDTO.ageConjoint = this.identiteForm.value.ageConjoint === '' ? null : this.identiteForm.value.ageConjoint;
    this.identiteValid = true;
    if (!this.enCouple && this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
      this.emprunt1ou2Form.controls['emprunt1ou2Val'].setValue('');
      this.emprunt1ou2Valid = false;
    }

    this.showListTabs('identite');
    this.yearBirth = this.identiteForm.value.dateNaissance.split('/')[2];

    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    this.setfocusOnContainer('messageErrorContainer');
    // Taggage Telium clic
    this.tagNavigation('Valider', 'Identite');
  }

  /**
   * methode to validate the habitation form through all the validations required
   */
  validerHabitation() {
    this.habitationValid = true;
    this.showListTabs('habitation');
    this.icvFinalDTO.typeHabitation = this.habitationForm.value.typeHabitation;
    this.icvFinalDTO.codePostal = this.habitationForm.value.codePostal;
    this.icvFinalDTO.anneeEntreeHabitation = this.habitationForm.value.anneeEntreeHabitation;
    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    this.setfocusOnContainer('messageErrorContainer');
    // Taggage Telium clic
    this.tagNavigation('Valider', 'Lieu de vie');
  }

  /**
   * methode to validate the situation professionnelle forms through all the validations required
   */
  validerSituationPro() {
    this.situationProValid = true;
    this.showListTabs('situationProfessionnelle');
    this.icvFinalDTO.csp = this.listeCspDetail.find(csp => csp.code === this.situationProForm.value.csp);
    this.icvFinalDTO.nafAgrege = this.situationProForm.value.secteurActivite;
    this.icvFinalDTO.anneeDebutPro = this.situationProForm.value.anneeDebutPro;
    this.icvFinalDTO.typeContrat = this.situationProForm.value.typeContrat;
    if (this.enCouple) {
      this.icvFinalDTO.cspConjoint = this.listeCspDetail.find(csp => csp.code === this.situationProConjointForm.value.cspConjoint);
      this.icvFinalDTO.nafAgregeConjoint = this.situationProConjointForm.value.secteurActiviteConjoint;
      this.icvFinalDTO.anneeDebutProConjoint = this.situationProConjointForm.value.anneeDebutProConjoint;
      this.icvFinalDTO.typeContratConjoint = this.situationProConjointForm.value.typeContratConjoint;
    }
    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    this.setfocusOnContainer('messageErrorContainer');
    this.tagNavigation('Valider', 'Situation professionnelle');
  }

  conjointForm() {
    this.situationPro.select('conjoint');
  }

  /**
   * methode to validate the situation professionnelle forms through all the validations required
   */

  validerBridgeChargeRevenuForm() {

    let tmpIcvFinal = JSON.parse(localStorage.getItem('BridgeIcvFinal'));

    tmpIcvFinal.moyenneSoldesfindesmois = this.icvFinalDTO.moyenneSoldesfindesmois;
    tmpIcvFinal.apportfluxsortantetfluxentrantM1 = this.icvFinalDTO.apportfluxsortantetfluxentrantM1;
    tmpIcvFinal.rapportfluxsortantetfluxentrantM2 = this.icvFinalDTO.rapportfluxsortantetfluxentrantM2;
    tmpIcvFinal.nombredejourscrediteurs30J = this.icvFinalDTO.nombredejourscrediteurs30J;
    tmpIcvFinal.nombreoperationsnegatives90J = this.icvFinalDTO.nombreoperationsnegatives90J;
    tmpIcvFinal.premierecategoriedebitrice90J = this.icvFinalDTO.premierecategoriedebitrice90J;
    tmpIcvFinal.deuxiemecategoriedebitrice90J = this.icvFinalDTO.deuxiemecategoriedebitrice90J;

    this.icvFinalDTO = tmpIcvFinal;
    this.icvFinalDTO.montantRevenusConjoint = 0;
    this.icvFinalDTO.montantRevenus = this.bridgeChargeRevenuForm.value.montantRevenus;
    this.icvFinalDTO.autresRevenus = this.bridgeChargeRevenuForm.value.autresRevenus;
    this.icvFinalDTO.montantChargeResidencePrin = this.bridgeChargeRevenuForm.value.montantChargeResidencePrin;
    this.icvFinalDTO.montantAutresCharges = this.bridgeChargeRevenuForm.value.montantAutresCharges;
    this.icvFinalDTO.montantAutresCredits = this.bridgeChargeRevenuForm.value.montantAutresCredits;
    this.icvFinalDTO.topImpotRas = this.prelevementSourceSelected;
    this.icvFinalDTO.montantChargeResidenceSecon = 0;
    this.icvFinalDTO.montantCreditsRenouvlable = 0;
    this.icvFinalDTO.topProcess = 1;
    this.tagNavigationDSP2('Valider - charges et revenus DSP2', 'charges et revenus DSP2', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
    this.tagViewDSP2('charges et revenus DSP2 - validee', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
  }

  /**
   * methode to validate the situation professionnelle forms through all the validations required
   */
  validerRevenus() {
    this.revenusValid = true;
    this.showListTabs('revenus');
    this.icvFinalDTO.montantRevenus = this.revenusForm.value.montantRevenus;
    if (this.enCouple) {
      this.icvFinalDTO.montantRevenusConjoint = this.revenusForm.value.montantRevenusConjoint;
    }
    this.icvFinalDTO.autresRevenus = this.revenusForm.value.autresRevenus;
    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    this.setfocusOnContainer('messageErrorContainer');
    this.tagNavigation('Valider', 'Revenus');
  }

  /**
   * methode to validate the Emprunt 1 ou 2 forms through all the validations required
   */
  validerEmprunt1ou2() {
    // this.revenusValid = true;
    this.showListTabs('emprunt1ou2');


    if (this.emprunt1ou2Form.value.emprunt1ou2Val === '1') {
      this.emprunt1ou2Libelle = 'J’emprunte seul(e)';
    } else if (this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
      this.emprunt1ou2Libelle = 'J’emprunte à deux';
    }

    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    // this.setfocusOnErrormessageContainer();
    this.tagNavigationDSP2('Valider - ' + this.getCoEmprunteur(true), 'emprunter seul ou a deux', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
  }

  getImpotSource(): string {
    let res = "";
    if (this.prelevementSourceOui && !this.prelevementSourceNon) {
      res = 'oui';
    }
    else if (!this.prelevementSourceOui && this.prelevementSourceNon) {
      res = 'non';
    } else {
      res = '';
    }
    return res;
  }

  getParcoursDsp2(): string {
    let res = '';
    if (this.bridgeOrClassicForm && this.bridgeOrClassicForm.value) {
      if (this.bridgeOrClassicForm.value.bridgeOrClassicVal === 'bridge') {
        res = 'DSP2';
      } else if (this.bridgeOrClassicForm.value.bridgeOrClassicVal === 'classic') {
        res = 'manuelle'
      }
    }
    return res;
  }

  getCoEmprunteur(str?): string {
    let co_emprunteur = "";
    let co_emprunteur_str = "";
    if (this.emprunt1ou2Form && this.emprunt1ou2Form.value) {
      if (this.emprunt1ou2Form.value.emprunt1ou2Val === '1') {
        co_emprunteur = "seul";
        co_emprunteur_str = "seul";
      } else if (this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
        co_emprunteur = "a deux";
        co_emprunteur_str = 'avec un co-emprunteur';
      } else {
        co_emprunteur = "";
        co_emprunteur_str = ""
      }
    }
    return str ? co_emprunteur_str : co_emprunteur;
  }


  changeEmprunt1ou2() {

    if (this.emprunt1ou2Form.value.emprunt1ou2Val === '1') {
      this.emprunt1ou2Libelle = 'J’emprunte seul(e)';
      this.emprunt1ou2Valid = true;
    } else if (this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
      this.emprunt1ou2Libelle = 'J’emprunte à deux';
      this.emprunt1ou2Valid = true;
    }

    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }

  }

  onPrelevementSourceChange(ob: MatCheckboxChange, nbr) {
    if (ob.checked && nbr === 1) {
      this.prelevementSourceNon = false;
      this.prelevementSourceOui = true;
      this.prelevementSourceSelected = true;
    } else if (ob.checked && nbr === 2) {
      this.prelevementSourceOui = false;
      this.prelevementSourceNon = true;
      this.prelevementSourceSelected = false;
    } else {
      this.prelevementSourceOui = false;
      this.prelevementSourceNon = false;
      this.prelevementSourceSelected = null;
    }
    localStorage.setItem('prelevementSource', this.getImpotSource());
  }

  displayMenuForBridge() {

    if (this.accesCollaborateur) return true;

    if (this.emprunt1ou2Valid) {
      if (this.emprunt1ou2FormControls.emprunt1ou2Val.value === '2') {
        return true;
      }

      if (this.emprunt1ou2FormControls.emprunt1ou2Val.value === '1') {
        if (this.bridgeOrClassicFormControls.bridgeOrClassicVal.value === 'bridge') {
          return false;
        }
        if (this.bridgeOrClassicFormControls.bridgeOrClassicVal.value === 'classic') {
          return true;
        }

      }
    }


    return false;
  }

  displayBridgeOrClassicButton() {

    if (this.emprunt1ou2Form === undefined || this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
      return false;
    }

    if (this.bridgeOrClassicFormControls.bridgeOrClassicVal.value === 'classic') {
      return false;
    }

    if (!this.emprunt1ou2Form.valid || !this.identiteForm.valid ||
      !this.situationProForm.valid || !this.habitationForm.valid) {
      return false;
    }
    // tslint:disable-next-line:max-line-length
    if (this.showIdentite || this.showEmprunt1ou2 || this.showSituationProfessionnelle || this.showHabitation || this.showBridgeOrClassic) {
      return false;
    }

    return true;

  }

  goToQuiSommesNous() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('A vous de choisir !', 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Espace Authentifié', 'Mon espace client', ''),
        new ClicModel('En savoir + sur Bridge', 'navigation', 'Espace Authentifié', 'Mon espace client', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.modalService.open(BridgeInfoComponent);
  }

  /**
   * methode to validate bridge or classic forms through all the validations required
   */
  validerBridgeOrClassic() {
    this.showListTabs('bridgeOrClassic');

    this.bridgeOrClassicVal = this.bridgeOrClassicForm.value.bridgeOrClassicVal;
    this.tagNavigationDSP2('Valider - methode ' + this.getParcoursDsp2(), 'choix methode de calcul capacite emprunt', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource(), true);
    if (this.bridgeOrClassicForm.value.bridgeOrClassicVal === 'bridge') {
      localStorage.setItem('BridgeIcvFinal', JSON.stringify(this.managedIcvFinal.icvFinalDTO));
      this.getBridgeConnectUrl();
      return false;
    } else if (this.bridgeOrClassicForm.value.bridgeOrClassicVal === 'classic') {
      // this.emprunt1ou2Libelle = 'J’emprunte à deux';
    }

    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
  }

  /**
   * methode to validate the situation professionnelle forms through all the validations required
   */
  validerCharges() {
    this.chargesValid = true;
    this.showListTabs('charges');
    this.icvFinalDTO.montantChargeResidencePrin = this.chargesForm.value.montantChargeResidencePrin;
    this.icvFinalDTO.montantChargeResidenceSecon = this.chargesForm.value.montantChargeResidenceSecon;
    this.icvFinalDTO.montantCreditsRenouvlable = this.chargesForm.value.montantCreditsRenouvlable;
    this.icvFinalDTO.montantAutresCharges = this.chargesForm.value.montantAutresCharges;
    this.icvFinalDTO.montantAutresCredits = this.chargesForm.value.montantAutresCredits;
    if (this.progressValue < 100) {
      this.incrementerProgressBar();
    }
    if (this.progressValue === 100) {
      // Pour signaler en rouge le bouton de mise a jour capacité
      this.isModifiedIcv = true;
    }
    this.setfocusOnContainer('messageErrorContainer');
    this.tagNavigation('Valider', 'Charges');
  }

  displayclassicbutton() {


    // tslint:disable-next-line:max-line-length
    if (this.emprunt1ou2Form.value.emprunt1ou2Val === '2') {
      return true;
    } else if (this.emprunt1ou2Form.value.emprunt1ou2Val === '1' && this.bridgeOrClassicForm.value.bridgeOrClassicVal === 'classic') {
      return true;
    }

    return false;

  }


  isAllFormValid() {
    if (this.chargesForm === undefined) {
      return false;
    } else {
      if (
        // tslint:disable-next-line:triple-equals
        !this.chargesForm.valid || !this.displayclassicbutton() ||
        (!this.identiteForm.valid || this.dateNaissanceInvalide || (this.enCouple && this.identiteForm.value.ageConjoint === '')) ||
        (!this.habitationForm.valid ||
          !this.anneeEntreeHabitationValid ||
          this.cpInvalid ||
          this.cpDomTom ||
          this.habitationForm.value.anneeEntreeHabitation < this.yearBirth) ||
        ((!this.enCouple && (!this.situationProForm.valid || !this.anneeDebutProValid)) ||
          (this.enCouple &&
            (!this.situationProConjointForm.valid ||
              !this.anneeDebutProConjointValid ||
              !this.situationProForm.valid ||
              !this.anneeDebutProValid))) ||
        (!this.revenusForm.valid || (this.revenusForm.value.montantRevenusConjoint === '' && this.enCouple))
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  calculerIcvFinalBridge() {
    this.validerBridgeChargeRevenuForm();
    // User
    if (!this.accesCollaborateur) {
      this.updateIcvFinal();
    } else {
      // collaborateur
      this.verifierIcvFinal();
    }
  }
  calculerIcvFinal() {
    this.icvFinalDTO.topProcess = 0;
    // User
    if (!this.accesCollaborateur) {
      this.updateIcvFinal();
    } else {
      // collaborateur
      this.verifierIcvFinal();
    }
    localStorage.setItem('source', '');
    localStorage.setItem('goToMesInfos', '');
    localStorage.setItem('iban', '');
    localStorage.setItem('libelleCompteBridge', '');
  }

  /***********************************************
   * Update, calcul ET sauvegarde icv complet
   ***********************************************/
  updateIcvFinal() {
    this.icvFinalDTO.dateNaissance = this.identiteForm.value.dateNaissance;
    this.icvFinalDTO.media = localStorage.getItem('media');
    this.userDTO.dateNaissance = this.identiteForm.value.dateNaissance;
    this.managedIcvFinal.icvFinalDTO = this.icvFinalDTO;
    this.userDTO['lastName'] = JSON.parse(localStorage.getItem('AccountInfos'))['lastName'];
    this.userDTO['firstName'] = JSON.parse(localStorage.getItem('AccountInfos'))['firstName'];
    this.userDTO['sexe'] = JSON.parse(localStorage.getItem('AccountInfos'))['sexe'];
    this.managedIcvFinal.userDTO = this.userDTO;
    this.managedIcvFinal.icvSimpleDTO = this.icvSimpleDTO;
    this.scoringService.updateIcvComplete(this.managedIcvFinal).subscribe(
      // Appel update icv Complet
      responseUpdate => {
        this.managedIcvFinal = responseUpdate as IcvFinalModel;
        this.scoringService.calculerScoringFinal(this.managedIcvFinal.icvFinalDTO).subscribe(
          // Appel au calcul icv Complet
          responseCalcul => {
            this.scoringService.saveCalculScoringFinal().subscribe(
              // Appel Save icv complet
              responseSaveIcvFinal => {
                this.managedIcvFinal = responseSaveIcvFinal as IcvFinalModel;
                this.openModaleResultatIcvComplet();
                const sentToModal = {
                  icvSimpleDTO: this.icvSimpleDTO === null ? null : this.icvSimpleDTO,
                  icvFinalDTO: this.managedIcvFinal.icvFinalDTO === null ? null : this.managedIcvFinal.icvFinalDTO,
                  userDTO: this.managedIcvFinal.userDTO === null ? null : this.managedIcvFinal.userDTO
                };
                this.scoringService.sendMessage(sentToModal);
                if (this.isDashboard && this.isModifiedIcv) {
                  this.isModifiedIcv = false;
                }
              },
              error => {
                console.log('Erreur save icv complet' + error);
              }
            );
          },
          error => {
            console.log('Erreur calcul icv complet' + error);
          }
        );
      },
      error => {
        console.log('Erreur update icv complet' + error);
      }
    );
  }

  /***********************************************
   * Verification icv complet
   ***********************************************/
  verifierIcvFinal() {
    var Naissance = this.identiteForm.value.dateNaissance.split('/')[2] + '/' + this.identiteForm.value.dateNaissance.split('/')[1] + '/' + this.identiteForm.value.dateNaissance.split('/')[0]
    this.icvFinalDTO.dateNaissance = new Date(Naissance);
    this.icvFinalDTO.dateDisponibiliteEpargneSalarial = new Date(this.icvFinalDTO.dateDisponibiliteEpargneSalarial);
    this.scoringService.verifierIcvFinal(this.icvFinalDTO).subscribe(
      reponseOk => {
        this.managedIcvFinal = reponseOk as IcvFinalModel;
        this.openModaleResultatIcvComplet();
        const sentToModal = {
          icvSimpleDTO: this.icvSimpleDTO === null ? null : this.icvSimpleDTO,
          icvFinalDTO: this.managedIcvFinal.icvFinalDTO === null ? null : this.managedIcvFinal.icvFinalDTO,
          userDTO: this.managedIcvFinal.userDTO === null ? null : this.managedIcvFinal.userDTO
        };
        this.scoringService.sendMessage(sentToModal);
      },
      error => {
        console.log(error);
      }
    );
  }

  clearConjointInfos() {
    // clear data from the object sent to the server
    this.icvFinalDTO.cspConjoint = null;
    this.icvFinalDTO.nafAgregeConjoint = null;
    this.icvFinalDTO.anneeDebutProConjoint = null;
    this.icvFinalDTO.typeContratConjoint = null;
    this.icvFinalDTO.ageConjoint = null;
    this.icvFinalDTO.montantRevenusConjoint = null;
    this.situationProConjointForm.setValue({
      //  clear conjoint inputs from the dom
      cspConjoint: '',
      secteurActiviteConjoint: '',
      anneeDebutProConjoint: '',
      typeContratConjoint: ''
    });
    this.identiteForm.get('ageConjoint').setValue('');

    this.revenusForm.get('montantRevenusConjoint').setValue('');
  }

  showForm(section) {
    this.showList = false;
    setTimeout(() => {
      document.getElementById("mesInfosContent")?.focus();
    });
    switch (section) {
      case 'identite': {
        this.showIdentite = true;
        break;
      }
      case 'habitation': {
        this.showHabitation = true;
        break;
      }
      case 'emprunt1ou2': {
        this.showEmprunt1ou2 = true;
        this.tagNavigationDSP2('emprunter seul ou a deux', 'Mes informations', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        this.tagViewDSP2('emprunter seul ou a deux', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        break;
      }
      case 'bridgeOrClassic': {
        this.showBridgeOrClassic = true;
        let libelle = "";
        if (this.getParcoursDsp2() && this.getParcoursDsp2().length) {
          libelle = "choix methode de calcul capacite emprunt - methode " + this.getParcoursDsp2();
        } else {
          libelle = "choix methode de calcul capacite emprunt";
        }
        this.tagNavigationDSP2('choix methode de calcul capacite emprunt', 'Mes informations', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        this.tagViewDSP2(libelle, this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        break;
      }
      case 'situationProfessionnelle': {
        this.showSituationProfessionnelle = true;
        if (!this.enCouple) {
          setTimeout(() => {
            this._elementRef.nativeElement.querySelector('ngb-tabset').getElementsByClassName('nav-pills')[0].style.display = 'none';
          }, 0);
        } else {
          this.ariaAdjustments();
        }
        break;
      }
      case 'revenus': {
        this.showRevenus = true;
        break;
      }
      case 'charges': {
        this.showCharges = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  showListTabs(section) {
    this.showList = true;
    switch (section) {
      case 'identite': {
        this.showIdentite = false;
        break;
      }
      case 'habitation': {
        this.showHabitation = false;
        break;
      }
      case 'situationProfessionnelle': {
        this.showSituationProfessionnelle = false;
        break;
      }
      case 'emprunt1ou2': {
        this.showEmprunt1ou2 = false;
        this.tagViewDSP2('emprunter seul ou a deux validee', this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        break;
      }
      case 'bridgeOrClassic': {
        this.showBridgeOrClassic = false;
        let libelle = "";
        if (this.getParcoursDsp2() && this.getParcoursDsp2().length) {
          libelle = "choix methode de calcul capacite emprunt - methode " + this.getParcoursDsp2();
        } else {
          libelle = "choix methode de calcul capacite emprunt";
        }
        this.tagViewDSP2(libelle, this.getCoEmprunteur(), this.getParcoursDsp2(), this.getImpotSource());
        break;
      }
      case 'revenus': {
        this.showRevenus = false;
        break;
      }
      case 'charges': {
        this.showCharges = false;
        break;
      }
      default: {
        break;
      }
    }
    this.setfocusOnContainer('mesInfosContent');
  }
  /************************************************************************
   * Valider code postal
   ************************************************************************/
  strStartsWith(str, prefix) {
    return str.indexOf(prefix) === 0;
  }
  validerCP() {
    let codesPostaux = null;
    if (this.habitationForm.value.codePostal !== '') {
      if (
        this.strStartsWith(this.habitationForm.value.codePostal, '97') ||
        this.strStartsWith(this.habitationForm.value.codePostal, '98')
      ) {
        // Verifier si domtom
        this.cpDomTom = true;
        this.cpInvalid = false;
      } else {
        this.cpDomTom = false;
        // Verifier si code postal est dans la liste du fichier json
        this.scoringService.getListValidCPLight().subscribe(
          success => {
            codesPostaux = success;
            for (let i = 0; i < codesPostaux.length; i++) {
              if (this.habitationForm.value.codePostal === codesPostaux[i].cp) {
                this.cpInvalid = false;
                break;
              } else {
                this.cpInvalid = true;
              }
            }
          },
          error => {
            console.log(error);
          }
        );
        this.cpInvalid = false;
      }
    }
  }

  initMessageErreur() {
    console.log('focus called');
  }

  /*****************************************************
 *  valider Age Conjoint
 ****************************************************/
  validerAgeConjoint() {
    const ageConjoint = this.identiteForm.value.ageConjoint;
    var isValid = true;
    // Age doit contenir 2 chiffres
    if (this.enCouple && ageConjoint !== '' && ageConjoint !== null) {
      const reg = new RegExp('^[0-9]*$');
      /*regexp qui contrôle s'il y a des chiffes*/
      isValid = reg.test(ageConjoint);
      if (isValid && parseInt(ageConjoint) < 16) {
        isValid = false;
      }
    }
    return isValid;
  }

  /*******************************************************************
   * Validation âge >=18
   *
   *******************************************************************/
  checkAge(value) {
    this.mineur = false;
    this.dateNaissanceInvalide = false;
    if (value !== undefined) {
      if (value.length === 10) {
        // Calcul age
        const ageDifMs = Date.now() - new Date(value.split('/')[2], value.split('/')[1], value.split('/')[0]).getTime();
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);
        if (age < 18) {
          // Si mineur
          this.mineur = true;
        } else if (
          // Si dateNaissance < "01/01/1900"
          new Date(value.split('/')[2], value.split('/')[1], value.split('/')[0]).getTime() <
          new Date(formatDate('01/01/1900', 'mediumDate', 'fr')).getTime()
        ) {
          this.dateNaissanceInvalide = true;
          this.mineur = false;
        }
      }
    }
  }

  /*************************************************
   * incrementerProgressBar
   ************************************************/
  incrementerProgressBar() {
    let progressValue = 0;
    // Controle saisie Identité
    if (this.enCouple) {
      if (
        this.userDTO.sexe !== null &&
        this.userDTO.lastName !== null &&
        this.userDTO.firstName !== null &&
        this.icvFinalDTO.situationFamiliale !== null &&
        this.icvFinalDTO.nombreEnfants !== null &&
        this.icvFinalDTO.dateNaissance !== null &&
        this.icvFinalDTO.ageConjoint !== null &&
        this.icvFinalDTO.ageConjoint !== '' &&
        this.userDTO.sexe !== undefined &&
        this.userDTO.lastName !== undefined &&
        this.userDTO.firstName !== undefined &&
        this.icvFinalDTO.situationFamiliale !== undefined &&
        this.icvFinalDTO.nombreEnfants !== undefined &&
        this.icvFinalDTO.dateNaissance !== undefined &&
        this.icvFinalDTO.ageConjoint !== undefined
      ) {
        progressValue = progressValue + 20;
      }
    } else if (
      this.userDTO.sexe !== null &&
      this.userDTO.lastName !== null &&
      this.userDTO.firstName !== null &&
      this.icvFinalDTO.situationFamiliale !== null &&
      this.icvFinalDTO.nombreEnfants !== null &&
      this.icvFinalDTO.dateNaissance !== null &&
      this.userDTO.sexe !== undefined &&
      this.userDTO.lastName !== undefined &&
      this.userDTO.firstName !== undefined &&
      this.icvFinalDTO.situationFamiliale !== undefined &&
      this.icvFinalDTO.nombreEnfants !== undefined &&
      this.icvFinalDTO.dateNaissance !== undefined
    ) {
      progressValue = progressValue + 20;
    }
    // Controle saisie profession
    if (this.enCouple) {
      if (
        this.icvFinalDTO.csp !== null &&
        this.icvFinalDTO.nafAgrege !== null &&
        this.icvFinalDTO.anneeDebutPro !== null &&
        this.icvFinalDTO.typeContrat !== null &&
        this.icvFinalDTO.cspConjoint !== null &&
        this.icvFinalDTO.nafAgregeConjoint !== null &&
        this.icvFinalDTO.anneeDebutProConjoint !== null &&
        this.icvFinalDTO.typeContratConjoint !== null &&
        this.icvFinalDTO.csp !== undefined &&
        this.icvFinalDTO.nafAgrege !== undefined &&
        this.icvFinalDTO.anneeDebutPro !== undefined &&
        this.icvFinalDTO.typeContrat !== undefined &&
        this.icvFinalDTO.cspConjoint !== undefined &&
        this.icvFinalDTO.nafAgregeConjoint !== undefined &&
        this.icvFinalDTO.anneeDebutProConjoint !== undefined &&
        this.icvFinalDTO.typeContratConjoint !== undefined
      ) {
        progressValue = progressValue + 20;
      }
    } else if (
      this.icvFinalDTO.csp !== null &&
      this.icvFinalDTO.nafAgrege !== null &&
      this.icvFinalDTO.anneeDebutPro !== null &&
      this.icvFinalDTO.typeContrat !== null &&
      this.icvFinalDTO.csp !== undefined &&
      this.icvFinalDTO.nafAgrege !== undefined &&
      this.icvFinalDTO.anneeDebutPro !== undefined &&
      this.icvFinalDTO.typeContrat !== undefined
    ) {
      progressValue = progressValue + 20;
    }
    // Controle saisie lieu de vie
    if (
      this.icvFinalDTO.typeHabitation !== null &&
      this.icvFinalDTO.codePostal !== null &&
      this.icvFinalDTO.anneeEntreeHabitation !== null &&
      this.icvFinalDTO.typeHabitation !== undefined &&
      this.icvFinalDTO.codePostal !== undefined &&
      this.icvFinalDTO.anneeEntreeHabitation !== undefined
    ) {
      progressValue = progressValue + 20;
    }
    // Controle saisie revenus
    if (this.enCouple) {
      if (
        this.icvFinalDTO.montantRevenus !== null &&
        this.icvFinalDTO.montantRevenusConjoint !== null &&
        this.icvFinalDTO.autresRevenus !== null &&
        this.icvFinalDTO.montantRevenus !== undefined &&
        this.icvFinalDTO.montantRevenusConjoint !== undefined &&
        this.icvFinalDTO.autresRevenus !== undefined
      ) {
        progressValue = progressValue + 20;
      }
    } else if (
      this.icvFinalDTO.montantRevenus !== null &&
      this.icvFinalDTO.autresRevenus !== null &&
      this.icvFinalDTO.montantRevenus !== undefined &&
      this.icvFinalDTO.autresRevenus !== undefined
    ) {
      progressValue = progressValue + 20;
    }
    // Controle saisie charges
    if (
      this.icvFinalDTO.montantChargeResidencePrin !== null &&
      this.icvFinalDTO.montantChargeResidenceSecon !== null &&
      this.icvFinalDTO.montantCreditsRenouvlable !== null &&
      this.icvFinalDTO.montantAutresCharges !== null &&
      this.icvFinalDTO.montantAutresCredits !== null &&
      this.icvFinalDTO.montantChargeResidencePrin !== undefined &&
      this.icvFinalDTO.montantChargeResidenceSecon !== undefined &&
      this.icvFinalDTO.montantCreditsRenouvlable !== undefined &&
      this.icvFinalDTO.montantAutresCharges !== undefined &&
      this.icvFinalDTO.montantAutresCredits !== undefined
    ) {
      progressValue = progressValue + 20;
    }
    this.progressValue = progressValue;
  }

  //section_dsp2_start
  tagNavigationDSP2(clickLibelle: string, pageName: string, co_emprunteur, parcours_dsp2, impot_source, action?) {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel(pageName, 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Espace Authentifié', 'Mon espace client', ''),
        new ClicModel(clickLibelle, action ? 'action' : 'navigation', 'Espace Authentifié', 'Mon espace client', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', co_emprunteur, parcours_dsp2, impot_source)
      ));
  }
  tagViewDSP2(pageName: string, co_emprunteur, parcours_dsp2, impot_source) {
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel(pageName, 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', co_emprunteur, parcours_dsp2, impot_source)
      )
    );
  }
  //section_dsp2_end

  tagNavigation(clickLibelle: string, pageName: string) {
    // Taggage Telium clic
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel(pageName, 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Espace Authentifié', 'Mon espace client', ''),
        new ClicModel(clickLibelle, 'navigation', 'Espace Authentifié', 'Mon espace client', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      ));
  }
  tagView(pageName: string,) {
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel(pageName, 'information'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Espace Authentifié', 'Mon Espace Client', 'Mes informations'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  setfocusOnContainer(containerId) {
    setTimeout(() => {
      document.getElementById(containerId)?.focus();
    }, 0);
  }

  /** ARIA SECTION START  **/
  ariaAdjustments() {
    setTimeout(() => {
      let tabSetElement = this._elementRef.nativeElement.querySelector('ngb-tabset');
      if (tabSetElement) {
        let listUl = tabSetElement.getElementsByClassName('nav-pills')[0];
        listUl.addEventListener("keydown", function (e) {
          if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
          }
        }, false);
        let list = tabSetElement.getElementsByClassName('nav-item');
        let panel = tabSetElement.getElementsByClassName('tab-content')[0];
        panel.setAttribute('tabindex', "-1");
        this.listTabsIds = [];
        this.listTabsVisibility = [];
        if (list && list.length) {
          for (let index = 0; index < list.length; index++) {
            list[index].setAttribute('role', "presentation");
            let child = list[index].children[0];
            if (child) {
              this.listTabsVisibility = [...this.listTabsVisibility, !child?.innerHTML.includes('disabled')]
              this.listTabsIds = [...this.listTabsIds, child?.id]
              if (child?.innerHTML.includes('disabled')) {
                child.setAttribute('disabled', "");
              }
              child.setAttribute('tabindex', "-1");
              if (child.getAttribute('aria-selected') == "true") {
                child.setAttribute('tabindex', "0");
              }
            }
          }
        }
      }
    }, 0);
  }

  navigationFleche(event, isForward) {
    let direction = isForward ? 'forward' : 'back';
    let currentIndex = this.listTabsIds.includes(document?.activeElement?.id) ? this.listTabsIds.indexOf(document.activeElement.id) : this.listTabsIds.indexOf(this.situationPro.activeId);
    let nextIndex = this.getNextElement(direction, currentIndex, this.listTabsIds.length);
    if (this.listTabsVisibility[nextIndex]) {
      document.getElementById(this.listTabsIds[nextIndex])?.focus();
    }
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  getNextElement(direction, currentIndex, length) {
    let nextIndex = currentIndex;
    if (direction == "back") {
      if (currentIndex - 1 < 0) {
        nextIndex = length - 1;
      } else {
        nextIndex--
      }
    }
    if (direction == "forward") {
      if (currentIndex + 1 >= length) {
        nextIndex = 0;
      } else {
        nextIndex++
      }
    }
    return nextIndex;
  }

  selectTab(e) {
    e.preventDefault();
    if (this.listTabsIds.includes(document?.activeElement?.id)) {
      this.situationPro.select(document?.activeElement?.id);
    }
  }

  tabChangeEvent() {
    this.ariaAdjustments();
  }


  /** ARIA SECTION END  **/
}
