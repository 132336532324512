import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/features/services/account.service';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';
import { ClicModel } from 'src/app/tealium/clic.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private accountService: AccountService,
    private tealium: TealiumUtagService,
    private route: Router,
    private utagDataService: UtagDataService) { }

  ngOnInit() {
  }


  logout() {
    this.accountService.sendMessage('logout');
    this.accountService.logout();
    this.activeModal.dismiss('logout click');
    this.tagDeconnexion('Oui', 'action');
  }

  tagDeconnexion(clickLibelle: string, clickType: string) {
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Deconnexion', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Espace Authentifié', 'Parametre de compte', ''),
        new ClicModel(clickLibelle, clickType, 'Espace Authentifié', 'Parametre de compte', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      ));
  }
}
