import { Injectable } from '@angular/core';
import { PageModel } from './page.model';
import { ProduitModel } from './produit.model';
import { FormModel } from './form.model';
import { NtxModel } from './ntx';
import { ClicModel } from './clic.model';
import { ClientModel } from './client.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomModel } from './custom';

@Injectable({
  providedIn: 'root'
})
export class UtagDataService {
  constructor(private deviceService: DeviceDetectorService) {}

  /**********************************************************
   * Construction utagdata for page
   **********************************************************/
  constructDataPage(page: PageModel, produit: ProduitModel, form: FormModel, ntx: NtxModel, client: ClientModel, custom: CustomModel) {
    // préparaion des données custom
    custom.device = 'desktop';
    if (this.deviceService.isMobile()) {
      custom.device = 'mobile';
    } else if (this.deviceService.isTablet()) {
      custom.device = 'tablet';
    }
    if (this.deviceService.browser !== null) {
      custom.navigateur = this.deviceService.browser;
    }
    if (this.deviceService.browser_version !== null) {
      custom.navigateur_version = this.deviceService.browser_version;
    }
    if (this.deviceService.os !== null) {
      custom.systeme_exploitation = this.deviceService.os;
    }

    // Construction du Json
    const utag_data_page = {
      'page.pageName': page.pageName,
      'page.pageType': page.pageType,
      'page.univers': page.univers,
      'page.codeEnttGrpe': page.CodeEnttGrpe,
      'page.applicationType': page.applicationType,
      'page.applicationName': page.applicationName,
      'page.marche': page.marche,
      'page.espace': page.espace,

      'produit.gamme': produit.gamme,
      'produit.code': produit.code,

      'form.nom': form.nom === null ? '' : form.nom,
      'form.idEtape': form.idEtape === null ? '' : form.idEtape,

      'ntx.env_site_id': ntx.site_id === null ? '' : ntx.site_id,
      'ntx.env_environnement': ntx.environnement === null ? '' : ntx.environnement,
      'ntx.env_metier': ntx.metier === null ? '' : ntx.metier,
      'ntx.env_langue': ntx.langue === null ? '' : ntx.langue,
      'ntx.env_canal': ntx.canal === null ? '' : ntx.canal,
      'ntx.page_site_section': ntx.site_section === null ? '' : ntx.site_section,
      'ntx.page_categorie': ntx.categorie === null ? '' : ntx.categorie,
      'ntx.page_sous_categorie': ntx.sous_categorie === null ? '' : ntx.sous_categorie,
      'ntx.produits_services': ntx.produits_services === null ? '' : ntx.produits_services,
      'ntx.env_reseau': ntx.reseau === null ? '' : ntx.reseau,
      'ntx.user_segmentation_nxs': ntx.segmentation_ntx === null ? '' : ntx.segmentation_ntx,
      'ntx.user_id_client_nxs': ntx.id_client_ntx === null ? '' : ntx.id_client_ntx,
      'ntx.user_logged_status': ntx.user_logged_status === null ? '' : ntx.user_logged_status,
      'ntx.page_url_query_string_parameter': ntx.page_url_query_string_parameter === null ? '' : ntx.page_url_query_string_parameter,
      'ntx.user_profil_visiteur': ntx.user_profil_visiteur === null ? '' : ntx.user_profil_visiteur,

      'client.idEnttGrpe': client.idEnttGrpe === null ? '' : client.idEnttGrpe,
      'client.id': client.id === null ? '' : client.id,
      'client.statut': client.statut === null ? '' : client.statut,
      'client.marche': client.marche === null ? '' : client.marche,

      'custom.situation_familiale': custom.situation_familiale === null ? '' : custom.situation_familiale,
      'custom.nombre_enfants_charge': custom.nombre_enfants_charge === null ? '' : custom.nombre_enfants_charge,
      'custom.code_postal_habitation': custom.code_postal_habitation === null ? '' : custom.code_postal_habitation,
      'custom.revenus_demandeur': custom.revenus_demandeur === null ? '' : custom.revenus_demandeur,
      'custom.revenu_conjoint': custom.revenu_conjoint === null ? '' : custom.revenu_conjoint,
      'custom.charges_mensuelles': custom.charges_mensuelles === null ? '' : custom.charges_mensuelles,
      'custom.choix_pret': custom.choix_pret === null ? '' : custom.choix_pret,
      'custom.emprunter': custom.emprunter === null ? '' : custom.emprunter,
      'custom.pourcentage_completude': custom.pourcentage_completude === null ? '' : custom.pourcentage_completude,
      'custom.site_affluent': custom.site_affluent === null ? '' : custom.site_affluent,
      'custom.device': custom.device === null ? '' : custom.device,
      'custom.navigateur': custom.navigateur === null ? '' : custom.navigateur,
      'custom.navigateur_version': custom.navigateur_version === null ? '' : custom.navigateur_version,
      'custom.source_trafic': custom.source_trafic === null ? '' : custom.source_trafic,
      'custom.systeme_exploitation': custom.systeme_exploitation === null ? '' : custom.systeme_exploitation,
      'custom.co_emprunteur': custom.co_emprunteur ? custom.co_emprunteur : '',
      'custom.dsp2': custom.dsp2 ? custom.dsp2 : '',
      'custom.impot_source': custom.impot_source ? custom.impot_source : ''
    };

    return utag_data_page;
  }

  /**************************************************************
   * Construction utagdata for link
   **************************************************************/
  constructDataLink(
    page: PageModel,
    produit: ProduitModel,
    form: FormModel,
    ntx: NtxModel,
    clic: ClicModel,
    client: ClientModel,
    custom: CustomModel
  ) {
    // préparaion des données custom
    custom.device = 'desktop';
    if (this.deviceService.isMobile()) {
      custom.device = 'mobile';
    } else if (this.deviceService.isTablet()) {
      custom.device = 'tablet';
    }
    if (this.deviceService.browser !== null) {
      custom.navigateur = this.deviceService.browser;
    }
    if (this.deviceService.browser_version !== null) {
      custom.navigateur_version = this.deviceService.browser_version;
    }
    if (this.deviceService.os !== null) {
      custom.systeme_exploitation = this.deviceService.os;
    }

    // Construction du Json
    const utag_data_link = {
      'page.pageName': page.pageName,
      'page.pageType': page.pageType,
      'page.univers': page.univers,
      'page.codeEnttGrpe': page.CodeEnttGrpe,
      'page.applicationType': page.applicationType,
      'page.applicationName': page.applicationName,
      'page.marche': page.marche,
      'page.espace': page.espace,
      'produit.gamme': produit.gamme,
      'produit.code': produit.code,

      'form.nom': form.nom === null ? '' : form.nom,
      'form.idEtape': form.idEtape === null ? '' : form.idEtape,

      'ntx.env_site_id': ntx.site_id === null ? '' : ntx.site_id,
      'ntx.env_environnement': ntx.environnement === null ? '' : ntx.environnement,
      'ntx.env_metier': ntx.metier === null ? '' : ntx.metier,
      'ntx.env_langue': ntx.langue === null ? '' : ntx.langue,
      'ntx.env_canal': ntx.canal === null ? '' : ntx.canal,
      'ntx.page_site_section': ntx.site_section === null ? '' : ntx.site_section,
      'ntx.page_categorie': ntx.categorie === null ? '' : ntx.categorie,
      'ntx.page_sous_categorie': ntx.sous_categorie === null ? '' : ntx.sous_categorie,
      'ntx.produits_services': ntx.produits_services === null ? '' : ntx.produits_services,
      'ntx.env_reseau': ntx.reseau === null ? '' : ntx.reseau,
      'ntx.user_segmentation_nxs': ntx.segmentation_ntx === null ? '' : ntx.segmentation_ntx,
      'ntx.user_id_client_nxs': ntx.id_client_ntx === null ? '' : ntx.id_client_ntx,
      'ntx.user_logged_status': ntx.user_logged_status === null ? '' : ntx.user_logged_status,
      'ntx.page_url_query_string_parameter': ntx.page_url_query_string_parameter === null ? '' : ntx.page_url_query_string_parameter,
      'ntx.user_profil_visiteur': ntx.user_profil_visiteur === null ? '' : ntx.user_profil_visiteur,

      'client.idEnttGrpe': client.idEnttGrpe === null ? '' : client.idEnttGrpe,
      'client.id': client.id === null ? '' : client.id,
      'client.statut': client.statut === null ? '' : client.statut,
      'client.marche': client.marche === null ? '' : client.marche,

      'clic.libelle': clic.libelle === null ? '' : clic.libelle,
      'clic.type': clic.type === null ? '' : clic.type,
      'ntx.event_site_section': clic.event_site_section === null ? '' : clic.event_site_section,
      'ntx.event_categorie': clic.event_categorie === null ? '' : clic.event_categorie,
      'ntx.event_sous_categorie': clic.event_sous_categorie === null ? '' : clic.event_sous_categorie,
      'ntx.event_name': clic.event_name === null ? '' : clic.event_name,

      'custom.situation_familiale': custom.situation_familiale === null ? '' : custom.situation_familiale,
      'custom.nombre_enfants_charge': custom.nombre_enfants_charge === null ? '' : custom.nombre_enfants_charge,
      'custom.code_postal_habitation': custom.code_postal_habitation === null ? '' : custom.code_postal_habitation,
      'custom.revenus_demandeur': custom.revenus_demandeur === null ? '' : custom.revenus_demandeur,
      'custom.revenu_conjoint': custom.revenu_conjoint === null ? '' : custom.revenu_conjoint,
      'custom.charges_mensuelles': custom.charges_mensuelles === null ? '' : custom.charges_mensuelles,
      'custom.choix_pret': custom.choix_pret === null ? '' : custom.choix_pret,
      'custom.emprunter': custom.emprunter === null ? '' : custom.emprunter,
      'custom.pourcentage_completude': custom.pourcentage_completude === null ? '' : custom.pourcentage_completude,
      'custom.site_affluent': custom.site_affluent === null ? '' : custom.site_affluent,
      'custom.device': custom.device === null ? '' : custom.device,
      'custom.navigateur': custom.navigateur === null ? '' : custom.navigateur,
      'custom.navigateur_version': custom.navigateur_version === null ? '' : custom.navigateur_version,
      'custom.source_trafic': custom.source_trafic === null ? '' : custom.source_trafic,
      'custom.systeme_exploitation': custom.systeme_exploitation === null ? '' : custom.systeme_exploitation,

      'custom.montantSouhaite': custom.montantSouhaite ? custom.montantSouhaite : '' ,
      'custom.mensualiteSouhaite': custom.mensualiteSouhaite ? custom.mensualiteSouhaite :''  ,
      'custom.dureeSouhaitee': custom.dureeSouhaitee  ? custom.dureeSouhaitee : ''  ,
      'custom.type_pret': custom.type_pret ? custom.type_pret : '',
      'custom.optionAssurance': custom.optionAssurance ? custom.optionAssurance : '',
      'custom.datePrelevement': custom.datePrelevement ? custom.datePrelevement : '',
      'custom.versement_8_jours': custom.versement_8_jours ? custom.versement_8_jours : '',
      'custom.co_emprunteur': custom.co_emprunteur ? custom.co_emprunteur : '',
      'custom.dsp2': custom.dsp2 ? custom.dsp2 : '',
      'custom.impot_source': custom.impot_source ? custom.impot_source : ''
    };

    return utag_data_link;
  }
}
