import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CapaciteEmpruntModaleComponent } from './modal/capacite.emprunt.modale/capacite.emprunt.modale.component';
import { LogoutComponent } from './modal/logout/logout.component';
import { ErreurModalComponent } from './modal/erreur.modal/erreur.modal.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { sharedRouting } from './shared.routes';
import { ResultatIcvCompletComponent } from './modal/resultat-icv-complet/resultat-icv-complet.component';
import { DateMaskDirective } from './date-mask.directive';
import { InformationsClientIcvComponent } from './informations-client-icv/informations-client-icv.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuiSommesNousComponent } from './modal/qui.sommes.nous/qui.sommes.nous.component';
import { FaqComponent } from './faq/faq.component';
import { CguModule } from './cgu/cgu.module';
import { FaqModule } from './faq/faq.module';
import { MentionsLegalesModule } from './mentions-legales/mentions-legales.module';
import { ConfirmAbandonSimulationComponent } from './modal/confirm-abandon-simulation/confirm-abandon-simulation.component';
import { MaterialModule } from 'src/app/material.module';
import {BridgeInfoComponent} from './modal/bridge-info/bridge-info.component';
import { AriaClickDirective } from './directives/aria-click.directive';
import {BridgeFormComponent} from './modal/bridge-form/bridge-form.component';
import {BridgeFormDataKoComponent} from './modal/bridge-form-data-ko/bridge-form-data-ko.component';

/* commentaires */
@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NgbModule, sharedRouting, CguModule, FaqModule, MentionsLegalesModule, MaterialModule],
  declarations: [
    CapaciteEmpruntModaleComponent,
    ErreurModalComponent,
    LogoutComponent,
    AccessDeniedComponent,
    ResultatIcvCompletComponent,
    DateMaskDirective,
    AriaClickDirective,
    InformationsClientIcvComponent,
    QuiSommesNousComponent,
    BridgeInfoComponent,
    ConfirmAbandonSimulationComponent,
    BridgeFormComponent,
    BridgeFormDataKoComponent
  ],
  exports: [
    CapaciteEmpruntModaleComponent,
    LogoutComponent,
    ResultatIcvCompletComponent,
    DateMaskDirective,
    AriaClickDirective,
    InformationsClientIcvComponent,
    QuiSommesNousComponent,
    BridgeInfoComponent,
    ConfirmAbandonSimulationComponent,
    BridgeFormComponent
  ],
  entryComponents: [
    CapaciteEmpruntModaleComponent,
    ErreurModalComponent,
    LogoutComponent,
    ResultatIcvCompletComponent,
    QuiSommesNousComponent,
    BridgeInfoComponent,
    ConfirmAbandonSimulationComponent,
    BridgeFormComponent
  ]
})
export class SharedModule {}
