<p class="purpleBand">
  Un crédit vous engage et doit être remboursé. <span>Vérifiez vos capacités de remboursement avant de vous engager.</span>
</p>
<!-- SCORING LIGHT -->
<section class="scoringLight maxWidth clearfix">
  <div class="scoringLightFirst clearfix maxWidth">
    <h1 class="title page_title_1">
      Prêt personnel<br>
      J'estime
      <button class="link" (click)="openModaleCapaciteEmprunt()" data-toggle="modal" data-target="#modal-capaciteEmprunt">
        ma capacité d'emprunt*</button>
      </h1>
    <h2 class="sousTitre">Je réponds aux 5 questions obligatoires ci-dessous</h2>
  </div>
  <!-- Alerte -->
  <div class="alert alert-danger" style="text-align: center;" *ngIf="chargeRevenuInvalid">
    <strong>Vos charges dépassent vos revenus et ne permettent pas le calcul de votre capacité d'emprunt.</strong>
  </div>
  <div class="scoringTable clearfix">
    <ngb-tabset type="pills" orientation="vertical" aria-orientation="vertical" class="container-Scoring-light"
      #icvSimpleTabs (keyup.arrowup)="navigationFleche($event, false)" (keyup.arrowdown)="navigationFleche($event, true)"
      (keyup.Space)="selectTab()" (tabChange)="tabChangeEvent()">
      <!-- Situation Familiale -->
      <ngb-tab id="family" [disabled]="onglet1Inactif">
        <ng-template ngbTabTitle>
          <div (click)="showTabContent('Situation Familiale', 'etape1')">
            <button [disabled]="onglet1Inactif" class="circle" aria-labelledby="family-title" tabindex="-1"></button>
            <p id="family-title">Situation familiale : <span>{{ outputScoringLight.situationFamiliale }}</span></p>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <!-- Situation familiale -->
          <div [hidden]="onglet1Inactif" class="text-center">
            <!-- <h4>Situation familiale</h4> -->
            <label for="selectbox1">Situation familiale</label>
            <form [formGroup]="situationFamilialeForm">
              <div class="form-group">
                <select class="select-styled" id="selectbox1" formControlName="situationFamiliale" aria-required="true">
                  <option value="" disabled selected hidden>Sélectionnez</option>
                  <option value="CELIBATAIRE">{{ situationFamilialeEnum.CELIBATAIRE }}</option>
                  <option value="CONCUBINAGE">{{ situationFamilialeEnum.CONCUBINAGE }}</option>
                  <option value="PACSE">{{ situationFamilialeEnum.PACSE }}</option>
                  <option value="MARIE">{{ situationFamilialeEnum.MARIE }}</option>
                  <option value="SEPARE">{{ situationFamilialeEnum.SEPARE }}</option>
                  <option value="DIVORCE">{{ situationFamilialeEnum.DIVORCE }}</option>
                  <option value="VEUF">{{ situationFamilialeEnum.VEUF }}</option>
                </select>
              </div>
          <!--    <button type="submit" class="fullSize" (click)="validerSituationFamiliale()">Valider</button> -->
                <button type="button" aria-label="Valider la situation familiale" class="fullSize" (click)="validerSituationFamiliale()">Valider</button>
            </form>
          </div>
          </ng-template>
          <!-- /Situation familiale -->
      </ngb-tab>
      <!-- Nombre d'enfants à charge -->
      <ngb-tab id="children" [disabled]="ongletEnfantInactif">
        <ng-template ngbTabTitle>
          <div (click)="showTabContent('Nombre d\'enfants à charge', 'etape2')">
            <button [disabled]="ongletEnfantInactif" class="circle" aria-labelledby="children-title" tabindex="-1"></button>
            <p id="children-title">Nombre d'enfants à charge : <span>{{ outputScoringLight.nombreEnfants }}</span></p>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <div>
            <!-- Nombre d'enfants -->
            <div id="children" [hidden]="ongletEnfantInactif" class="text-center">
              <label for="session" class="tooltipInfo">
                Nombre d'enfants à charge <span class="previous" (click)="showTabList()"></span>
                <button mat-icon-button class="tooltipInfoBtn"
                        aria-label="Information (i)"
                        matTooltip="Nombre d'enfants rattachés à votre foyer fiscal">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
              </label>
              <form [formGroup]="enfantAchargeForm">
                <div class="form-group">
               <!--   <button class="circle" (click)="dowSpin()">-</button> -->
                  <button class="circle" aria-label="Enlever un enfant à charge (-)" (click)="dowSpin()">-</button>
                  <input
                    id="session"
                    type="number"
                    aria-required="true"
                    formControlName="enfant"
                    name="session"
                    content="width=device-width, user-scalable=no"                  
                    [attr.aria-invalid]="enfantNull"
                    [attr.aria-describedby]="enfantNull ? 'enfants_error_1' : ''" />
                 <!--   <button class="circle" (click)="upSpin()">+</button> -->
                  <button class="circle" aria-label="Ajouter un enfant à charge (+)" (click)="upSpin()">+</button>
                </div>
                <p id="enfants_error_1" *ngIf="enfantNull" class="error cperror">Nombre d'enfants est obligatoire</p>
                <!--  <button type="submit" (click)="validerEnfants()" class="fullSize">Valider</button> -->
                  <button type="button" aria-label="Valider le nombre d'enfants" (click)="validerEnfants()" class="fullSize">Valider</button>
              </form>
            </div>
          </div>
          <!-- /Nombre d'enfants -->
        </ng-template>
      </ngb-tab>
      <!-- Code postal d'habitation -->
      <ngb-tab id="postCode" [disabled]="ongletCodePostaltInactif">
        <ng-template ngbTabTitle>
          <div (click)="showTabContent('Code Postal d\'habitation', 'etape3')">
            <button [disabled]="ongletCodePostaltInactif" class="circle" aria-labelledby="postCode-title" tabindex="-1"></button>
            <p id="postCode-title">Code postal d'habitation : <span>{{ outputScoringLight.codePostal }}</span></p>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <!-- Code postal -->
          <div id="postCodeContent" [hidden]="ongletCodePostaltInactif" class="text-center">
            <label for="postalcode">
              Code postal (format: 00000) <span class="previous" (click)="showTabList()"></span>
              <p class="texteCodePostal">
                Nos offres de crédit sont uniquement accessibles aux particuliers résidents en France métropolitaine.
              </p>
            </label>
            <form id="cpform" name="cpform" [formGroup]="codePostalForm">
              <div class="form-group">
                <input
                  class="form-control"
                  content="width=device-width, user-scalable=no"
                  type="text"
                  id="postalcode"
                  aria-required="true"
                  [attr.aria-invalid]="cpInvalid || cpDomTom || cpNull || (codePostalForm.dirty && codePostalForm.invalid)"
                  [attr.aria-describedby]="postal_code_error_list"
                  name="codePostal"
                  maxlength="5"
                  placeholder="00000"
                  formControlName="codePostal"
                />
                <p id="postal_code_error_1" *ngIf="cpInvalid" ng-message="invalid" class="error cperror">
                  Le code postal que vous avez saisi ne correspond pas à une commune française.
                </p>
                <p id="postal_code_error_2" *ngIf="cpDomTom" ng-message="invalid" class="error cperror">
                  Les offres de crédit proposées s'adressent à des résidents français domiciliés en France métropolitaine. Renseigner un code postal valide (exemple : 75001).
                </p>
                <p id="postal_code_error_3" *ngIf="cpNull" class="error cperror">Code postal obligatoire</p>
                <p id="postal_code_error_4" *ngIf="codePostalForm.dirty && codePostalForm.invalid" class="error cperror" >
                  Le code postal doit contenir 5 caractères
                </p>
              </div>
            <!--  <button type="submit" class="fullSize" (click)="validerCP()">Valider</button> -->
              <button type="button" aria-label="Valider le Code postal" class="fullSize" (click)="validerCP()">Valider</button>
            </form>
          </div>
        </ng-template>
        <!-- /Code postal -->
      </ngb-tab>
      <!-- Revenus nets/mois -->
      <ngb-tab id="salary" [disabled]="ongletRevenuInactif">
        <ng-template ngbTabTitle>
          <div (click)="showTabContent('Revenus net par mois', 'etape4')">
            <button aria-labelledby="desc_btn_revenus" [disabled]="ongletRevenuInactif" class="circle" tabindex="-1"></button>
            <p id="desc_btn_revenus">Revenus nets/mois : <span>{{ outputScoringLight.montantRevenus }} €</span></p>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <!-- Revenus par mois -->
          <div id="salaryContent" class="fade" [ngClass]="{ revenuCouple: !revenuSeul }" [hidden]="ongletRevenuInactif">
            <fieldset>
              <legend>
                Revenus nets/mois <span class="previous" (click)="showTabList()"></span>
                <button aria-label="Information (i)" mat-icon-button matTooltip="Indiquez les revenus nets mensuels avant impôt hors prime, bonus, variable, du travail ou perçus par des droits acquis
                                par le travail (préretraite et retraite). Sur une base de 12 mois.">
                  <mat-icon class="tooltipStyle">info</mat-icon>
                </button>
                <!-- <span id="tooltip2" role="tooltip" hidden>Indiquez les revenus nets mensuels avant impôt hors prime, bonus, variable, du
                  travail ou perçus par des droits acquis
                  par le travail (préretraite et retraite). Sur une base de 12 mois.</span> -->
                <p class="fade_p"> Renseignez ici vos revenus nets par mois avant impôt (revenus d'activité ou salaire)</p>
              
              </legend>
            <!-- Celibataire -->
            <form role="form" *ngIf="revenuSeul" [formGroup]="revenuSeulForm">
              <div class="form-group">
                <label for="vosrevenus">Vos revenus : </label>
                <input placeholder="0 €" id="vosrevenus" aria-required="true" class="form-control" formControlName="revenusSeul"
                  type="number"
                  [attr.aria-invalid]="revenusNull || revenusNegatives"
                  [attr.aria-describedby]="revenus_error_list" />
                  <br>
                <p id="revenus_error_1" *ngIf="revenusNull" class="error cperror">Revenus obligatoires</p>
                <p id="revenus_error_2" *ngIf="revenusNegatives" class="error cperror">Saisie incorrecte</p>
              </div>
            <!--  <button type="submit" class="fullSize" (click)="validerRevenus()">Valider</button> -->
              <button type="button" aria-label="Valider_Celibataire" class="fullSize" (click)="validerRevenus()">Valider</button>
            </form>

            <!-- En couple -->
            <form name="revenuCoupleForm" class="formRevenuCouple" *ngIf="!revenuSeul" [formGroup]="revenusCoupleForm">
              <!-- Revenus -->
              <div class="form-group">
                <label for="vosrevenus">Vos revenus : </label>
                <input placeholder="0 €" id="vosrevenus" aria-required="true" name="monRevenu" class="form-control" type="number"
                  formControlName="monRevenu"
                  [attr.aria-invalid]="revenusFoyerNull || revenusNegatives"
                  [attr.aria-describedby]="revenusCouple_error_list" />
              </div>
              <br>
              <p id="revenusCouple_error_2" *ngIf="revenusNegatives" class="error cperror">Saisie incorrecte</p>

              <!-- Revenus conjoint -->
              <div class="form-group">
                <label for="revenusconjoint">Ceux de votre conjoint : </label>
                <input placeholder="0 €" id="revenusconjoint" aria-required="true" name="revenuConjoint" class="form-control"
                  formControlName="revenuConjoint" type="number" 
                  [attr.aria-invalid]="revenusFoyerNull || revenusConjointNegatives"
                  [attr.aria-describedby]="revenusCouple_error_list" />
              </div>
              <br>
              <p id="revenusCouple_error_1" *ngIf="revenusFoyerNull" class="error cperror">Veuillez saisir au moins un des revenus</p>
              <p id="revenusCouple_error_3" *ngIf="revenusConjointNegatives" class="error cperror">Saisie incorrecte</p>

              <!--  <button type="submit" class="fullSize revenuCouple" (click)="validerRevenus()">Valider</button> -->
              <button type="button" aria-label="Valider le Revenus conjoint" class="fullSize revenuCouple" (click)="validerRevenus()">Valider</button>
            </form>
            </fieldset>
          </div>
        </ng-template>
        <!-- /Revenus par mois -->
      </ngb-tab>
      <!-- Charges/mois -->
      <ngb-tab id="wages" [disabled]="ongletChargesInactif">
        <ng-template ngbTabTitle>
          <div (click)="showTabContent('Charges par mois', 'etape5')">
            <button aria-labelledby="desc_btn_cahrges" [disabled]="ongletChargesInactif" class="circle" tabindex="-1"></button>
            <p id="desc_btn_cahrges">
              Charges/mois : <span>{{ outputScoringLight.montantCharges }} €</span>
            </p>
          </div>
        </ng-template>
        <ng-template ngbTabContent>
          <!-- Charges par mois -->
          <div id="wagesContent" [hidden]="ongletChargesInactif" class="text-center">
            <label for="charges">
              Charges/mois <span class="previous" (click)="showTabList()"></span>
              <button mat-icon-button
                      aria-label="Information (i)"
                      matTooltip="Vos charges mensuelles correspondent à vos charges d'habitation (loyer ou mensualité de crédit immo), à vos mensualités
                  d'autres crédits, aux pensions alimentaires versées et à vos impôts mensualisés.">
                <mat-icon class="tooltipStyle">info</mat-icon>
              </button>
            </label>
            <form [formGroup]="chargeForm">
              <div class="form-group">
                <input class="form-control" id="charges" aria-required="true" required formControlName="charges" type="number"
                  placeholder="0 €"
                  [attr.aria-invalid]="chargesNull || chargesNegatives"
                  [attr.aria-describedby]="charges_error_list" />
                <br>
                <p id="charges_error_1" *ngIf="chargesNull" class="error cperror">Charges obligatoires</p>
                <p id="charges_error_2" *ngIf="chargesNegatives" class="error cperror">Saisie incorrecte</p>
              </div>
              <button id="capaciteDEmprunt" type="submit" class="fullSize" (click)="validerCharges()">Valider</button>
            </form>
          </div>
          <!-- /Charge par mois fin -->
        </ng-template>
      </ngb-tab>
      <!-- RECAPITULATIF FINAL -->
      <!-- Celibataire -->
      <ngb-tab id="totalCelibataire">
        <ng-template ngbTabTitle> <div (click)="showTabContent('Icv Light Estimée', 'etape6')"></div> </ng-template>
        <ng-template ngbTabContent>
          <div id="totalCelibataireContent" class="fade totalEstim">
            <h4>D'après votre situation, notre estimation est la suivante :</h4>
            <div class="tableRecap">
              <p>
                Le montant maximum que vous pouvez emprunter est de : <strong>{{ inputScoringLight.montantMax }} €*</strong>
              </p>
              <p>
                La mensualité maximum que vous êtes en mesure de supporter est comprise entre :
                <strong>{{ inputScoringLight.mensualiteMin }} € et {{ inputScoringLight.mensualiteMax }} €/mois</strong>
              </p>
            </div>
            <button type="submit" class="fullSize" (click)="account()">Affiner mon estimation</button>
            <button type="button" class="linkIcv fullSize" (click)="resetIcvLight()">Recommencer mon estimation</button>
          </div>
        </ng-template>
      </ngb-tab>
      <!-- Couple -->
      <ngb-tab id="totalCouple">
        <ng-template ngbTabTitle> <div (click)="showTabContent('Icv Light Estimée', 'etape6')"></div> </ng-template>
        <ng-template ngbTabContent>
          <div id="totalCoupleContent" class="fade totalEstim">
            <h4>D'après votre situation, notre estimation est la suivante :</h4>
            <div class="tableRecap">
              <p>
                Le montant maximum que vous pouvez emprunter est de : <strong>{{ inputScoringLight.montantFoyerMax }} €*</strong>
              </p>
              <p>
                La mensualité maximum que vous êtes en mesure de supporter est comprise entre :
                <strong>{{ inputScoringLight.mensualiteFoyerMin }} € et {{ inputScoringLight.mensualiteFoyerMax }} €/mois</strong>
              </p>
            </div>

            <button type="submit" class="fullSize" (click)="account()">Affiner mon estimation</button>
            
            <button type="button" class="linkIcv fullSize" (click)="resetIcvLight()">Recommencer l'estimation</button>
          </div>
        </ng-template>
      </ngb-tab>
      <!-- /RECAPITULATIF FINAL -->
    </ngb-tabset>
  </div>
  <!-- /SCORING LIGHT - PART RIGHT -->
  <p>
    <i class="asterisque">* BPCE Financement vous propose des offres de crédit à la consommation pour vos projets de travaux, achat de véhicule, voyages...
    Estimation de votre capacité d'emprunt non contractuelle. Sous réserve de l'exactitude des informations saisies et de l'acceptation de
    votre demande de crédit par BPCE Financement.</i>
  </p>
</section>
<!-- END SCORING LIGHT -->

<!-- COMMENT CA MARCHE -->
<section class="howItwork maxWidth" id="commentCaMarche">
  <div class="howItWorks scroll-with-offset">
    <h2>Comment ça marche ?</h2>
    <p class="howItWorks-desc">Calculer précisément votre capacité d'emprunt permet de vous aider à construire votre projet de façon responsable.</p>
    <ol class="clearfix">
      <li class="clearfix">
        <div class="circle"></div>
        <div class="argument clearfix">
          <p>1</p>
          <span>J'estime ma capacité d'emprunt depuis la page d'accueil</span>
        </div>
      </li>
      <li class="clearfix">
        <div class="circle"></div>
        <div class="argument clearfix">
          <p>2</p>
          <span>J'affine ma capacité d'emprunt en complétant mes informations</span>
        </div>
      </li>
      <li class="clearfix">
        <div class="circle"></div>
        <div class="argument clearfix">
          <p>3</p>
          <span>Je définis mon projet d'emprunt</span>
        </div>
      </li>
      <li class="clearfix">
        <div class="circle"></div>
        <div class="argument clearfix">
          <p>4</p>
          <span>Je souscris en ligne et je réalise mon projet</span>
        </div>
      </li>
    </ol>
  </div>
</section>
<!-- /COMMENT CA MARCHE -->

<!-- VIDEO -->
<!-- <section class="story" id="enSavoirPlus">
  <div class="maxWidth clearfix knowMore scroll-with-offset bottom-div">
    <h2>En Savoir Plus</h2>
    <iframe
      id="ytplayer"
      type="text/html"
      width="100%"
      height="510"
      src="https://www.youtube.com/embed/Y-i3XhiELrA?html5=1&amp;rel=0&amp;hl=en_US&amp;version=5"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</section> -->
<!-- /VIDEO -->

<section class="story" id="enSavoirPlus">
  <div class="maxWidth clearfix knowMore scroll-with-offset bottom-div">
    <h2>En Savoir Plus</h2>
    <iframe
      id="ytplayer"
      title="Vidéo Youtube, Financez votre projet avec un prêt 100% digital"
      type="text/html"
      height="510"
      src="https://www.youtube.com/embed/Y-i3XhiELrA?html5=1&amp;rel=0&amp;hl=fr_FR&amp;version=5"
      allowfullscreen
    ></iframe>
    <p>
      <a class="iframe-link" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" (click)="getTextFile($event)">
        Transcription textuelle de la vidéo BPCE Financement - Offre de prêt personnel
      </a>

    </p>
    <div class="collapse" id="collapseExample">
      <div class="card card-body overflow-auto p-3 bg-light transcriptionStyle">
        <p *ngFor="let line of lines" >{{line}}</p>
      </div>
    </div>
  </div>
  <p>



</section>
<!-- /VIDEO -->
