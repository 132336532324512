import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { AccountComponent } from '../../features/account/account.component';
import { AccountModel } from 'src/app/features/models/account.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/features/services/account.service';
import { Subscription } from 'rxjs';
import { LogoutComponent } from 'src/app/shared/modal/logout/logout.component';
import { ScoringService } from 'src/app/features/services/scoring.service';
import { Router } from '@angular/router';
import { QuiSommesNousComponent } from 'src/app/shared/modal/qui.sommes.nous/qui.sommes.nous.component';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { ClicModel } from 'src/app/tealium/clic.model';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  $: any;
  constructor(
    private modalService: NgbModal,
    private accountService: AccountService,
    private scoringService: ScoringService,
    private route: Router,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService
  ) {
    this.subscription = this.accountService.getMessage().subscribe(message => {
      if (message.text === 'login') {
        this.changeLibelle();
      } else {
        this.isAuthenticated = false;
        this.libelle = 'Mon Compte';
        if (message.text === 'unauthorized') {
          // if unauthorized open modal login
          this.openModal();
        }
      }
    });
  }
  libelle: any = 'Mon Compte';
  subscription: Subscription;
  isAuthenticated: boolean;
  showMonCompte: boolean;

  ngOnInit() {
    this.changeLibelle();
    this.updateHeader();
  }

  updateHeader() {
    this.showMonCompte = true;
    if (this.route.url.includes('cgu') || this.route.url.includes('mentions')) {
      this.showMonCompte = false;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToElement(element): void {
    this.scoringService.sendAnchor(element);
    this.showMonCompte = true;
    if (element === 'howItWorks') {
      // Taggage Telium
      this.tealium.link(
        this.utagDataService.constructDataLink(
          new PageModel('Header', 'header'),
          new ProduitModel(),
          new FormModel('', ''),
          new NtxModel(this.route, 'Home', 'Header', ''),
          new ClicModel('Comment ca marche ?', 'navigation', 'Home', 'Header', ''),
          new ClientModel(),
          new CustomModel('', '', '', '', '', '', '', '', '')
        )
      );
    } else if (element === 'knowMore') {
      // Taggage Telium
      this.tealium.link(
        this.utagDataService.constructDataLink(
          new PageModel('Header', 'header'),
          new ProduitModel(),
          new FormModel('', ''),
          new NtxModel(this.route, 'Home', 'Header', ''),
          new ClicModel('En savoir plus ?', 'navigation', 'Home', 'Header', ''),
          new ClientModel(),
          new CustomModel('', '', '', '', '', '', '', '', '')
        )
      );
    }
  }

  openModal() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Header', 'header'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Header', ''),
        new ClicModel('Mon compte', 'navigation', 'Home', 'Header', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    if (localStorage.getItem('isLoggedIn') === 'false' || localStorage.getItem('isLoggedIn') === null) {
      this.modalService.open(AccountComponent);
    }
  }

  changeLibelle() {
    if (localStorage.getItem('AccountInfos') !== null && localStorage.getItem('AccountInfos') !== '') {
      const accountInfos: AccountModel = JSON.parse(localStorage.getItem('AccountInfos'));
      let lastName =''
      if(accountInfos.lastName)
       lastName = accountInfos.lastName.trim();
      let civilite = '';
      if (accountInfos.sexe === 'FEMME') {
        civilite = 'Mme';
      } else if (accountInfos.sexe === 'HOMME') {
        civilite = 'M.';
      }
      this.libelle = civilite + ' ' + lastName.trim();
      this.isAuthenticated = localStorage.getItem('isLoggedIn') === 'true' ? true : false;
    } else {
      this.libelle = 'Mon Compte';
    }
  }

  logout() {
    this.modalService.open(LogoutComponent);
  }

  /*********************************************
   * Go to Dashboard
   ********************************************/
  monEspaceClient() {
    this.route.navigate(['features/dashboard']); // redirect to dashboard
    setTimeout(() => {
      document.getElementById("contenu")?.focus();
    });
  }

  /******************************************
   * Go to account settings
   ******************************************/
  settingsAccount() {
    this.route.navigate(['features/account-settings']); // redirect to account-settings
    setTimeout(() => {
      document.getElementById("contenu")?.focus();
    });
  }
  /******************************************
   * Show modale qui sommes nous
   ******************************************/
  goToQuiSommesNous() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Header', 'header'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Header', ''),
        new ClicModel('Qui sommes nous  ?', 'navigation', 'Home', 'Header', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
    this.modalService.open(QuiSommesNousComponent);
  }

  /*****************************
   * Go to FAQ
   *****************************/
  goToFaq() {
    this.route.navigate(['features/faq']);
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Header', 'header'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Header', ''),
        new ClicModel('FAQ (Header)', 'navigation', 'Home', 'Header', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  /*****************************
   * Go to home
   *****************************/
  goToHome() {
    this.route.navigate(['home']);
    this.showMonCompte = true;
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Header', 'header'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Header', ''),
        new ClicModel('Logo', 'navigation', 'Home', 'Header', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
}
