export class ConstantsModel {
  siteName: string;
  siteUrl: string;
  shareCapital: string;
  companyPhoneNumber: string;
  companyEmail: string;
  nomDomaine1: string;
  nomDomaine2: String;
  registrationDate: string;
  registrationAuthority: string;
  listOfCookies: string;

  constructor() {
    this.siteName = 'Prêt personnel BPCE Financement';
    this.siteUrl = 'http://www.pret-personnel.bpce.fr/#/';
    this.shareCapital = '73 801 950 euros';
    this.companyPhoneNumber = '09 72 72 02 76';
    this.companyEmail = 'https://groupebpce.com/nous-contacter';
    this.nomDomaine1 = 'www.pret-personnel.bpce.fr';
    this.nomDomaine2 = 'www.pret-personnel.bpce.com';
    this.registrationDate = '20/11/2018';
    this.registrationAuthority = 'CSC Digital Brand Services';
  }
}
