import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TokenModel } from './features/models/token.model';
import { catchError, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injector, Inject, Injectable } from '@angular/core';
import { AuthOauth2Service } from './features/services/auth.oauth2.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AccountService } from './features/services/account.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor(
    @Inject(Injector) private injector: Injector,
    private accountService: AuthOauth2Service,
    private connexionService: AccountService,
    private modalService: NgbModal,
    private route: Router
  ) {}
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  isProcessed = false;
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header only if API in url
    const tokenAccess: TokenModel = JSON.parse(localStorage.getItem('tokenAuthentication'));
    // On ne s'adresse pas au backend || On est probablement pas connecte
    let clonedRequest = req;
    if (req.url.indexOf('/api/') !== -1 && tokenAccess && tokenAccess.token_type) {
      if (tokenAccess.expires_at && tokenAccess.expires_at > new Date().getTime()) {
        clonedRequest = req.clone({
          headers: req.headers.set('Authorization', tokenAccess.token_type + ' ' + tokenAccess.access_token)
        });
      }
    }
    return next.handle(clonedRequest).pipe(
      tap(
        event => {},
        (err: any) => {
          if (err.status === 500) {
            throwError(err);
            this.toastr.error('Service indisponible', '', {
              disableTimeOut: true,
              positionClass: 'toast-top-full-width',
              closeButton: true
            });
          } else if (
            (err.status === 401 || err.statusText === 'Unauthorized') &&
            !this.isProcessed &&
            err.url.indexOf('ccoweb/oauth/token') === -1
          ) {
            this.isProcessed = true;
            this.accountService.unAuthorizedLogout().subscribe(() => {
              localStorage.removeItem('isLoggedIn');
              localStorage.removeItem('AccountInfos');
              localStorage.removeItem('tokenAuthentication');
              this.route.navigate(['features/access-denied'], { skipLocationChange: true });
              this.connexionService.sendMessage('unauthorized');
            });
          }
        }
      )
    );
  }
}
