
<section  class="cgu maxWidth clearfix">
    <h1 class="page_title_1">Conditions générales d'utilisation</h1>

    <div class = "content">
        <nav role="navigation" aria-label="Accéder à">
            <div class="menu center-block">
                <h2 class="menu-title">Accéder à :</h2>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#objet">  I. Objet du site </a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#creationCompte">  II. Conditions de création d'un compte et fonctionnement</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#donnesPerso"> III. Protection des données personnelles</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#intellectuel">IV. Propriété intellectuelle - Copyright</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#hypertexte">  V. Liens hypertextes et virus</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#signature"> VI. Signature électronique - Convention de preuve - E-Documents</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#suppressionCompte">  VII. Suppression du compte utilisateur</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#modification"> VIII. Modification des conditions générales d'utilisation</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#droitLangue">  IX. Droit applicable et langue utilisée</a>
              <a pageScroll [pageScrollOffset]="100" [pageScrollDuration]="500" href="#telephone">  X. Démarchage par téléphone</a>
            </div>
        </nav>
        <div class = "text-content col-md-12">
            Les présentes conditions générales d'utilisation ont pour objet de définir les modalités de mise à disposition et d'utilisation de Prêt personnel BPCE Financement. Tout accès ou utilisation du site et du compte utilisateur suppose l'acceptation et le respect des présentes conditions générales d'utilisation.
            <h3 class="section-content-title" id = "objet">I. Objet du site</h3>
            <p>Le site internet Prêt personnel BPCE Financement (ci-après « Site ») est géré par BPCE Financement.<br/>
                Le Site  est destiné aux personnes physiques majeures, capables et résidents fiscaux français justifiant d'une domiciliation en France métropolitaine (ci-après dénommées « utilisateur »).<br/><br/>
                Le Site est composé d'un espace utilisateur qui permet à l'utilisateur :</p>
            <ul>
                <li> De se renseigner sur la capacité d'emprunt dont il pourrait disposer auprès de BPCE Financement</li>
                <li> D'effectuer des simulations de crédit à la consommation</li>
                <li> De faire une demande de financement, de souscrire à un crédit à la consommation et à l'assurance emprunteur facultative associée (ci-après « Produit ») par voie électronique.</li>
            </ul><br/>
            <p>La demande de financement fera l'objet d'une étude par BPCE Financement.</p>

            <h3 class="section-content-title" id="creationCompte">II. Conditions de création d'un compte et fonctionnement</h3>
            <p>Pour pouvoir connaitre sa capacité d'emprunt détaillée ou pour pouvoir faire une demande de financement, l'utilisateur doit créer un compte utilisateur. Pour l'ouverture d'un compte utilisateur, l'utilisateur doit communiquer les informations personnelles relatives à son identité (nom et prénom) et son adresse email valide qui sert d'identifiant. A défaut, l'ouverture d'un compte utilisateur n'est pas possible. L'utilisateur doit valider la création du compte dans les 72 heures en cliquant sur un lien qui lui est adressé sur son adresse email. Si l'utilisateur ne valide pas la création du compte, il ne pourra pas bénéficier de l'entièreté des fonctionnalités du site internet. Si l'utilisateur n'a pas validé la création du compte dans un délai de 72 heures, il ne pourra plus accéder au compte utilisateur tant que cette validation n'a pas été faite.<br/><br/>
                L'accès à l'espace utilisateur est sécurisé et un utilisateur doit choisir son mot de passe. L'utilisateur est tenu de garder confidentiel le mot de passe lui permettant d'accéder à son compte utilisateur. Le mot de passe est personnel et ne doit ni être partagé, ni transféré à une quelconque personne physique ou morale, sous quelque forme que ce soit.<br/><br/>
                BPCE Financement ne peut être tenue pour responsable de tout dommage qui pourrait être créé par une indisponibilité du service. BPCE Financement ne garantit pas que le Site soit accessible sans interruption. L'accès au Site pourra notamment être interrompu pour des raisons de maintenance.<br/>
                L'utilisateur s'engage à utiliser le Site selon les finalités décrites et à ne pas utiliser de dispositifs ayant pour effet ou finalité de perturber le fonctionnement du Site ou à augmenter la charge sur les infrastructures du Site de manière disproportionnée.
            </p>

            <h3 class="section-content-title" id="donnesPerso">III. Protection des données personnelles</h3>
            <p>
                Les informations concernant l'utilisation des données personnelles de l'utilisateur par BPCE Financement, responsable de traitement, ainsi que les droits dont il dispose sont détaillées dans notre <a style = "display : inline"  href="http://www.notice-financement.bpce.fr">Notice d'information sur la protection des données personnelles</a>.
            </p>

            <h3 class="section-content-title" id="intellectuel">IV. Propriété intellectuelle - Copyright</h3>
            <p>
                Ce Site constitue une œuvre protégée en France par le code de la propriété intellectuelle et, à l'étranger, par les conventions internationales en vigueur sur le droit d'auteur. La reproduction de tout ou partie de ce site ou de son contenu, par quelque procédé que ce soit, est formellement interdite, sauf autorisation expresse du directeur de la publication. La violation de l'un des droits d'auteur de l'œuvre est un délit de contrefaçon passible, aux termes de l'article L. 335-2 du code de la propriété intellectuelle, de trois ans d'emprisonnement et de 300 000 euros d'amende.
            </p>
            <p>
                Les marques citées sur ce site sont déposées par les entités du groupe BPCE qui en sont propriétaires ou détiennent tout droit à cet effet de leurs titulaires. Toute reproduction ou imitation totale ou partielle des marques et/ou logos est interdite sans l'accord préalable de leurs propriétaires ou de leurs titulaires, conformément aux articles L. 713-2 et L. 713-3 du code de la propriété intellectuelle. Il en est de même des bases de données figurant au sein du site dont le contenu est protégé, notamment par les dispositions de la loi du 1er juillet 1998 relative à la protection juridique des bases de données. La violation de ce droit est constitutive de contrefaçon et passible à ce titre de poursuites judiciaires, tant au plan civil que pénal.
            </p>

            <h3 class="section-content-title" id="hypertexte">V. Liens hypertextes et virus</h3>
            <p>Les liens hypertextes, éventuellement mis en place dans le cadre du Site en direction d'autres sites présents sur le réseau Internet, ne sauraient engager la responsabilité de BPCE Financement. Le fait de fournir des liens hypertextes ne constitue, en aucune manière, un conseil ou un avis concernant le contenu des sites auxquels ils renvoient.</p>

            <p>En outre, les utilisateurs et visiteurs du Site ne peuvent mettre en place un hyperlien en direction du présent site sans l'autorisation expresse et préalable de BPCE Financement.</p>
            <p>De façon générale, il appartient à l'utilisateur de prendre les précautions nécessaires pour s'assurer que le site sélectionné n'est pas infesté de virus ou de tout autre parasite de nature destructive. En aucun cas, BPCE Financement ne pourra être tenu responsable des dommages directs ou indirects résultant de l'usage de son site web ou d'autres sites qui lui sont liés.</p>
            <p>L'utilisateur reconnaît avoir connaissance des contraintes et limites du réseau Internet (Internet et Internet mobile).</p>
            <p>Il incombe à l'utilisateur de protéger ses équipements techniques notamment contre toute forme de contamination par des virus et/ou de tentative d'intrusion, BPCE Financement ne pouvant en aucun cas en être tenu pour responsable.</p>
            <p>L'utilisateur est seul responsable de l'installation, de l'exploitation et de la maintenance de ses équipements techniques nécessaires pour consulter le Site . En aucun cas BPCE Financement ne saurait être tenu responsable si le Site s'avère incompatible ou présente des dysfonctionnements avec certains équipements de l'utilisateur. En outre, BPCE Financement n'assume aucune responsabilité pour les dommages qui pourraient être causés aux équipements des utilisateurs.</p>

            <h3 class="section-content-title" id="signature">VI. Signature électronique - Convention de preuve - E-Documents</h3>
            <p>Signature électronique - Convention de preuve :<br/>

                Le Site permet la souscription de tout Produit exclusivement au moyen d'une signature électronique associée à un certificat électronique à usage unique.

                L'utilisateur doit disposer d'un téléphone mobile personnel dont le numéro, géré par un opérateur déclaré en France, a été préalablement porté à la connaissance de BPCE Financement.

                Pour la souscription du Produit au moyen d'une signature électronique, BPCE Financement utilise une plateforme gérée par un prestataire de services de confiance qualifié.

                L'utilisateur accepte que les informations demandées en vue de la conclusion du contrat puissent être transmises en format électronique.

                Avant d'engager le processus de souscription, les documents précontractuels sont mis à la disposition de l'utilisateur sur le Site et peuvent faire l'objet d'une impression sur support papier et/ou d'un enregistrement dans un format intègre sur le disque dur de son ordinateur ainsi que sur tout autre support durable.

                L'utilisateur est invité à prendre connaissance des documents précontractuels pour en accepter les termes.

                A ce stade, l'utilisateur dispose de la possibilité de signer les documents précontractuels à l'étape finale de signature électronique ou d'abandonner la procédure de signature électronique.</p><br/>

            <p>Une fois les documents précontractuels acceptés, l'utilisateur est amené au moyen du processus mis en place par BPCE Financement :</p>
            <ul>
                <li>        à signer électroniquement le Produit,</li>

                <li>       à confirmer la signature électronique du Produit.</li></ul>

            <p>Le Produit est ainsi accepté par l'utilisateur à la date de sa signature électronique. Cette date fait courir le point de départ du délai de rétractation ou du délai de renonciation en vigueur.

            A l'issue du processus de signature électronique, BPCE Financement met à disposition de l'utilisateur les documents signés sur le Site, une telle mise à disposition valant remise.

            Il est convenu que le processus de souscription d'un Produit au moyen d'une signature électronique décrite ci-avant est le même pour un Produit souscrit par l'utilisateur vis-à-vis du partenaire commercial de BPCE Financement (Assureur : assurance emprunteur facultative).

            Le Produit est définitivement souscrit sous réserve du non exercice de la faculté de rétractation ou de renonciation en vigueur par l'utilisateur et sous réserve de l'agrément définitif du prêteur, BPCE Financement.

            L'utilisateur dispose de la possibilité d'obtenir une copie papier des documents signés à tout moment dans un délai conforme aux exigences légales en s'adressant à son centre de relation clientèle.</p><br/>

                L'utilisateur accepte :<ul>

            <li>    Que les éléments d'identification utilisés, à savoir les OTP SMS (« One Time Password » : mot de passe unique) et les numérisations des pièces d'identité, et les certificats électroniques à usage unique soient admissibles devant les tribunaux et fassent preuve des données et des éléments qu'ils contiennent ainsi que des procédés d'authentification et des signatures qu'ils expriment ;
            </li>
            <li>Que les éléments d'horodatage soient admissibles devant les tribunaux et fassent preuve des données qu'ils contiennent ;</li>

            <li>  Qu'il manifeste son consentement en saisissant l'OTP SMS et/ou en cochant des cases et/ou en utilisant tout autre moyen mis à sa disposition, que ces procédés soient admissibles devant les tribunaux et fassent preuve des données et des éléments qu'ils matérialisent ainsi que des signatures qu'ils expriment conformément aux exigences légales ;
            </li>
            <li>  Que tout fichier, enregistrement sur le Site ou sur tout autre support informatique de BPCE Financement soient admissibles devant les tribunaux et fassent preuve des données et des éléments qu'ils contiennent.
            </li>
        </ul>
            <br/>
            <p>
                E-Documents :<br/>

                L'utilisateur accepte que les informations et les documents liés à l'exécution ou la gestion du Produit (crédit à la consommation) puissent être consultés, téléchargés et imprimés à tout moment en format électronique déposés sur le Site. L'utilisateur accepte également de recevoir des messages de gestion par SMS.<br/>

                L'utilisateur est informé de la mise à disposition des documents ou des informations sur le Site. Cette notification est réalisée par l'envoi d'un message à son adresse mail valide utilisée au titre d'identifiant.d'accès au Site.<br/>

                En cas d'indisponibilité de l'information/document en format électronique, celui-ci sera transmis en format papier. BPCE Financement peut également, notamment pour des raisons juridiques ou réglementaires ou lorsque l'envoi papier apparaît plus adapté au type de communication envisagé, choisir d'adresser certains documents au format papier.<br/>

                Les informations/documents sont consultables pendant 10 ans à compter de leur mise à disposition. Il appartient donc à l'utilisateur de procéder à la sauvegarde de ces informations/documents en ligne, afin de pouvoir en disposer, avant l'expiration du délai de mise à disposition sur le Site.<br/>

                L'utilisateur peut mettre fin au service « e-Documents », à tout moment et sans frais, en s'adressant à son centre de relation clientèle. Cette résiliation porte sur l'ensemble des informations/documents et implique le retour automatique aux informations/documents sous format papier.<br/>

                BPCE Financement peut résilier le service « e-Documents » à tout moment, au moyen d'une notification par l'envoi d'un message à l'adresse mail valide de l'utilisateur.<br/><br/>

                En cas de résiliation du service « e-Documents » par l'utilisateur ou par BPCE Financement, l'accès à l'historique des informations/documents en ligne reste uniquement possible pour la période pendant laquelle l'utilisateur a souscrit au service « e-Documents », dans la limite des durées de consultation définies plus avant et sous réserve de la non suppression du compte utilisateur.<br/>

                En cas de suppression du compte utilisateur, l'utilisateur ne pourra plus consulter en lignes les informations/documents. Il lui appartiendra donc de procéder à la sauvegarde de ces informations/documents en ligne, afin de pouvoir en disposer avant la suppression effective du compte utilisateur.
            </p>
            <h3 class="section-content-title" id="suppressionCompte">VII. Suppression du compte utilisateur</h3>

          <p>En cas d'absence de conclusion d'un contrat relatif au Produit avec BPCE Financement, l'utilisateur peut supprimer à tout moment son compte et toutes les données associées au compte utilisateur seront supprimées.
            </p>
          <p>
            En cas d’inactivité constatée de l’utilisateur (absence de connexion) sur le site de BPCE Financement durant une période de 24 mois, BPCE Financement supprimera le compte utilisateur ainsi que toutes les données personnelles associées. BPCE Financement informera l’utilisateur par email un mois avant la suppression effective de son compte.
          </p>
            <p>Par ailleurs, BPCE Financement se réserve le droit de suspendre l'accès ou l'exécution de tout ou partie des services de l'espace utilisateur sans aucun préavis, ni formalité, notamment  en cas de faits laissant présumer une tentative ou l'utilisation frauduleuse de ces services ou l'utilisation de contenu illicite par le biais de ces services ou pour réaliser des opérations de maintenance.</p>

            <h3 class="section-content-title" id="modification">VIII. Modification des conditions générales d'utilisation</h3>
            <p>BPCE Financement peut  modifier, compléter ou remplacer  les dispositions des présentes conditions générales d'utilisation. Celles-ci prendront effet lors de leur publication sur le Site ou par toute autre forme de notification. L'utilisation du Site après notification des modifications implique que l'utilisateur accepte les nouvelles conditions générales d'utilisation.</p>

            <h3 class="section-content-title" id="droitLangue">IX. Droit applicable et langue utilisée</h3>
            <p>La conclusion, l'interprétation et la validité des présentes conditions générales d'utilisation sont régies par le droit français. La langue utilisée est le français.</p>

            <h3 class="section-content-title" id="telephone">X. Démarchage par téléphone</h3>
            <p>Conformément aux dispositions du code de la consommation, l'utilisateur est informé qu'il peut s'inscrire sur une liste d'opposition au démarchage téléphonique. L'utilisateur est informé que malgré cette inscription, il peut être démarché par téléphone par BPCE Financement dès lors qu'il existe des relations contractuelles antérieures.</p>
        </div>
    </div>
</section>

