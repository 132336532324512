import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoginModel } from '../models/login.model';
import { TokenModel } from '../models/token.model';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountComponent } from '../account/account.component';

@Injectable({
  providedIn: 'root'
})
export class AuthOauth2Service {
  constructor(private http: HttpClient, private router: Router, private modalService: NgbModal) {}

  login(credentials: LoginModel) {
    const data =
      'username=' +
      encodeURIComponent(credentials.username) +
      '&password=' +
      encodeURIComponent(credentials.password) +
      '&grant_type=password&scope=read%20write&' +
      'client_secret=secret-key-api-instant&client_id=Instantapp';

    return this.http.post<TokenModel>('/ccoweb/oauth/token', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json',
        Authorization: 'Basic ' + btoa('Instantapp' + ':' + 'secret-key-api-instant')
      }
    });
  }

  logout() {
    this.http.post('/ccoweb/api/logout', null).subscribe(() => {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('AccountInfos');
      localStorage.removeItem('tokenAuthentication');
      this.router.navigate(['home']);
    });
  }

  unAuthorizedLogout() {
    return this.http.post('/ccoweb/api/logout', null);
  }

  simpleLogout() {
    return this.http.post('/ccoweb/api/logout', null).subscribe(() => {
      localStorage.removeItem('isLoggedIn');
      localStorage.removeItem('AccountInfos');
      localStorage.removeItem('tokenAuthentication');
    });
  }

  resetPassword(keyAndPassword: any) {
    return this.http.post('/ccoweb/api/account/reset_password/finish', keyAndPassword, {});
  }
}
