
export class ProduitModel {
  gamme: string;
  code: string;

  constructor() {
    this.gamme = 'credit conso';
    this.code = 'pret personnel';
  }
}
