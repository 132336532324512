export class PageModel {
  pageName: string;
  applicationType: string;
  applicationName: string;
  CodeEnttGrpe: string;
  marche: string;
  univers: string;
  pageType: string;
  espace: string;

  constructor(pageName: string, pageType: string) {
    this.pageName = pageName;
    this.pageType = pageType;
    this.applicationType = 'site';
    this.applicationName = 'samy credit';
    this.CodeEnttGrpe = 'xxxx';
    this.marche = 'particuliers';
    this.univers = 'financement';
    this.espace = 'transactionnel';
  }
}
