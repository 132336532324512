import { Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { ResetPasswordComponent } from './features/account/reset-password/reset-password.component';
import { AccountActivatedComponent } from './features/account/account-activated/account-activated.component';
import { NieSimulateurGuard } from './features/services/route-guards/nie-simulateur.guard';

export const APP_ROUTES: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'demo', 
  loadChildren: () => import('./features/provenance/provenance.module').then(m => m.ProvenanceModule), 
  canActivate: [NieSimulateurGuard]
},
  {
    path: 'docs',
    loadChildren: () => import('./swagger/swagger.module').then(m => m.SwaggerModule)
  },
  { path: 'home', component: HomeComponent },
  { path: 'activate', component: AccountActivatedComponent },
  {
    path: 'features',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule)
  },
  { path: 'sign-invitation', component: HomeComponent },
  { path: 'reset/finish', component: ResetPasswordComponent }
];
