import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuiSommesNousComponent } from 'src/app/shared/modal/qui.sommes.nous/qui.sommes.nous.component';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { ClicModel } from 'src/app/tealium/clic.model';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService,
    private route: Router
  ) {}

  ngOnInit() {}
  tagCookies(){
    window.scroll(0,0);
    (<any>window).utag.gdpr.showConsentPreferences();
  }

  goToQuiSommesNous() {
    this.modalService.open(QuiSommesNousComponent);
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Footer', 'footer'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Footer', 'A Propos'),
        new ClicModel('Qui sommes nous?', 'navigation', 'Home', 'Footer', 'A Propos'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagCgu() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Footer', 'footer'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Footer', 'A Propos'),
        new ClicModel('Qui sommes nous?', 'navigation', 'Home', 'Footer', 'A Propos'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagMentions() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Footer', 'footer'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Footer', 'A Propos'),
        new ClicModel('Mentions Legales', 'navigation', 'Home', 'Footer', 'A Propos'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagFaq() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Footer', 'footer'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Footer', 'A Propos'),
        new ClicModel('FAQ (Bas de page)', 'navigation', 'Home', 'Footer', 'Aide et Assistance'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  tagAccessibilite() {
    // Taggage Telium
    this.tealium.link(
      this.utagDataService.constructDataLink(
        new PageModel('Footer', 'footer'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.route, 'Home', 'Footer', 'A Propos'),
        new ClicModel('Accessibilité', 'navigation', 'Home', 'Footer', 'Aide et Assistance'),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }
}
