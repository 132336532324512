import { Routes, RouterModule } from '@angular/router';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
export const SHARED_ROUTES: Routes = [
  {
    path: '12access-denied',
    component: AccessDeniedComponent
  }
];

export const sharedRouting = RouterModule.forChild(SHARED_ROUTES);
