import { CanActivate, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountComponent } from '../../account/account.component';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
  constructor(private modalService: NgbModal, private route: Router) {}
  canActivate() {
    if (localStorage.getItem('isLoggedIn') === 'true') {
      return true;
    } else {
      if (!this.modalService.hasOpenModals()) {
        this.modalService.open(AccountComponent);
      }
      this.route.navigate(['/home']);
      return false;
    }
  }
}
