import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AccountService} from 'src/app/features/services/account.service';

@Component({
  selector: 'app-bridge-form',
  templateUrl: './bridge-form.component.html',
  styleUrls: ['./bridge-form.component.css']
})
export class BridgeFormComponent implements OnInit {
  
  constructor(public activeModal: NgbActiveModal, private accountService: AccountService,
    private route: Router, ) {
  }

  ngOnInit() {
  }

  annuler() {
    this.activeModal.dismiss('cancelExitBridge');
  }

  exitBridge() {
    localStorage.setItem('source', 'classique');
    localStorage.setItem('goToMesInfos', 'true');
    this.route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.route.navigate(['features/dashboard']);
    this.activeModal.dismiss('exitBridge');
  }
}
