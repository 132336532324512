<section class="faq maxWidth">
  <h1 class="page_title_1">Questions / Réponses</h1>
  <!--
    <div class="moteur">
        <form class="clearfix" id="form" name="form" method="post" action="">
            <input name="search" type="text" id="search" placeholder="Posez votre question ici"/>
            <button class="search">Rechercher</button>
        </form>
    </div>
    -->
  <!-- INFO DEV
    >>>> SI UNE QUESTION A ETE POSEE PAR UN INTERNAUTE, LE MENU DISPARAIT AINSI QUE LES 4 TAB-CONTENT -->
  <!-- NAV -->
  <ul id="tab" class="nav clearfix" role="tablist" aria-orientation="horizontal">
    <li [ngClass]="{ active: isActive1 }" role="presentation">
      <button id="tab1" (click)="activer1()" data-toggle="tab" data-target="#general" type="button" role="tab" aria-controls="general" [attr.aria-selected]="isActive1">Général</button>
    </li>
    <li [ngClass]="{ active: isActive2 }" role="presentation">
      <button id="tab2" (click)="activer2()" data-toggle="tab" data-target="#espaceclient" type="button" role="tab" aria-controls="espaceclient" [attr.aria-selected]="isActive2">Espace client</button>
    </li>
    <li [ngClass]="{ active: isActive3 }" role="presentation">
      <button id="tab3" (click)="activer3()" data-toggle="tab" data-target="#souscription" type="button" role="tab" aria-controls="souscription" [attr.aria-selected]="isActive3">Souscription</button>
    </li>
    <li [ngClass]="{ active: isActive4 }" role="presentation">
      <button id="tab4" (click)="activer4()" data-toggle="tab" data-target="#suivi" type="button" role="tab" aria-controls="suivi" [attr.aria-selected]="isActive4">Suivi de mon prêt</button>
    </li>
  </ul>
  <!-- END NAV -->
  <div class="tab-content">
    <!-- GENERAL -->
    <div id="general" class="tab-pane active" [hidden]="!isActive1" role="tabpanel" aria-labelledby="tab1" tabindex="-1">
      <ul class="panelSuivi">
        <!-- FAQ 1 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq1"
            data-toggle="collapse"
            (click)="tagGeneralSections('J\'ai oublié mon mot de passe')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq1" aria-expanded="false" aria-controls="faq1" >J'ai oublié mon mot de passe</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq1">
            Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser en cliquant sur "Mon compte" puis sur le lien "Mot de passe
            oublié ?". Vous recevrez un email vous permettant de créer un nouveau mot de passe. Votre mot de passe doit être composé d'au
            moins 8 caractères comprenant au moins une lettre majuscule, une lettre minuscule et un chiffre.
          </div>
        </li>
        <!-- END FAQ 1 -->
        <!-- FAQ 2 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq2"
            data-toggle="collapse"
            (click)="tagGeneralSections('Je ne souhaite plus recevoir les offres commerciales par mail')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq2" aria-expanded="false" aria-controls="faq2" >Je ne souhaite plus recevoir les offres commerciales par mail</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq2">
            Il vous suffit d'adresser un courrier à BPCE Financement, Service Consommateurs, TSA 68004, 59781 Lille Cedex 9 ou un email à
            financement-serviceconsommateurs@bpce.fr.
          </div>
        </li>
        <!-- END FAQ 2 -->
        <!-- FAQ 3 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq3"
            data-toggle="collapse"
            (click)="tagGeneralSections('Je n\'arrive pas à activer mon compte, comment recevoir un nouveau un mail d\'activation ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq3" aria-expanded="false" aria-controls="faq3" >Je n'arrive pas à activer mon compte, comment recevoir un nouveau un mail d'activation ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq3">
            Si vous n'avez pas reçu le mail d'activation, vérifiez que celui-ci ne se trouve pas dans vos courriers indésirables. Si vous
            vous connectez 72h après la création de votre compte, la fenêtre de connexion vous proposera de recevoir un nouveau lien
            d'activation par mail à l'adresse mail saisie lors de la création de votre compte.
          </div>
        </li>
        <!-- END FAQ 3 -->
        <!-- FAQ 4 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq4"
            data-toggle="collapse"
            (click)="tagGeneralSections('Le montant de ma capacité d\'emprunt est-il le montant définitif que je pourrais emprunter ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq4" aria-expanded="false" aria-controls="faq4" >Le montant de ma capacité d'emprunt est-il le montant définitif que je pourrais emprunter ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq4">
            Votre capacité d'emprunt maximum correspond au montant de crédit dont vous pouvez bénéficier, sous réserve de la véracité des
            informations que vous nous avez communiquées et que vous ne soyez pas inscrit au Fichier des incidents de remboursement des
            crédits aux particuliers (FICP).
          </div>
        </li>
        <!-- END FAQ 4 -->
        <!-- FAQ 5 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq5"
            data-toggle="collapse"
            (click)="tagGeneralSections('Comment contacter un conseiller ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq5" aria-expanded="false" aria-controls="faq5" >Comment contacter un conseiller ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq5">
            Pour vous accompagner lors de la souscription de votre crédit, vous pouvez contacter un conseiller par Tchat, par téléphone ou
            fixer un rendez-vous téléphonique directement depuis votre Espace personnel. Si vous avez un crédit en cours de remboursement,
            nos équipes Service Clients sont à votre disposition au 09 72 72 02 76 (prix d'un appel local), le lundi de 8h45 à 18h00, du
            mardi au vendredi de 8h45 à 18h30 et le samedi de 8h45 à 12h30.
          </div>
        </li>
        <!-- END FAQ 5 -->
        <!-- FAQ 6 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq6"
            data-toggle="collapse"
            (click)="tagGeneralSections('Pourquoi une bulle ou une fenêtre apparait parfois à l\'écran ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq6" aria-expanded="false" aria-controls="faq6" >Pourquoi une bulle ou une fenêtre apparait parfois à l'écran ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq6">
            Cette bulle ou cette fenêtre vous indique que vous pouvez contacter l'un de nos conseillers (par Tchat ou demander à être appelé
            par téléphone). Si rien ne s'affiche, cela signifie que tous nos conseillers sont en ligne à cet instant. Dans cette
            éventualité, vous avez tout de même la possibilité de fixer un rendez-vous téléphonique avec l'un de nos conseillers.
          </div>
        </li>
        <!-- END FAQ 6 -->

        <!-- FAQ 8 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq8"
            data-toggle="collapse"
            (click)="tagGeneralSections('Pourquoi suis-je déconnecté ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq8" aria-expanded="false" aria-controls="faq8" >Pourquoi suis-je déconnecté ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq8">
            Si vous avez été déconnecté de votre compte, le délai de connexion a sans doute expiré. Pour des raisons de sécurité, vous devez
            vous connecter à nouveau pour accéder à votre Espace personnel.
          </div>
        </li>
        <!-- END FAQ 8 -->
        <!-- FAQ 9 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq9"
            data-toggle="collapse"
            (click)="tagGeneralSections('Qu\'est-ce que la capacité d\'emprunt ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq9" aria-expanded="false" aria-controls="faq9" >Qu'est-ce que la capacité d'emprunt ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq9">
            Le calcul de la capacité d'emprunt vous permet de connaître le montant réel que vous pourriez emprunter pour un crédit à la
            consommation. La capacité d'emprunt calculée correspond au montant maximum que vous pourriez emprunter auprès de BPCE
            Financement et à la mensualité maximale que vous pourriez rembourser (après étude de votre demande de crédit).
          </div>
        </li>
        <!-- END FAQ 9 -->
        <!-- FAQ 10 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq10"
            data-toggle="collapse"
            (click)="tagGeneralSections('Comment est calculée ma capacité d\'emprunt ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq10" aria-expanded="false" aria-controls="faq10" >Comment est calculée ma capacité d'emprunt ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq10">
            <p>Votre capacité d'emprunt maximum est calculée grâce aux informations que vous saisissez. Avec les 1ères
              informations déclarées
              (situation familiale, nombre d'enfants à charge, code postal d'habitation, revenus et charges mensuels), vous
              obtenez une
              estimation de votre capacité d'emprunt.
            </p>
            <p>Les informations complémentaires saisies par la suite dans votre Espace personnel affine votre capacité d'emprunt
              et
              vous permettent d'emprunter dans la limite de ce montant (après étude de votre dossier).
            </p>
          </div>
        </li>
        <!-- END FAQ 10 -->
        <!-- FAQ 11 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq11"
            data-toggle="collapse"
            (click)="tagGeneralSections('Pourquoi me demande-t-on mon code postal d\'habitation ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq11" aria-expanded="false" aria-controls="faq11" >Pourquoi me demande-t-on mon code postal d'habitation ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq11">
            Le code postal est une information requise permettant de connaître votre lieu de résidence. En effet, seuls les clients
            résidants en France métropolitaine peuvent bénéficier d'une offre.
          </div>
        </li>
        <!-- END FAQ 11 -->
        <!-- FAQ 12 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#faq12"
            data-toggle="collapse"
            (click)="tagGeneralSections('Quelles sont les conditions pour souscrire à un crédit ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#faq12" aria-expanded="false" aria-controls="faq12" >Quelles sont les conditions pour souscrire à un crédit ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="faq12">
            <p>
              Lors de l'analyse d'une demande de prêt, nos conseillers s'assurent que le prêt demandé est en adéquation avec votre
              capacité
              d'emprunt à partir des informations communiquées et des justificatifs fournis.
            </p>
            <p>
              Un crédit vous engage et doit être remboursé. Vérifiez vos capacités de remboursement avant de vous engager.
            </p>
          </div>
        </li>
        <!-- END FAQ 12 -->
      </ul>
    </div>


    <!-- END GENERAL -->

    <!-- ESPACE CLIENT -->
    <div id="espaceclient" class="tab-pane fade"  role="tabpanel" aria-labelledby="tab2" tabindex="-1">
      <ul class="panelSuivi">
        <!-- FAQ 1 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#espacefaq1"
            data-toggle="collapse"
            (click)="tagEspaceSections('Mes charges et/ou mes revenus ont évolué, comment actualiser ma capacité d\'emprunt ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#espacefaq1" aria-expanded="false" aria-controls="espacefaq1" >Mes charges et/ou mes revenus ont évolué, comment actualiser ma capacité d'emprunt ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="espacefaq1">
            Nous vous invitons à mettre à jour au fil de l'eau votre situation personnelle et professionnelle, vos revenus et charges dans
            votre espace personnel, rubrique "Mes informations". En cas de modification, vous serez invité à cliquer sur "Mettre à jour ma
            capacité d'emprunt" (le bouton deviendra rouge).
          </div>
        </li>
        <!-- END FAQ 1 -->
        <!-- FAQ 2 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#espacefaq2"
            data-toggle="collapse"
            (click)="tagEspaceSections('J\'ai déménagé, comment mettre mon adresse à jour ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#espacefaq2" aria-expanded="false" aria-controls="espacefaq2" >J'ai déménagé, comment mettre mon adresse à jour ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="espacefaq2">
            Pour modifier votre adresse postale, nous vous invitons à contacter notre Service Clients au 09 72 72 02 76 (prix d'un appel
            local). Un conseiller vous donnera alors la marche à suivre pour recueillir votre nouveau justificatif de domicile.
          </div>
        </li>
        <!-- END FAQ 2 -->
        <!-- FAQ 3 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#espacefaq3"
            data-toggle="collapse"
            (click)="tagEspaceSections('Comment modifier mon état civil ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#espacefaq3" aria-expanded="false" aria-controls="espacefaq3" >Comment modifier mon état civil ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="espacefaq3">
            Si votre situation personnelle évolue, vous avez la possibilité, à tout moment, de mettre à jour votre état civil en vous
            rendant dans votre Espace personnel, rubrique "Mes informations". Un conseiller reviendra vers vous pour recueillir votre
            nouveau justificatif d'identité si ce changement intervient alors que vous avez déjà souscrit un prêt.
          </div>
        </li>
        <!-- END FAQ 3 -->
        <!-- FAQ 4 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#espacefaq4"
            data-toggle="collapse"
            (click)="tagEspaceSections('Je n\'ai pas activé mon compte dans les 72h suivant la création de mon compte, comment faire ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#espacefaq4" aria-expanded="false" aria-controls="espacefaq4" >Je n'ai pas activé mon compte dans les 72h suivant la création de mon compte, comment faire ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="espacefaq4">
            Si votre compte n'a pas été activé dans les 72h suivant la réception du mail d'activation vous ne pourrez plus accéder au site.
            Il vous faudra activer votre compte avec le mail reçu ou demander l'envoi d'un nouveau mail d'activation.
          </div>
        </li>
        <!-- END FAQ 4 -->
        <!-- FAQ 5 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#espacefaq5"
            data-toggle="collapse"
            (click)="tagEspaceSections('Où trouver le descriptif de l\'offre de crédit ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#espacefaq5" aria-expanded="false" aria-controls="espacefaq5" >Où trouver le descriptif de l'offre de crédit ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="espacefaq5">
            Vous pouvez accéder à tout moment, au descriptif des offres à partir de votre Espace personnel.
          </div>
        </li>
        <!-- END FAQ 5 -->
      </ul>
    </div>
    <!-- END ESPACE CLIENT -->

    <!-- SOUCRIPTION -->
    <div id="souscription" class="tab-pane fade" [hidden]="!isActive3" role="tabpanel" aria-labelledby="tab3" tabindex="-1">
      <ul class="panelSuivi">
        <!-- FAQ 1 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq1"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Quels sont les justificatifs à fournir ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq1" aria-expanded="false" aria-controls="soussfaq1" >Quels sont les justificatifs à fournir ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq1">
            Lors de la souscription, une copie de votre pièce d'identité (carte nationale d'identité recto/verso ou page 3 de votre passeport ou carte de résident recto / verso),
            votre dernier avis d'imposition (en cas de déclaration séparée chaque emprunteur devra fournir son avis d'imposition),
            vos deux derniers bulletins de salaire si vous êtes salarié(e) ainsi qu'un justificatif de domicile (facture de téléphone mobile) vous seront demandés.
             Si vous empruntez à deux, la pièce d'identité ainsi que les justificatifs de revenus et de domicile de votre conjoint seront également nécessaires.
             A la fin de votre souscription, les justificatifs restants à fournir vous seront confirmés
          </div>
        </li>
        <!-- END FAQ 1 -->
        <!-- FAQ 2 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq2"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Quel est le délai de versements des fonds ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq2" aria-expanded="false" aria-controls="soussfaq2" >Quel est le délai de versements des fonds ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq2">
            Le délai de versement des fonds est de 14 jours. Sachez cependant qu'il est possible de disposer des fonds dès le 8ème jour en
            en faisant la demande lors de la souscription
          </div>
        </li>
        <!-- END FAQ 2 -->
        <!-- FAQ 3 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq3"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Pourquoi prendre une assurance ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq3" aria-expanded="false" aria-controls="soussfaq3" >Pourquoi prendre une assurance ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq3">
            Pour mener votre projet en toute sérénité et vous prémunir contre les aléas de la vie et protéger vos proches en cas de décès.
            Nos formules couvrent les garanties essentielles. L'assurance emprunteur vous protège de certains risques : maladie, invalidité
            … L'assurance facultative prend en charge le remboursement de vos mensualités ou le solde de votre prêt en cas d'accident de la
            vie.
          </div>
        </li>
        <!-- END FAQ 3 -->
        <!-- FAQ 4 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq4"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Qu\'est-ce que la signature électronique ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq4" aria-expanded="false" aria-controls="soussfaq4" >Qu'est-ce que la signature électronique ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq4">
            La signature électronique dispose des mêmes prérogatives et engage le consentement des signataires de la même façon que la
            signature manuscrite.
          </div>
        </li>
        <!-- END FAQ 4 -->
        <!-- FAQ 5 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq5"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Je souhaite annuler ma demande de crédit, comment faire ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq5" aria-expanded="false" aria-controls="soussfaq5" >Je souhaite annuler ma demande de crédit, comment faire ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq5">
            Vous disposez d'un droit de rétractation de 14 jours à compter de la signature de l'offre de prêt. Ce droit vous permet de
            renoncer à votre crédit sans avoir à vous justifier et sans pénalités. Pour cela, vous devez imprimer le coupon de rétractation
            qui est intégré à votre offre de contrat de crédit, le dater et signer de votre main, et l'envoyer en lettre recommandée avec
            accusé de réception à : BPCE FINANCEMENT - CREDIT TSA 58003 59781 LILLE CEDEX 09 en indiquant clairement la référence de votre
            dossier.
          </div>
        </li>
        <!-- END FAQ 5 -->
        <!-- FAQ 6 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq6"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Pourquoi me demande-t-on une facture de téléphone mobile ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq6" aria-expanded="false" aria-controls="soussfaq6" >Pourquoi me demande-t-on une facture de téléphone mobile ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq6">
            La facture de téléphone mobile répond à un double objectif. Elle constitue un justificatif de domicile et confirme que vous êtes
            bien le titulaire du numéro de téléphone portable communiqué. Cela permet de sécuriser le processus de signature électronique.
          </div>
        </li>
        <!-- END FAQ 6 -->
        <!-- FAQ 7 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq7"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Comment ajouter un coemprunteur ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq7" aria-expanded="false" aria-controls="soussfaq7" >Comment ajouter un coemprunteur ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq7">
            Pour emprunter à deux, vous devez dans un premier temps faire le calcul de votre capacité d'emprunt à deux dans votre Espace
            Personnel. Vous pouvez ajouter un coemprunteur en renseignant les informations le concernant (revenus, charges...) et les pièces
            justificatives demandées. Au moment de la signature, votre conjoint coemprunteur recevra un email et sera invité à activer son
            compte personnel sur le site.
          </div>
        </li>
        <!-- END FAQ 7 -->
        <!-- FAQ 8 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq8"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Comment fait le coemprunteur pour signer électroniquement ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq8" aria-expanded="false" aria-controls="soussfaq8" >Comment fait le coemprunteur pour signer électroniquement ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq8">
            Une fois que vous avez signé votre offre de prêt, votre conjoint coemprunteur reçoit automatiquement un email l'invitant à son
            tour à signer l'offre de prêt. Il lui suffira d'activer son compte personnel et de signer. Une fois la signature effectuée, il
            retrouvera automatiquement l'offre de prêt dans la rubrique "mon suivi".
          </div>
        </li>
        <!-- END FAQ 8 -->
        <!-- FAQ 9 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq9"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Où pourrais-je trouver mon contrat signé ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq9" aria-expanded="false" aria-controls="soussfaq9" >Où pourrais-je trouver mon contrat signé ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq9">
            Le processus de souscription est 100% digital, à l'issue de la signature électronique, vous retrouverez votre contrat dans votre
            Espace Client, rubrique « Mon suivi ». Vous avez le droit d'emprunter seul. Notez cependant que le montant maximum que vous
            pouvez emprunter, et la mensualité maximum que vous pourrez supporter correspond à la capacité d'emprunt seule qui vous a été
            communiqué.
          </div>
        </li>
        <!-- END FAQ 9 -->
        <!-- FAQ 10 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq10"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Je ne suis pas salarié, puis-je quand même emprunter ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq10" aria-expanded="false" aria-controls="soussfaq10" >Je ne suis pas salarié, puis-je quand même emprunter ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq10">
            Si vous exercez une profession non salariée, vous avez la possibilité de faire une demande de crédit. Votre dernier avis
            d'imposition vous sera demandé afin de justifier vos revenus.
          </div>
        </li>
        <!-- END FAQ 10 -->

        <!-- FAQ 12 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq12"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('Combien de jours est valable mon offre de prêt ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq12" aria-expanded="false" aria-controls="soussfaq12" >Combien de jours est valable mon offre de prêt ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq12">
            L'offre de prêt est valable 30 jours. Passé ce délai, la proposition communiquée n'est plus valide. Il faudra refaire une
            nouvelle souscription.
          </div>
        </li>
        <!-- END FAQ 12 -->
        <!-- FAQ 13 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#soussfaq13"
            data-toggle="collapse"
            (click)="tagSouscriptionSections('L\'assurance emprunteur est-elle obligatoire ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#soussfaq13" aria-expanded="false" aria-controls="soussfaq13" >L'assurance emprunteur est-elle obligatoire ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="soussfaq13">
            L'assurance emprunteur n'est pas obligatoire. Cependant, nous vous préconisons de suivre nos conseils en assurance pour une
            meilleure protection.
          </div>
        </li>
        <!-- END FAQ 13 -->
      </ul>
    </div>
    <!-- END SOUSCRIPTION -->

    <!-- SUIVI -->
    <div id="suivi" class="tab-pane fade" [hidden]="!isActive4" role="tabpanel" aria-labelledby="tab4" tabindex="-1">
      <ul class="panelSuivi">
        <!-- FAQ 1 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq1"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment modifier la date de prélèvement ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq1" aria-expanded="false" aria-controls="suivifaq1" >Comment modifier la date de prélèvement ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq1">
            <p>Lors de la souscription, vous pouvez choisir le 7, 15, 20 ou 28 de chaque mois pour être prélevée de votre
              échéance.</p>
            <p>
              Par la suite, si vous souhaitez modifier votre date de prélèvement, il vous suffit de contacter notre Service
              Clients au 09 72
              72 02 76 (prix d'un appel local), le lundi de 8h45 à 18h00, du mardi au vendredi de 8h45 à 18h30 et le samedi de
              8h45 à 12h30 ou
              d'adresser un courrier à BPCE FINANCEMENT - CENTRE DE RELATIONS CLIENTELE TSA 58003 59781 LILLE CEDEX 09 en
              indiquant clairement
              la référence de votre dossier.
            </p>
          </div>
        </li>
        <!-- END FAQ 1 -->
        <!-- FAQ 2 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq2"
            data-toggle="collapse"
            (click)="tagSuiviSections('Je souhaite changer de compte de prélèvement, que dois-je faire ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq2" aria-expanded="false" aria-controls="suivifaq2" >Je souhaite changer de compte de prélèvement, que dois-je faire ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq2">
            Il est possible de changer votre compte de prélèvement. Il suffit de nous adresser votre nouveau BIC IBAN (à votre nom) par
            courrier à BPCE FINANCEMENT - CENTRE DE RELATIONS CLIENTELE TSA 58003 59781 LILLE CEDEX 09 en indiquant clairement la référence
            de votre dossier. Notez qu'un BIC IBAN au nom d'un tiers ne sera pas accepté.
          </div>
        </li>
        <!-- END FAQ 2 -->
        <!-- FAQ 3 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq3"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment faire un remboursement anticipé ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq3" aria-expanded="false" aria-controls="suivifaq3" >Comment faire un remboursement anticipé ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq3">
            À tout moment, vous pouvez rembourser tout ou partie de votre crédit. Pour cela, il vous suffit de contacter notre Service
            Clients au 09 72 72 02 76 (prix d'un appel local), le lundi de 8h45 à 18h00, du mardi au vendredi de 8h45 à 18h30 et le samedi
            de 8h45 à 12h30 ou d'adresser un courrier à BPCE FINANCEMENT - CENTRE DE RELATIONS CLIENTELE TSA 58003 59781 LILLE CEDEX 09 en
            indiquant clairement la référence de votre dossier.
          </div>
        </li>
        <!-- END FAQ 3 -->
        <!-- FAQ 4 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq4"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment modifier le montant de ma mensualité ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq4" aria-expanded="false" aria-controls="suivifaq4" >Comment modifier le montant de ma mensualité ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq4">
            Vous avez la possibilité de moduler le montant de votre échéance, à la hausse comme à la baisse dans la limite de votre
            mensualité d'origine, jusqu'à 3 fois durant la vie de votre crédit et sous réserve des conditions prévues à votre contrat de
            crédit. Il vous suffit de contacter notre Service Clients au 09 72 72 02 76 (prix d'un appel local), le lundi de 8h45 à 18h00,
            du mardi au vendredi de 8h45 à 18h30 et le samedi de 8h45 à 12h30 ou d'adresser un courrier à BPCE FINANCEMENT - CENTRE DE
            RELATIONS CLIENTELE TSA 58003 59781 LILLE CEDEX 09 en indiquant clairement la référence de votre dossier.
          </div>
        </li>
        <!-- END FAQ 4 -->
        <!-- FAQ 5 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq5"
            data-toggle="collapse"
            (click)="tagSuiviSections('Je rencontre des difficultés financières ponctuelles, comment faire pour honorer mes mensualités ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq5" aria-expanded="false" aria-controls="suivifaq5" >Je rencontre des difficultés financières ponctuelles, comment faire pour honorer mes mensualités ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq5">
            Si vous vous rencontrez une difficulté ponctuelle, contactez notre Service Clients au 09 72 72 02 76 (prix d'un appel local), le
            lundi de 8h45 à 18h00, du mardi au vendredi de 8h45 à 18h30 et le samedi de 8h45 à 12h30 afin d'étudier avec vous votre
            situation.
          </div>
        </li>
        <!-- END FAQ 5 -->
        <!-- FAQ 6 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq6"
            data-toggle="collapse"
            (click)="tagSuiviSections('Lors d\'un report d\'échéance, quand sera prélevée la mensualité reportée ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq6" aria-expanded="false" aria-controls="suivifaq6" >Lors d'un report d'échéance, quand sera prélevée la mensualité reportée ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq6">
            La mensualité concernée est reportée en fin de crédit. Ainsi, si vous avez emprunté sur une durée initiale de 24 mois et que
            vous avez bénéficié au cours de la vie de votre crédit de deux reports, la durée de remboursement finale sera de 26 mois. Les
            conditions du report d'échéance sont décrites dans votre offre de prêt.
          </div>
        </li>
        <!-- END FAQ 6 -->
        <!-- FAQ 7 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq7"
            data-toggle="collapse"
            (click)="tagSuiviSections('Quelles sont les conditions pour bénéficier d\'un report d\'échéance ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq7" aria-expanded="false" aria-controls="suivifaq7" >Quelles sont les conditions pour bénéficier d'un report d'échéance ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq7">
            Pour bénéficier d'un report d'échéance, votre crédit doit être à jour dans ses remboursements (ne pas présenter
            d'impayé). Les frais de gestion sont de 4% du montant des échéances reportées. Avec une mensualité de 150 euros, le coût
            du report de mensualité sera donc de 6 euros. Il est possible de reporter 2 échéances maximum par an (sur 12 mois
            glissants) et 12 sur la durée du prêt.
          </div>
        </li>
        <!-- END FAQ 7 -->
        <!-- FAQ 8 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq8"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment savoir où vous en êtes dans vos remboursements ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq8" aria-expanded="false" aria-controls="suivifaq8" >Comment savoir où vous en êtes dans vos remboursements ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq8">
            Vous pouvez demander l'envoi d'une lettre de situation en contactant notre Service Clients au 09 72 72 02 76 (prix d'un appel
            local), le lundi de 8h45 à 18h00, du mardi au vendredi de 8h45 à 18h30 et le samedi de 8h45 à 12h30.
          </div>
        </li>
        <!-- END FAQ 8 -->
        <!-- FAQ 9-->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq9"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment obtenir un tableau d\'amortissement ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq9" aria-expanded="false" aria-controls="suivifaq9" >Comment obtenir un tableau d'amortissement ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq9">
            Vous pouvez demander l'envoi d'un tableau d'amortissement en contactant notre Service Clients au 09 72 72 02 76 (prix d'un appel
            local), le lundi de 8h45 à 18h00, du mardi au vendredi de 8h45 à 18h30 et le samedi de 8h45 à 12h30.
          </div>
        </li>
        <!-- END FAQ 9 -->
        <!-- FAQ 10 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq10"
            data-toggle="collapse"
            (click)="tagSuiviSections('Mon prélèvement a été rejeté, allez-vous reprélever ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq10" aria-expanded="false" aria-controls="suivifaq10" >Mon prélèvement a été rejeté, allez-vous reprélever ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq10">
            <p>Le prélèvement rejeté est représenté sous 4 jours ouvrés puis le mois suivant si celui-ci est une nouvelle fois
              rejeté.</p>
            <p>Dès connaissance de l'impayé, nous vous invitons à le régulariser sans tarder en vous rendant sur le site
              www.monpaiement.fr ou
              en contactant votre conseiller au 09 74 75 50 00.
            </p>
          </div>
        </li>
        <!-- END FAQ 10 -->
        <!-- FAQ 11 -->
        <li>
          <div
            class="intitUp collapsed clearfix"
            data-target="#suivifaq11"
            data-toggle="collapse"
            (click)="tagSuiviSections('Comment résilier l assurance emprunteur de mon crédit ?')"
          >
            <h2><button type="button" data-toggle="collapse" data-target="#suivifaq11" aria-expanded="false" aria-controls="suivifaq11" >Comment résilier l'assurance emprunteur de mon crédit ?</button></h2>
          </div>
          <div class="collpaseInfo collapse clearfix" id="suivifaq11">
            Si vous avez choisi de souscrire à l'assurance emprunteur proposée lors de la signature de votre offre de crédit à la consommation,
            la possibilité de résilier cette assurance vous sera proposée dans votre espace personnel à la rubrique "Mon suivi".<br />
            Vous devez en faire la demande au moins deux mois avant la date anniversaire (date de signature de votre offre de crédit).
            Au-delà, votre demande sera appliquée à la prochaine date anniversaire.
          </div>
        </li>
        <!-- END FAQ 11 -->
      </ul>
    </div>
    <button type="submit" class="w300 mAuto" (click)="close()">Fermer les FAQ</button>

    <!-- REPONSE A MA QUESTION -->
  </div>
</section>
