<section class="sectionCount maxWidth clearfix">
  <div class="row">
    <div>
      <h2>Modification du mot de passe</h2>

      <div class="alert alert-danger" *ngIf="keyMissing"><strong>La clef de réinitialisation est manquante.</strong></div>

      <div class="alert alert-danger" *ngIf="resetError">
        <p>Votre mot de passe n'a pas pu être réinitialisé. La demande de réinitialisation n'est valable que 24 heures.</p>
      </div>

      <div class="modifMdpok confInscr authIdent sectionCount maxWidth clearfix" *ngIf="resetSucess">
        <p>
          Votre mot de passe a été réinitialisé avec succès ! Vous pouvez dès maintenant poursuivre votre navigation sur BPCE Financement Offre de prêt personnel en vous connectant.
        </p>
      </div>
      <div *ngIf="resetSucess"><button type="submit" (click)="openLoginModal()" class="padding65">Me connecter</button></div>

      <div *ngIf="!keyMissing && !resetSucess">
        <form [formGroup]="resetPasswordForm">
            <div class="form-group iconPass">
                <label class="control-label"
                  >Mot de passe* <i>(8 caractères minimum dont au moins une majuscule, une minuscule, un chiffre)</i>
                </label>
                <input
                  type="password"
                  class="active form-control"
                  name="password"
                  formControlName="password"
                  placeholder="Mot de passe"
                />
                <div *ngIf="f2.password.dirty && f2.password.errors">
                  <p class="help-block" *ngIf="f2.password.errors.required">Mot de passe obligatoire</p>
                  <p class="help-block" *ngIf="f2.password.errors.minlength">Votre mot de passe doit comporter au moins 8 caractères.</p>
                  <p class="help-block" *ngIf="f2.password.errors.maxlength">
                    Votre mot de passe ne doit pas comporter plus de 50 caractères
                  </p>
                  <p class="help-block" *ngIf="f2.password.errors.pattern">Mot de passe invalide</p>
                </div>
              </div>
              <div class="form-group iconPass">
                <label class="control-label" for="confirmPassword">Confirmation du mot de passe*</label>
                <input
                  type="password"
                  class="form-control"
                  name="confirmPassword"
                  formControlName="confirmPassword"
                  placeholder="Confirmation du mot de passe"
                  (change)="validerConfirmPassword()"
                />
                <div *ngIf="f2.confirmPassword.dirty && f2.confirmPassword.errors">
                  <p class="help-block" *ngIf="f2.confirmPassword.errors.required">Confirmation du mot de passe obligatoire</p>
                  <p class="help-block" *ngIf="f2.confirmPassword.errors.minlength">
                    Votre confirmation du mot de passe doit comporter au moins 8 caractères.
                  </p>
                  <p class="help-block" *ngIf="f2.confirmPassword.errors.maxlength">
                    Votre confirmation du mot de passe ne doit pas comporter plus de 50 caractères.
                  </p>
                  <p class="help-block" *ngIf="f2.confirmPassword.errors.pattern">Confirmation du mot de passe invalide</p>
                </div>
                <p class="help-block" *ngIf="resetPasswordForm.value.confirmPassword && f2.confirmPassword.dirty && !validerConfirmPassword()">
                  Saisie différente
                </p>
              </div>
        </form>
        <p class="mandatory">* Informations obligatoires</p>

        <button type="submit" [disabled]="!resetPasswordForm.valid" class="fullSize" (click)="ResetPassword()">Valider</button>
      </div>
    </div>
  </div>
</section>
