import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IcvFinalModel } from '../models/icvFinal.model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScoringService {
  private subject = new Subject<any>();
  constructor(private http: HttpClient) {}

  public calculerIcvLignt(data) {
    return this.http.post('/ccoweb/api/calculer-icv-simple', data, {});
  }

  public getListValidCPLight() {
    return this.http.get('./codes_postaux_light.json', {});
  }

  public getListeCsp() {
    return this.http.get('/ccoweb/api/csps', {});
  }

  public getListeNafA() {
    return this.http.get('/ccoweb/api/nafs', {});
  }

  public getIcvCompleteManaged(): Observable<IcvFinalModel> {
    return this.http.post<IcvFinalModel>('/ccoweb/api/load-managed-icv-final', {}, {});
  }
  public getIcvCompleteManagedWithBridgeData(): Observable<IcvFinalModel> {
    return this.http.post<IcvFinalModel>('/ccoweb/api/load-managed-icv-final-bridge', {}, {});
  }
  updateIcvComplete(managedIcvFinalDTO) {
    // Format String to date
    managedIcvFinalDTO.icvFinalDTO.dateNaissance = this.formatStringTodate(managedIcvFinalDTO.icvFinalDTO.dateNaissance);
    managedIcvFinalDTO.userDTO.dateNaissance = this.formatStringTodate(managedIcvFinalDTO.userDTO.dateNaissance);

    return this.http.post('/ccoweb/api/update-managed-icv', managedIcvFinalDTO, {});
  }

  // Format string to date compatible with firefox and ie
  formatStringTodate(dateParam) {
    if (dateParam) {
      const dateParts = dateParam.split('/');
      const date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); // month is 0-based
      return date;
    } else {
      return null;
    }
  }

  calculerScoringFinal(icvFinalDTO) {
    return this.http.post('/ccoweb/api/calculer-icv-final', icvFinalDTO, {});
  }

  calculerScoringFinalV2(identitiesDto) {

    return this.http.post('/ccoweb/api/calculer-icv-final-v2', identitiesDto, {});
  }


  // function to send message to headerComponent from account Component
  sendMessage(data: any) {
    this.subject.next({ resultatCalculIcv: data });
  }

  // function to subscribe to to get any message sent by account Component
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  // function to send message to headerComponent from account Component
  sendAnchor(data: any) {
    this.subject.next({ anchor: data });
  }

  // function to subscribe to to get any message sent by account Component
  getAnchor(): Observable<any> {
    return this.subject.asObservable();
  }

  saveCalculScoringFinal() {
    return this.http.post('/ccoweb/api/save-calcul-icv-final', {media:localStorage.getItem('media')}, {});
  }

  getIcvCompleteManagedById(idIcv: string): any {
    return this.http.get('/ccoweb/api/icv-final', { params: { id: idIcv } });
  }

  /**********************************************
   * verifier le calcul IcvFinal
   * @param icvFinalDTO
   *********************************************/
  verifierIcvFinal(icvFinalDTO: any): any {
    return this.http.post('/ccoweb/api/verifier-icv-final', icvFinalDTO, {});
  }
}
