import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { TealiumUtagService } from 'src/app/tealium/utag.service';
import { Router } from '@angular/router';
import { ClientModel } from 'src/app/tealium/client.model';
import { CustomModel } from 'src/app/tealium/custom';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.css']
})
export class CguComponent implements OnInit {
  constructor(private tealium: TealiumUtagService, private utagDataService: UtagDataService, private router: Router) {}

  ngOnInit() {
    // Taggage Telium
    this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('CGU', 'edito'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.router, 'Transverse', '', ''),
        new ClientModel(),  new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  goTo(id) {
    $('html, body').animate(
      {
        scrollTop: $(id).offset().top - 100
      },
      1000
    );
  }
}
