import { Injectable } from '@angular/core';
import { AuthOauth2Service } from './auth.oauth2.service';
import { LoginModel } from '../models/login.model';
import { HttpClient } from '@angular/common/http';
import { AccountModel } from '../models/account.model';
import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private subject = new Subject<any>();
  
  constructor(private auth: AuthOauth2Service, private http: HttpClient, private modalService: NgbModal) {}

  account: AccountModel;
  public login(credentials: LoginModel) {
    return this.auth.login(credentials);
  }
  public getAccount() {
    return this.http.get<AccountModel>('/ccoweb/api/account', {});
  }
  public registerAccount(data) {
    return this.http.post('/ccoweb/api/register', data, {});
  }

  public requestActivationLink(email: string) {
    return this.http.post('/ccoweb/api/request-link-activation', email, {}).subscribe(
      success => {},
      error => {
        console.log(error);
      }
    );
  }

  // function to send message to headerComponent from account Component
  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  // function to subscribe to to get any message sent by account Component
  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }

  logout() {
    this.auth.logout();
  }

  simpleLogout() {
    this.auth.simpleLogout();
  }
  /**
   * call api for request reset password that sends email to the user
   */
  resetPassword(email: string) {
    return this.http.post('/ccoweb/api/account/reset_password/init', email, { responseType: 'text' }).pipe(tap(data => {}, error => {}));
  }
  /***********************************************
   * update settings account
   * @param settingsAccount
   **********************************************/
  updateSettingsAccount(settingsAccount) {
    return this.http.post('/ccoweb/api/update-settings-account', settingsAccount, {});
  }
  /*********************************************
   * check password
   * @param settingsAccount
   *********************************************/
  checkPassword(settingsAccount) {
    return this.http.post('/ccoweb/api/check-password-account', settingsAccount, {});
  }
  /**********************************************
   * Update password
   * @param settingsAccount
   *********************************************/
  updatePassword(settingsAccount) {
    return this.http.post('/ccoweb/api/update-check-password', settingsAccount, {});
  }
  /***************************************************
   * check if the account is deletable
   ***************************************************/
  checkDeleteAccount() {
    return this.http.get('/ccoweb/api/deletable', {});
  }
  /*************************************************
   * Delete account
   * @param managedIcvFinal
   ************************************************/
  deleteAccount(settingsAccount) {
    return this.http.post('/ccoweb/api/delete-account', settingsAccount, {});
  }

  /************************************
   * Get Coemprunteur prospect
   ************************************/
  getCoEmprunteur(universignId) {
    const sousData = {
      transactionIdUniversign: '' + universignId
    };
    return this.http.post('/ccoweb/api/charger-coemp', sousData, {});
  }

  /************************************
   * Register Coemprunteur
   ************************************/
  public registerCoemprunteurAccount(data) {
    return this.http.post('/ccoweb/api/registerco', data, {});
  }

  /************************************
   * Activate Account
   ************************************/
  public activate(key) {
    return this.http.get('/ccoweb/api/activate', { params: { key: key } });
  }

  /************************************
   * Get User
   ************************************/
  getUser() {
    return this.http.get('/ccoweb/api/user', {});
  }

}
