import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class BridgeService {

    constructor(private http: HttpClient) {}


    public getBridgeConnectUrl() {
        return this.http.get('/ccoweb/api/bridge/bridgeurl', {responseType: 'text'});
    }


}
