import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthOauth2Service } from '../../services/auth.oauth2.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountComponent } from '../account.component';
import { PageModel } from 'src/app/tealium/page.model';
import { ProduitModel } from 'src/app/tealium/produit.model';
import { UtagDataService } from 'src/app/tealium/utag-data.service';
import { FormModel } from 'src/app/tealium/form.model';
import { NtxModel } from 'src/app/tealium/ntx';
import { ClientModel } from 'src/app/tealium/client.model';
import { ClicModel } from 'src/app/tealium/clic.model';
import { CustomModel } from 'src/app/tealium/custom';
import { TealiumUtagService } from 'src/app/tealium/utag.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private oAuth2Service: AuthOauth2Service,
    private modalService: NgbModal,
    private tealium: TealiumUtagService,
    private utagDataService: UtagDataService,
    private routeNavigation: Router,
  ) {}
  resetPasswordForm: FormGroup;
  key: any;
  resetSucess: any;
  resetError: any;
  keyAndPassword: any = {};
  keyMissing: any = false;
  ngOnInit() {
    this.key = this.route.snapshot.queryParamMap.get('key');
    this.keyMissing = this.key === null ? true : false;
    this.resetPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ],
      confirmPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
          Validators.pattern(/^.*(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[\d\W]).*$/)
        ]
      ]
    });
     // Taggage Telium
     this.tealium.view(
      this.utagDataService.constructDataPage(
        new PageModel('Modification de mot de passe', 'profil'),
        new ProduitModel(),
        new FormModel('', ''),
        new NtxModel(this.routeNavigation, 'Mon compte', 'Mon compte - Connexion', ''),
        new ClientModel(),
        new CustomModel('', '', '', '', '', '', '', '', '')
      )
    );
  }

  ResetPassword() {
    this.keyAndPassword.key = this.key;
    this.keyAndPassword.newPassword = this.resetPasswordForm.value.password;
    this.oAuth2Service.resetPassword(this.keyAndPassword).subscribe(
      response => {
        this.resetSucess = true;
        this.resetError = false;
      },
      error => {
        this.resetSucess = false;
        this.resetError = true;
      }
    );
  }

  /**********************************************************
   *  Function to validate if the confirm Robert = Robert
   * @param password, confirmRobert
   **********************************************************/
  // Function to validate if the confirm Robert = Robert
  validerConfirmPassword() {
    const password = this.resetPasswordForm.value.password;
    const confirmPassword = this.resetPasswordForm.value.confirmPassword;
    if (confirmPassword && password && confirmPassword === password) {
      return true;
    } else {
      return false;
    }
  }

  get f2() {
    return this.resetPasswordForm.controls;
  }

  openLoginModal() {
    this.modalService.open(AccountComponent);
  }
}
