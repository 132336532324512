<header role="banner">
  <nav class="navbar navbar-fixed-top" role="navigation">
    <div class="container">
      <a class="evitement" href="#contenu">Aller au contenu</a>
      <!-- BURGER MENU + visibility -->
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#collapseNavBar"
          aria-expanded="false"
          aria-controls="collapseNavBar"
        >
          <span class="sr-only">Menu principal</span>
          <span class="icon-bar"></span> <span class="icon-bar"></span>
          <span class="icon-bar"></span> <span class="cross-bar"></span>
        </button>
        <ul>
          <li class="clearfix">
            <button (click)="goToHome()">
              <img class="imgCredit" src="./assets/images/header-footer/BPCE Financement.jpg" alt="BPCE Financement, accueil"/>
            </button>
          </li>
          <!--
            INFO DEV : POUR QUE LE SOUS-MENU S'AFFICHE, IL FAUT QUE L'INTERNAUTE SOIT CONNECTE


                >> PAR DEFAUT, LE LI N'A PAS DE CLASS DONCT LE SOUS-MENU N'APPARAIT PAS


                >> UNE FOIS CONNECTE, LE LI PREND LA CLASS "connected" ET LE SOUS-MENU APPARAIT
          -->
          <li *ngIf="!showMonCompte"></li>
          <li [ngClass]="{ connected: isAuthenticated }" class="dropdown" *ngIf="showMonCompte">
            <button (click)="openModal()" id="dropdownMenuButton1" aria-expanded="false" data-toggle="dropdown" aria-expanded="true" aria-haspopup="true" class="dropdown-toggle">{{ libelle }}</button>
            <ul class="subnavHeader dropdown-menu" aria-labelledby="dropdownMenuButton1" [hidden]="!isAuthenticated">
              <li><button (click)="monEspaceClient()" id="monEspaceClient">Mon espace client</button></li>
              <li><button (click)="settingsAccount()">Paramètres de compte</button></li>
              <li><button (click)="logout()" id="logout">Déconnexion</button></li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- HEADER VERSION DESKTOP -->
      <div class="maxWidth collapse navbar-collapse" id="collapseNavBar">
        <ul class="maxWidth nav navbar-nav clearfix">
          <li class="clearfix">
            <button (click)="goToHome()">
               <img class="imgCredit" src="./assets/images/header-footer/BPCE Financement.jpg" alt="BPCE Financement, accueil"/>
            </button>
          </li>
          <li><a [routerLink]="['/home/']" fragment="knowMore" (click)="goToElement('knowMore')" #monitored>En savoir plus</a></li>
          <li><a [routerLink]="['/home/']" fragment="howItWorks" (click)="goToElement('howItWorks')">Comment ça marche ?</a></li>
          <li><a [routerLink]="['/features/faq/']" target="_blank" (click)="goToFaq()">FAQ</a></li>
          <li><button (click)="$event.preventDefault();goToQuiSommesNous()" role="link" class="qsn">Qui sommes nous ?</button></li>
          <!--
            INFO DEV : POUR QUE LE SOUS-MENU S'AFFICHE, IL FAUT QUE L'INTERNAUTE SOIT CONNECTE


                >> PAR DEFAUT, LE LI N'A PAS DE CLASS DONC LE SOUS-MENU N'APPARAIT PAS


                >> UNE FOIS CONNECTE, LE LI PREND LA CLASS "connected" ET LE SOUS-MENU APPARAIT
          -->
          <li *ngIf="!showMonCompte"></li>
          <li [ngClass]="{ connected: isAuthenticated }" class="dropdown" *ngIf="showMonCompte">
                   <button (click)="openModal()" id="dropdownMenuButton" aria-expanded="false" data-toggle="dropdown" aria-expanded="true" aria-haspopup="true" class="dropdown-toggle">{{ libelle }}</button>
            <ul class="subnavHeader dropdown-menu" aria-labelledby="dropdownMenuButton" [hidden]="!isAuthenticated">
              <li><button (click)="monEspaceClient()" id="monEspaceClient">Mon espace client</button></li>
              <li><button (click)="settingsAccount()">Paramètres de compte</button></li>
              <li><button (click)="logout()" id="logout">Déconnexion</button></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- ------------------------------------------------   END HEADER    ------------------------------------------------ -->
